import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { notification, Table, Tooltip } from "antd";
import { useStateContext } from "contexts/ContextProvider";
import "./QueryProfileDetails.css";
import { getVaultDetails } from "services/ApiServices";
import dayjs from "dayjs";
import { MdFileDownload } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";

const DocumentDetails = (data) => {
  const { accountNumber } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [documentTableData, setDocumentTableData] = useState([]);

  const getFilesByRefNumber = async () => {
    try {
      setLoading(true);
      const res = await getVaultDetails(accountNumber);
      if (res.responseData) {
        setDocumentTableData(res.responseData);
        setLoading(false);
      } else {
        setDocumentTableData([]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getFilesByRefNumber();
  }, []);

  const documentColumn = [
    {
      title: "Reference Transaction Number",
      dataIndex: "entity_reference_number",
      width: 250,
    },
    {
      title: "Document Type",
      dataIndex: "document_type",
    },
    {
      title: "Document Name",
      dataIndex: "document_name",
    },
    {
      title: "Creation Date",
      dataIndex: "creation_date",
      align: "center",
      render: (text, record) => {
        return <span>{dayjs(record.creation_date).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => {
        return (
          <div className="flex justify-center items-center">
            <Tooltip title="View">
              <div
                className={`flex ${
                  record.s3_path ? "bg-validus-red" : "bg-validus-grey"
                }  text-white p-2 rounded-[100%] cursor-pointer mx-2 justify-between items-center hover:bg-validus-blue`}
                onClick={() => {
                  const fileIdMatch = record?.s3_path.match(
                    /(?:\/d\/|id=)([\w-]+)/
                  );
                  if (fileIdMatch) {
                    const fileId = fileIdMatch[1];
                    const viewUrl = `https://drive.google.com/file/d/${fileId}/view`;

                    window.open(viewUrl, "_blank");
                  } else {
                    record.s3_path
                      ? window.open(
                          record.s3_path.replace(
                            "&export=download",
                            "&export=view"
                          ),
                          "_blank"
                        )
                      : notification.error({
                          message: "File Unavailable",
                          description: "File does not exist",
                        });
                  }
                }}
              >
                <AiOutlineEye size={16} />
              </div>
            </Tooltip>
            <Tooltip title="Download">
              <div
                className={`flex ${
                  record.s3_path ? "bg-validus-red" : "bg-validus-grey"
                }  text-white p-2 rounded-[100%] cursor-pointer  justify-between items-center hover:bg-validus-blue`}
                onClick={() => {
                  if (record.s3_path) {
                    const fileUrl = record.s3_path;
                    const link = document.createElement("a");
                    link.href = fileUrl;
                    link.download = ""; // You can specify the file name here
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  } else {
                    notification.error({
                      message: "File Unavailable",
                      description: "File does not exist",
                    });
                  }
                }}
              >
                <MdFileDownload size={16} />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h1 style={{ textAlign: "center", fontSize: "24px" }}>
        Investor Documents
      </h1>

      <div className="flex flex-row justify-between">
        <div className="w-1/2">
          Client Name :{" "}
          <span className="font-semibold ml-5">{data?.clientName}</span>
        </div>
        <div className="w-1/2">
          Fund Name :{" "}
          <span className="font-semibold ml-5">{data?.fundName}</span>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-1/2">
          Investor Name :{" "}
          <span className="font-semibold ml-5">{data?.investorName}</span>
        </div>
        <div className="w-1/2">
          Investment Class :{" "}
          <span className="font-semibold ml-5">{data?.classCategory}</span>
        </div>
      </div>

      {loading ? (
        <p style={{ textAlign: "center", fontSize: "20px" }}>Loading...</p>
      ) : (
        <>
          {documentTableData?.length === 0 ? (
            <p style={{ textAlign: "center", fontSize: "20px" }}>
              No Documents Available
            </p>
          ) : (
            <Table
              bordered
              columns={documentColumn}
              dataSource={documentTableData}
              pagination={{
                pageSize: 10,
              }}
              size="small"
              fontSize="10px"
              style={{
                marginTop: "2%",
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DocumentDetails;
