import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userData: {},

}

const userAuthSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        setUserData(state, action) {
            state.userData = action.payload;
        },
    },
});

export const {
    setUserData,
} = userAuthSlice.actions;

export default userAuthSlice.reducer;