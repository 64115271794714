import { myApi } from "./apiInstance";
import endpoints from "./endpoints";

export const getClientData = async (data) => {
    try {
        return await myApi
            .get(
                `${endpoints.GET_CLIENT_DATA}/${data}`)
            .then((res) => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};

export const deleteUserAPI = async (userId) => {
    try {
        return await myApi
            .post(`${endpoints.DELETE_USER}/${userId}`)
            .then(res => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};

export const changePasswordAPI = async (data) => {
    try {
        return await myApi
            .post(`${endpoints.CHANGE_PASSWORD}`, data)
            .then(res => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};