

import { myApi } from "./apiInstance";
import endpoints from "./endpoints";

export const getSearchFields = async () => {
    try {
        return await myApi
            .get(
                `${endpoints.GET_SEARCH_FIELDS}`)
            .then((res) => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};

export const generateFamilyFolioSoa = async data => {
    try {
        return await myApi
            .get(`${endpoints.GENERATE_FAMILY_FOLIO_SOA}/${data.client_id}/${data.family_account_no}`)
            .then(res => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};
export const getAdvancedSearchData = async (data) => {
    try {
        return await myApi.post(`${endpoints.GET_ADVANCED_SEARCH_DATA}/${data?.user_id}?clientid=${data?.client_id}&type=${data?.type}`, data?.payload).then(res => {
            return res.data;
        });
    } catch (err) {
        return console.log(err);
    }
};
