import React, { useState, useEffect } from "react";
import { Button, Spin, Modal } from "antd";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import moment from "moment/moment";
import { getCountry } from "services/LookupServices";
import { getWorkflow, saveWorkflow } from "services/workflowServices";
import { useSelector } from "react-redux";

const UhrReports = ({ data, toDate, fundHouse }) => {
  const { userData } = useSelector((state) => state.userAuth);
  const [exportButton, setExportButton] = useState(true);
  const [workflowData, setWorkflowData] = useState([]);
  const [downloading, setDownloading] = useState(false);

  var workflowdate = moment().utcOffset("+05:30").format();
  const pageLoadingTimeStamp = workflowdate;

  useEffect(() => {
    // set the export button to false, if data is there it will change to true or else it will be false
    setExportButton(false);
    if (data.length === 0) {
      setExportButton(true);
    } else {
      setExportButton(false);
    }
  }, [data]);

  useEffect(() => {
    getWorkflowAPI();
  }, []);

  const getWorkflowAPI = async () => {
    try {
      const res = await getWorkflow("URDTR");
      if (res.responseData) {
        setWorkflowData(res.responseData);
      } else {
        return console.log("Error in fetching workflow data");
      }
    } catch (err) {
      return console.log(err);
    }
  };

  const createDownLoadUhrData = () => {
    // Downloading the UH Report
    insertWorkflowAPI(data);
    setDownloading(true);
    if (downloading === false) {
      handleUhrExport().then((url) => {
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute(
          "download",
          `UnitHoldingReport_${toDate}.xls`
        );
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
    } else {
      Modal.info({
        title: "Please wait while the UHR is downloading",
      });
    }
  };

  const insertWorkflowAPI = async (data) => {
    setDownloading(true);
    var submitTimeStamp = moment().utcOffset("+05:30").format();
    let pageLoadingTimeStampInSeconds = moment(pageLoadingTimeStamp).seconds();
    let submitTimeStampInSeconds = moment(submitTimeStamp).seconds();
    let actualAHT_Seconds = Math.abs(
      submitTimeStampInSeconds - pageLoadingTimeStampInSeconds
    );
    for (let i = 0; i < data.length; i++) {
      var apiInsertWorkFlowData = {
        worfklow_item_create_ts: pageLoadingTimeStamp,
        workflow_item_reference_value: userData?.user_id,
        isactive: true,
        workflow_id: workflowData[0].workflow_id,
        workflow_step_id: workflowData[0].worflow_step_id,
        system_feature_operation_id: 1,
        assigned_by_user_id: userData?.user_id,
        assigned_to_user_id: userData?.user_id,
        completed_by_user_id: userData?.user_id,
        assigned_ts: pageLoadingTimeStamp,
        completed_ts: pageLoadingTimeStamp,
        updation_date: pageLoadingTimeStamp,
        creation_by: userData?.user_id,
        creation_date: pageLoadingTimeStamp,
        workflow_step_json_data: "UHR report has been generated",
        workflow_status: "completed",
        actual_aht_seconds: actualAHT_Seconds,
        worflow_step_sequence_number:
          workflowData[0].worflow_step_sequence_number,
        application_num: data[i].applicationnumber,
        client_id: fundHouse,
      };
      await saveWorkflow(apiInsertWorkFlowData);
    }
    setDownloading(false);
  };

  const workbook2blob = (workbook) => {
    //Converting data into Excel
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    //Sorting of data in Excel
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleUhrExport = async () => {
    // Fetch and update the data with country names
    const updatedData = await Promise.all(
      data.map(async (item) => {
        const birthCountry = await getCountry(
          item.birth_or_incorporation_country_id
        );
        const nationalityCountry = await getCountry(
          item.nationality_country_id
        );

        return {
          ...item,
          birth_or_incorporation_country_id:
            birthCountry || item.birth_or_incorporation_country_id,
          nationality_country_id:
            nationalityCountry || item.nationality_country_id,
        };
      })
    );

    // UHR Excel Format
    const title = [
      {
        A: `Unit Holding Report of  "${updatedData[0].clientname}"  as on ${toDate}`,
      },
      {},
    ];

    let table = [
      {
        A: "Sno",
        B: "Scheme",
        C: "Class",
        D: "Class Code",
        E: "Class Category",
        F: "Account Number",
        G: "Investor Name",
        H: "Primary Holder Pan Number",
        I: "Primary Holder DOB",
        J: "Category Name",
        K: "Residential Status",
        L: "Commitment Amount",
        M: "Contribution Amount",
        N: "Balance Units",
        O: "AUM",
        P: "NAV",
        Q: "NAV Date",
        R: "Pledge Units",
        S: "Pledge In Favour Of",
        T: "Distributor Code",
        U: "Distributor Name",
        V: "RM Code",
        W: "AMC RM Name",
        X: "Dist RM Name",
        Y: "Primary Holder Moblie Number",
        Z: "Primary Holder Email Id",
        AA: "Address 1",
        AB: "Address 2",
        AC: "City",
        AD: "State",
        AE: "PIN Code",
        AF: "NRI Address 1",
        AG: "NRI Address 2",
        AH: "NRI City",
        AI: "NRI State",
        AJ: "NRI Country",
        AK: "NRI ZIP Code",
        AL: "Bank Name",
        AM: "Bank Account Number",
        AN: "Bank MICR Code",
        AO: "Bank IFSC Code",
        AP: "Bank Account Type",
        AQ: "Bank Address",
        AR: "Bank PIN Code",
        AS: "Mode Of Holding",
        AT: "First Joint Holder Name",
        AU: "First Joint Holder PAN",
        AV: "First Joint Holder Relationship",
        AW: "Second Joint Holder Name",
        AX: "Second Joint Holder PAN",
        AY: "Second Joint Holder Relationship",
        AZ: "Alternate Email Id",
        BA: "First Nominee Name",
        BB: "Second Nominee Name",
        BC: "Third Nominee Name",
        BD: "Guardian Name",
        BE: "Guardian Pan",
        BF: "POA Holder Name",
        BG: "POA Holder Address",
        BH: "Birth/Incorporation Country",
        BI: "Birth/Incorporation Place",
        BJ: "Tax Resident Country",
        BK: "Tax ID",
        BL: "Registration Date",
        BM: "DP Id",
        BN: "Client Id Number",
        BO: "Beneficiary Account Number",
        BP: "Family Name",
        BQ: "Family Folio No.",
      },
    ];

    let Total = [
      {
        A: "", // this will be for total and it will be empty
      },
    ];

    // Pushing data to tables
    for (let i = 0; i < updatedData.length; i++) {
      table.push({
        A: updatedData[i].sno,
        B: updatedData[i].fund_name,
        C: updatedData[i].class_name,
        D: updatedData[i].class_code,
        E: updatedData[i].class_cat,
        F: `${" " + updatedData[i].account_number}`,
        G: updatedData[i].investorname,
        H: updatedData[i].primary_holder_pan_num,
        I: updatedData[i].primary_holder_dob,
        J: updatedData[i].category_name,
        K: updatedData[i].residential_status,
        L: updatedData[i].committment_amount,
        M: updatedData[i].contributionamount,
        N: updatedData[i].balance_units,
        O: updatedData[i].aum,
        P: updatedData[i].nav,
        Q: updatedData[i].nav_date,
        R: updatedData[i].pledgedunits,
        S: updatedData[i].pledgein_favourof,
        T: updatedData[i].distributorcode,
        U: updatedData[i].distributorname,
        V: updatedData[i].rmcode,
        W: updatedData[i].rmname,
        X: updatedData[i].amc_rmname,
        Y: updatedData[i].primary_holder_mobile_num,
        Z: updatedData[i].primary_holder_emailid,
        AA: updatedData[i].address_line1,
        AB: updatedData[i].address_line2,
        AC: updatedData[i].city_name,
        AD: updatedData[i].state_name,
        AE: updatedData[i].pin_code,
        AF: updatedData[i].nri_address_line1,
        AG: updatedData[i].nri_address_line2,
        AH: updatedData[i].nr_city,
        AI: updatedData[i].nri_state,
        AJ: updatedData[i].nri_country,
        AK: updatedData[i].nri_zipcode,
        AL: updatedData[i].bankname,
        AM: `${" " + updatedData[i].bankaccount_number}`,
        AN: updatedData[i].bankmicr_code,
        AO: updatedData[i].bankifsc_code,
        AP: updatedData[i].bankaccount_type,
        AQ: updatedData[i].bank_address,
        AR: updatedData[i].bank_pincode,
        AS: updatedData[i].modeof_holding,
        AT: updatedData[i].first_holder_name,
        AU: updatedData[i].first_holder_pan,
        AV: updatedData[i].first_holder_relationship,
        AW: updatedData[i].second_holder_name,
        AX: updatedData[i].second_holder_pan,
        AY: updatedData[i].second_holder_relationship,
        AZ: updatedData[i].alternate_emailid,
        BA: updatedData[i].first_nominee_name,
        BB: updatedData[i].second_nominee_name,
        BC: updatedData[i].third_nominee_name,
        BD: updatedData[i].gaurdian_name,
        BE: updatedData[i].gaurdian_pan,
        BF: updatedData[i].poa_holdername,
        BG: updatedData[i].poa_holderaddress,
        BH: updatedData[i].birth_or_incorporation_country_id,
        BI: updatedData[i].birth_or_incorporation_place,
        BJ: updatedData[i].nationality_country_id,
        BK: updatedData[i].tax_id,
        BL: updatedData[i].institution_incorporation_date,
        BM: updatedData[i].dp_id,
        BN: updatedData[i].client_id_num,
        BO: updatedData[i].benificiary_account_num,
        BP: data[i].family_name,
        BQ: data[i].family_account_no,
      });
    }

    const totalSum = updatedData.reduce(
      (acc, row) => acc + parseFloat(row.balance_units),
      0
    );

    Total.push({
      A: "Total Transaction",
      B: updatedData.length,
      T: "Total Balance Units",
      U: totalSum,
    });

    table = [{ A: "" }]
      .concat([""])
      .concat([""])
      .concat(table)
      .concat([""])
      .concat([""])
      .concat(Total);

    const finalData = [...title, ...table];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "Unit Holding Report");

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Sno" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A1",
      titleRange: "A1:D2",
      tbodyRange: `A2:CZ${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:BQ${headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    // styling the excel sheet
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        sheet.column("A").width(25);
        sheet.column("B").width(30);
        sheet.column("C").width(30);
        sheet.column("D").width(35);
        sheet.column("E").width(35);
        sheet.column("F").width(35);
        sheet.column("G").width(25);
        sheet.column("H").width(25);
        sheet.column("I").width(25);
        sheet.column("J").width(25);
        sheet.column("K").width(25);
        sheet.column("L").width(25);
        sheet.column("M").width(25);
        sheet.column("N").width(25);
        sheet.column("O").width(25);
        sheet.column("P").width(25);
        sheet.column("Q").width(25);
        sheet.column("R").width(25);
        sheet.column("S").width(35);
        sheet.column("T").width(25);
        sheet.column("U").width(35);
        sheet.column("V").width(35);
        sheet.column("W").width(30);
        sheet.column("X").width(35);
        sheet.column("Y").width(35);
        sheet.column("Z").width(35);
        sheet.column("AA").width(25);
        sheet.column("AB").width(25);
        sheet.column("AC").width(25);
        sheet.column("AD").width(25);
        sheet.column("AE").width(35);
        sheet.column("AF").width(25);
        sheet.column("AG").width(25);
        sheet.column("AH").width(25);
        sheet.column("AI").width(25);
        sheet.column("AJ").width(25);
        sheet.column("AK").width(35);
        sheet.column("AL").width(35);
        sheet.column("AM").width(40);
        sheet.column("AN").width(35);
        sheet.column("AO").width(25);
        sheet.column("AP").width(25);
        sheet.column("AQ").width(25);
        sheet.column("AR").width(35);
        sheet.column("AS").width(25);
        sheet.column("AT").width(30);
        sheet.column("AU").width(35);
        sheet.column("AV").width(40);
        sheet.column("AW").width(40);
        sheet.column("AX").width(25);
        sheet.column("AY").width(25);
        sheet.column("AZ").width(25);
        sheet.column("BA").width(25);
        sheet.column("BB").width(25);
        sheet.column("BC").width(30);
        sheet.column("BD").width(30);
        sheet.column("BE").width(30);
        sheet.column("BF").width(30);
        sheet.column("BG").width(30);
        sheet.column("BH").width(30);
        sheet.column("BI").width(30);
        sheet.column("BJ").width(30);
        sheet.column("BK").width(30);
        sheet.column("BL").width(30);
        sheet.column("BM").width(30);
        sheet.column("BN").width(30);
        sheet.column("BO").width(30);
        sheet.column("BP").width(30);
        sheet.column("BQ").width(30);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
          border: true,
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "center",
          });
        }

        sheet.range(dataInfo.theadRange).style({
          bold: true,
          horizontalAlignment: "center",
          border: true,
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <div>
      {downloading ? (
        <Spin size="medium" />
      ) : (
        <Button
          onClick={() => {
            createDownLoadUhrData();
          }}
          disabled={exportButton}
          loading={downloading}
          style={{
            width: "140px",
            height: "33px",
            backgroundColor: "#A3020C",
            color: "white",
            borderRadius: 5,
          }}
        >
          Export as Excel
        </Button>
      )}
    </div>
  );
};

export default UhrReports;
