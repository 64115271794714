import moment from 'moment';

// Transaction Register column data
export const columnsTransactionRegister = [
  {
    title: 'S. No',
    dataIndex: 'sno',
    responsive: ['md'],
  },
  {
    title: 'Account Number',
    dataIndex: 'accountnumber',
    responsive: ['md'],
  },
  {
    title: 'Investor Name',
    dataIndex: 'investor_name',
    responsive: ['md'],
  },
  {
    title: 'Transaction Number',
    dataIndex: 'ihnumber',
    responsive: ['md'],
  },
  {
    title: 'Transaction Date',
    dataIndex: 'transaction_date',
    responsive: ['md'],
  },
  {
    title: 'Transaction Type',
    dataIndex: 'transaction_type_name',
    responsive: ['md'],
  },
];

// CreditMarking column data
export const columnsCreditMarking = [
  {
    title: 'S. No',
    dataIndex: 'sno',
    responsive: ['md'],
  },
  {
    title: 'Account Number',
    dataIndex: 'accountnumber',
    responsive: ['md'],
  },
  {
    title: 'Transaction Date',
    dataIndex: 'transaction_date',
    responsive: ['md'],
  },
  {
    title: 'Transaction Number',
    dataIndex: 'ihnumber',
    responsive: ['md'],
  },
  {
    title: 'Transaction Amount',
    dataIndex: 'transactionamount',
    responsive: ['md'],
  },
  {
    title: 'Transaction Type',
    dataIndex: 'transaction_type',
    responsive: ['md'],
  },
  {
    title: 'Status',
    dataIndex: 'endorsement_flag',
    responsive: ['md'],
  },
];

// CreditMarking column data
export const columnsNAVReport = [
  {
    title: 'S. No',
    dataIndex: 'sno',
    responsive: ['md'],
  },
  {
    title: 'Fund House',
    dataIndex: 'client_name',
    responsive: ['md'],
  },
  {
    title: 'Scheme',
    dataIndex: 'fund_name',
    responsive: ['md'],
  },
  {
    title: 'Investment Class',
    dataIndex: 'class_name',
    responsive: ['md'],
  },
  {
    title: 'NAV Date',
    dataIndex: 'nav_date',
    responsive: ['md'],
  },
  {
    title: 'NAV Value',
    dataIndex: 'nav_value',
    responsive: ['md'],
  },
];

// DTR column data
export const columnsDtr = [
  {
    title: 'S. No',
    dataIndex: 'sno',
    key: '1',
    responsive: ['md'],
  },
  {
    title: 'Name',
    dataIndex: 'investor_name',
    key: '2',
    responsive: ['md'],
  },
  {
    title: 'Class',
    dataIndex: 'investment_classname',
    key: '3',
    responsive: ['md'],
  },
  {
    title: 'Transaction Number',
    dataIndex: 'ih_number',
    key: '4',
    responsive: ['md'],
  },
  {
    title: 'Amount',
    dataIndex: 'committment_amount',
    key: '5',
    responsive: ['md'],
  },
  {
    title: 'Transaction Date',
    dataIndex: 'transaction_date',
    key: '6',
    responsive: ['md'],
  },
  {
    title: 'Leegality Document Id',
    dataIndex: 'legality_document_id',
    key: '7',
    responsive: ['md'],
  },
];

// UCR column data
export const columnsUcr = [
  {
    title: 'S. No',
    dataIndex: 'sno',
    responsive: ['md'],
  },
  {
    title: 'Name',
    dataIndex: 'fund_name',
    responsive: ['md'],
  },
  {
    title: 'Total Amount',
    dataIndex: 'transactionamount',
    responsive: ['md'],
  },
];

// UCR column data
export const columnsInvictaUcr = [
  {
    title: 'S. No',
    dataIndex: 'sno',
    responsive: ['md'],
  },
  {
    title: 'Class Name',
    dataIndex: 'class_name',
    responsive: ['md'],
  },
  {
    title: 'Transaction Type',
    dataIndex: 'transaction_type_desc',
    responsive: ['md'],
  },
  {
    title: 'Transaction Date',
    dataIndex: 'transaction_date',
    responsive: ['md'],
  },
  {
    title: 'NAV Date',
    dataIndex: 'navdate',
    responsive: ['md'],
  },
];

// Uhr column data
export const columnsUhr = [
  {
    title: 'S. No',
    dataIndex: 'sno',
    responsive: ['md'],
  },
  {
    title: 'Name',
    dataIndex: 'investorname',
    responsive: ['md'],
  },
  {
    title: 'Class',
    dataIndex: 'class_name',
    responsive: ['md'],
  },
  {
    title: 'Transaction Number',
    dataIndex: 'account_number',
    responsive: ['md'],
  },
  {
    title: 'Amount',
    dataIndex: 'contributionamount',
    responsive: ['md'],
  },
];

export const columnsDDR = [
  {
    title: 'Client Name',
    dataIndex: 'clientname',
    responsive: ['md'],
  },
  {
    title: 'Scheme Name',
    dataIndex: 'schemename',
    responsive: ['md'],
  },
  {
    title: 'Class Name',
    dataIndex: 'class_name',
    responsive: ['md'],
  },
  {
    title: 'Folio No.',
    dataIndex: 'folio_no',
    responsive: ['md'],
  },
  {
    title: 'Drawdown Value',
    dataIndex: 'drawdown_value',
    responsive: ['md'],
  },
  {
    title: 'Drawdown Start Date',
    dataIndex: 'drawdown_start_date',
    responsive: ['md'],
    render: (_, record) => (
      <>{moment(record.drawdown_start_date).format('DD/MM/YYYY')}</>
    ),
  },
  {
    title: 'Drawdown End Date',
    dataIndex: 'drawdown_end_date',
    responsive: ['md'],
    render: (_, record) => (
      <>{moment(record.drawdown_end_date).format('DD/MM/YYYY')}</>
    ),
  },
];

export const columnsAxiaMis = [
  {
    title: 'S. No',
    dataIndex: 'sno',
    responsive: ['md'],
  },
  {
    title: 'Name',
    dataIndex: 'investorname',
    responsive: ['md'],
  },
  {
    title: 'Class',
    dataIndex: 'class_name',
    responsive: ['md'],
  },
  {
    title: 'Transaction Number',
    dataIndex: 'account_number',
    responsive: ['md'],
  },
  {
    title: 'Amount',
    dataIndex: 'contributionamount',
    responsive: ['md'],
  },
];

export const columnsAxiaPreAllotment = [
  {
    title: 'S. No',
    dataIndex: 'sno',
    responsive: ['md'],
  },
  {
    title: 'Name',
    dataIndex: 'investorname',
    responsive: ['md'],
  },
  {
    title: 'Class',
    dataIndex: 'class_name',
    responsive: ['md'],
  },
  {
    title: 'Transaction Number',
    dataIndex: 'account_number',
    responsive: ['md'],
  },
  {
    title: 'Amount',
    dataIndex: 'contributionamount',
    responsive: ['md'],
  },
];

export const columnsAxiaPostAllotment = [
  {
    title: 'S. No',
    dataIndex: 'sno',
    responsive: ['md'],
  },
  {
    title: 'Name',
    dataIndex: 'investorname',
    responsive: ['md'],
  },
  {
    title: 'Class',
    dataIndex: 'class_name',
    responsive: ['md'],
  },
  {
    title: 'Transaction Number',
    dataIndex: 'account_number',
    responsive: ['md'],
  },
  {
    title: 'Amount',
    dataIndex: 'contributionamount',
    responsive: ['md'],
  },
];
