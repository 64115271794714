import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ServiceRequests } from "..";
import { useStateContext } from "../../contexts/ContextProvider";
import Transactions from "../Transactions/Transactions";
import Profile from "../Profile";
import Funds from "../Funds";
import Classes from "../Classes";
// import ReportGeneration from "../Reports/ReportGeneration";
import ReportGeneration from "../Reports/RptGeneration";
import QuerySelection from "../QueryModule/QuerySelection";
import DocumentUpload from "../DocumentUpload";
import AdvanceQuerySelection from "../QueryModule/AdavanceQuery/AdvanceQuerySelection";
import QueryProfileDetails from "../QueryModule/QueryDetails/QueryProfileDetails";
import DocumentVault from "../documentVault/DocumentVault";
import Dashboard from "../Dashboard/Dashboard";
import ChangePassword from "components/ChangePassword/ChangePassword";
import TermsOfUse from "pages/TermsOfUse/TermsOFUse";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import AccountStatement from "pages/AccountStatement/AccountStatement";
const Application = () => {
  const { accountNumber, loggedIn, clientId } = useStateContext();

  return (
    <div style={{ overflowX: "hidden", height: "fit-content" }}>
      <Routes>
        <Route
          path="/Login"
          element={
            loggedIn ? (
              <Navigate to={`/Dashboard`} />
            ) : (
              <Navigate to={`/Login`} />
            )
          }
        />

        <Route
          path="/Dashboard"
          element={loggedIn ? <Dashboard /> : <Navigate to={`/Login`} />}
        />

        <Route
          path="/Transactions"
          element={
            loggedIn ? (
              <Transactions data={clientId} />
            ) : (
              <Navigate to={`/Login`} />
            )
          }
        />

        <Route
          path="/Reports"
          element={
            loggedIn ? (
              <ReportGeneration clientId={1} />
            ) : (
              <Navigate to={`/Login`} />
            )
          }
        />

        <Route
          path="/ServiceRequests"
          element={loggedIn ? <ServiceRequests /> : <Navigate to={`/Login`} />}
        />
        <Route
          path="/Profile"
          element={loggedIn ? <Profile /> : <Navigate to={`/Login`} />}
        />
        <Route
          path="/Funds"
          element={loggedIn ? <Funds /> : <Navigate to={`/Login`} />}
        />
        <Route
          path="/Classes"
          element={loggedIn ? <Classes /> : <Navigate to={`/Login`} />}
        />

        <Route
          path={`/Query/ProfileData/Profile`}
          element={
            loggedIn ? (
              <QueryProfileDetails data={accountNumber} />
            ) : (
              <Navigate replace to={`/Login`} />
            )
          }
        />

        <Route
          path={`/QuerySelection`}
          element={
            loggedIn ? <QuerySelection /> : <Navigate replace to={`/Login`} />
          }
        />
        <Route
          path={`/AdvancedQuerySelection`}
          element={
            loggedIn ? (
              <AdvanceQuerySelection />
            ) : (
              <Navigate replace to={`/Login`} />
            )
          }
        />

        <Route
          path={`/DocumentUpload`}
          element={
            loggedIn ? <DocumentUpload /> : <Navigate replace to={`/Login`} />
          }
        />

        <Route
          path={`/DocumentVault`}
          element={
            loggedIn ? <DocumentVault /> : <Navigate replace to={`/Login`} />
          }
        />
        <Route
          path={`/ChangePassword`}
          element={
            loggedIn ? <ChangePassword /> : <Navigate replace to={`/Login`} />
          }
        />
        <Route
          path={`/TermsOfUse`}
          element={
            loggedIn ? <TermsOfUse /> : <Navigate replace to={`/Login`} />
          }
        />
        <Route
          path={`/PrivacyPolicy`}
          element={
            loggedIn ? <PrivacyPolicy /> : <Navigate replace to={`/Login`} />
          }
        />
        <Route
          path={`/AccountStatement`}
          element={
            loggedIn ? <AccountStatement /> : <Navigate replace to={`/Login`} />
          }
        />
      </Routes>
    </div>
  );
};

export default Application;
