import React, { useEffect, useRef } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { Modal } from "antd";
import { SideBar, Navbar } from "./components";
import { Routes, Route, Navigate } from "react-router-dom";
import AppRoutes from "./pages/auth/Approutes";
import Login from "./pages/auth/LoginManagement/Login";
import { useStateContext } from "contexts/ContextProvider";
import ForgotPassword from "./pages/auth/LoginManagement/ForgotPassword";
import ChangePassword from "./pages/auth/LoginManagement/ChangePassword";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store";
import { STRINGS } from "resources";

const App = () => {
  const { loggedIn, handleLogOut } = useStateContext();
  const { activeMenu } = useStateContext();
  const timeoutRef = useRef(null);
  useEffect(() => {
    const resetTimer = () => {
      if (loggedIn) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          handleLogOut();
          Modal.warning({
            title: STRINGS.LOGOUT_MESSAGE,
            content: "Please log in again.",
            onOk: () => {
              handleLogOut();
            },
          });
        }, 30 * 60 * 1000);
      }
    };

    window.addEventListener("click", resetTimer);
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    return () => {
      window.removeEventListener("click", resetTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      clearTimeout(timeoutRef.current);
    };
  }, [loggedIn, handleLogOut]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {loggedIn ? (
          <div className="flex relative dark:bg-main-dark-bg">
            <Routes>
              <Route
                path="/"
                element={
                  !loggedIn ? <Login /> : <Navigate replace to={"/Dashboard"} />
                }
              />
            </Routes>
            {activeMenu ? (
              <div
                className="w-64 fixed sidebar
                    dark:bg-secodary-dark-bg
                    bg-white"
              >
                <SideBar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secodary-dark-bg">
                <SideBar />
              </div>
            )}
            <div
              className={`max-h-max dark:bg-main-bg min-h-screen bg-main-bg w-full ${
                activeMenu ? "md:ml-64" : "flex-2"
              }`}
            >
              <div
                className="fixed md:static bg-main-bg dark:bg-main-bg navbar w-full"
                style={{ backgroundColor: "#F1F6F9" }}
              >
                <Navbar />
              </div>
              <div className="h-full" style={{ backgroundColor: "#F1F6F9" }}>
                <div>
                  <AppRoutes />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route
              path="/"
              element={!loggedIn ? <Login /> : <Navigate replace to="/" />}
            />
            <Route
              path="/Login"
              element={!loggedIn ? <Login /> : <Navigate replace to="/" />}
            />
            <Route
              path="*"
              element={!loggedIn ? <Login /> : <Navigate replace to="/" />}
            />
          </Routes>
        )}
      </PersistGate>
    </Provider>
  );
};

export default App;
