import { myApi } from "./apiInstance";
import endpoints from "./endpoints";


export const getCountry = async (data) => {
    try {
        return await myApi.get(`${endpoints.GET_COUNTRY}?countryid=${data}`)
            .then(res => {
                return res.data.responseData[0]?.code_description;
            });
    } catch (err) {
        return console.log(err);
    }
};
