import React from "react";

const TermsOfUse = () => {
  const styles = {
    container: {
      flex: 1,
      padding: 20,
      textAlign: "justify",
    },
    heading: {
      fontSize: 20,
      marginTop: 10,
      color: "#113293",
      textAlign: "center",
      fontWeight: "bold",
    },
    bold: {
      fontWeight: "bold",
      fontSize: 16,
      marginTop: 10,
      color: "#0a0a0a",
    },
    regular: {
      fontSize: 14,
      marginTop: 10,
      color: "#0a0a0a",
    },
    lastSpacer: {
      height: 100,
    },
  };
  return (
    <div>
      <div style={styles.container}>
        <div style={styles.heading}>Terms of Use</div>
        <div style={styles.regular}>LAST REVISION: 16th March 2023</div>
        <div style={styles.regular}>
          PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS
          WEBSITE OR ORDERING PRODUCTS & SERVICES OR SERVICES FROM THIS WEBSITE,
          YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS
          AGREEMENT.
        </div>
        <div style={styles.regular}>
          <div style={styles.bold}>
            Validus is in the business of providing Digital, IT and IT-enabled
            services to various companies in the BFSI hereinafter referred as
            "The company / Validus".
          </div>
        </div>
        <div style={styles.regular}>
          This Terms of Service Agreement (the "Agreement") governs your use of
          this website, https://www.validusfintech.com (the "Website"), Validus
          offer of PRODUCTS & SERVICES for purchase on this Website or your
          purchase of PRODUCTS & SERVICES available on this Website. This
          Agreement includes, and incorporates by this reference, the policies
          and guidelines referenced below. Validus reserves the right to change
          or revise the terms and conditions of this Agreement at any time by
          posting any changes or a revised Agreement on this Website. Validus
          will alert you that changes or revisions have been made by indicating
          on the top of this Agreement the date it was last revised. The changed
          or revised Agreement will be effective immediately after it is posted
          on this Website. Your use of the Website following the posting of any
          such changes or of a revised Agreement will constitute your acceptance
          of any such changes or revisions. Validus encourages you to review
          this Agreement whenever you visit the Website to make sure that you
          understand the terms and conditions governing the use of the Website.
          This Agreement does not alter in any way the terms or conditions of
          any other written agreement you may have with Validus for other
          PRODUCTS & SERVICES or services. If you do not agree to this Agreement
          (including any referenced policies or guidelines), please immediately
          terminate your use of the Website. If you would like to print this
          Agreement, please click the print button on your browser toolbar.
        </div>
        <div style={styles.bold}>I. PRODUCTS</div>
        <div style={styles.regular}>
          Terms of Offer. This Website offers for sale of certain PRODUCTS &
          SERVICES (the "Products"). By placing an order for PRODUCTS & SERVICES
          through this Website, you agree to the terms set forth in this
          Agreement.
        </div>
        <div style={styles.bold}>Customer Solicitation:</div>
        <div style={styles.regular}>
          Unless you notify our third-party call center reps or direct Validus
          sales reps, while they are calling you, of your desire to opt-out from
          further direct company communications and solicitations, you are
          agreeing to continue to receive further emails and call solicitations
          Validus and its designated in house or third party call team(s).
        </div>
        <div style={styles.bold}>Opt-Out Procedure:</div>
        <div style={styles.regular}>
          We provide 3 easy ways to opt-out of future solicitations. 1. You may
          use the opt-out link found in any email solicitation that you may
          receive. 2. You may also choose to opt-out, via sending your email
          address to hello@validustechfin.com
        </div>
        <div style={styles.regular}>
          3. You may send a written removal request to our registered address.
        </div>
        <div style={styles.bold}>Applicable Law</div>
        <div style={styles.regular}>
          By visiting this website, you agree that the laws of the country,
          without regard to principles of conflict laws, will govern these terms
          of service, or any dispute of any sort that might come between Validus
          and you, or its business partners and associates.
        </div>
        <div style={styles.bold}>Disputes</div>
        <div style={styles.regular}>
          Any dispute related in any way to your visit to this website or to
          products you purchase from us shall be arbitrated by state or federal
          court and you consent to exclusive jurisdiction and venue of such
          courts.
        </div>
        <div style={styles.bold}>Comments, Reviews, and Emails</div>
        <div style={styles.regular}>
          Visitors may post content as long as it is not obscene, illegal,
          defamatory, threatening, infringing of intellectual property rights,
          invasive of privacy or injurious in any other way to third parties.
          Content has to be free of software viruses, political campaigns, and
          commercial solicitation.
        </div>
        <div style={styles.bold}>License and Site Access</div>
        <div style={styles.regular}>
          We grant you a limited license to access and make personal use of this
          website. You are not allowed to download or modify it. This may be
          done only with written consent from us.
        </div>
        <div style={styles.bold}>User Account</div>
        <div style={styles.regular}>
          If you are an owner of an account on this website, you are solely
          responsible for maintaining the confidentiality of your private user
          details (username and password). You are responsible for all
          activities that occur under your account or password.
        </div>
        <div style={styles.bold}>Entire Agreement</div>
        <div style={styles.regular}>
          These terms and conditions, including the policies incorporated herein
          by express reference, constitute your entire agreement with us with
          respect to your use of our website.
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
