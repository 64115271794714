import React, { useState } from "react";
import { generateFamilyFolioSoa } from "services/QueryServices";
import { notification } from "antd";

const DownloadFamilyFolioSoa = ({ family_account_no, client_id }) => {
  const [loading, setLoading] = useState(false);
  const generateSOA = async () => {
    try {
      setLoading(true);
      const res = await generateFamilyFolioSoa({
        client_id,
        family_account_no,
      });
      if (res.responseData?.length) {
        window.open(res.responseData[1], "_blank");
        setLoading(false);
      } else {
        notification.error({
          message: "info",
          description: res.responseData,
          placement: "topRight",
          duration: 10,
        });
        setLoading(false);
      }
    } catch (err) {
      notification.error({
        message: "error",
        description: `Something went wrong!, ${err}`,
        placement: "topRight",
        duration: 5,
      });
      setLoading(false);
    }
  };
  return (
    <div
      className="text-white hover:bg-validus-blue bg-validus-red px-3 py-1 rounded-lg cursor-pointer"
      onClick={generateSOA}
    >
      {loading ? "Loading..." : "Generate Family Folio SOA"}
    </div>
  );
};

export default DownloadFamilyFolioSoa;
