import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { getClasses } from "services/profileServices";
import { useDispatch, useSelector } from "react-redux";
import { setClassData } from "store/clientData.slice";
const Funds = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { fundData } = useSelector((state) => state.clientData);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "S. No",
      dataIndex: "sno",
      align: "center",
    },
    {
      title: "Class Name",
      dataIndex: "investment_class_description",
      align: "center",
    },
    {
      title: "Class Code",
      dataIndex: "investment_class_code",
      align: "center",
    },
    {
      title: "View Details",
      dataIndex: "",
      align: "center",

      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              dispatch(setClassData(record));
            }}
            style={{
              backgroundColor: "#A3020C",
              color: "white",
              borderRadius: 5,
            }}
          >
            <Link to={{ pathname: `/Classes` }}>View</Link>
          </Button>
        );
      },
    },
  ];

  const showClasses = async () => {
    try {
      const res = await getClasses(fundData?.fund_ID);
      if (res.responseData) {
        res.responseData.forEach((e, i) => {
          e.sno = i + 1;
        });
        setTableData(res.responseData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    showClasses();
  }, []);

  return (
    <>
      <h1 className="text-validus-red text-center text-2xl font-bold">
        {fundData?.fund_Description}
      </h1>
      <div
        className="flex flex-col bg-[#f5f5f5] rounded-md  p-6 mx-4"
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="flex flex-row justify-between">
          <div className="w-[50%] flex flex-col">
            <h2 style={{ fontSize: "16px" }}>Fund Details</h2>
            <p>Fund Name : {fundData.fund_Description}</p>
            <p>Fund Code : {fundData.code}</p>
            <p>Fund Type : {fundData.fund_type_description}</p>
            <p>Fund Category : {fundData.fund_category_description}</p>
            <p>Fund Sub Category : {fundData.fund_sub_catergory_description}</p>

            <h2 style={{ fontSize: "16px" }}>Investor Details</h2>
            <p>Minimum no.of Investors : {fundData.minimum_Investor_Count}</p>
            <p>Maximum no.of Investors : {fundData.maximum_Investor_Count}</p>
          </div>
          <div className="w-[50%] flex flex-col">
            <h2 style={{ fontSize: "16px" }}>Registration Details</h2>
            <p>
              SEBI Registration No : {fundData.regulator_Registration_Number}
            </p>
            <p>
              Maturity Date :{" "}
              {moment(fundData.maturity_Date).format("DD-MM-YYYY")}
            </p>
            <p>
              Stampduty Payable by Investor :{" "}
              {fundData.stampduty_payable_investor === "Y" ? "Yes" : "No"}
            </p>

            <p>Face Value: {fundData.naV_Frequency}</p>
            <p>Units Round Off : {fundData.unit_Round_Type} </p>
            <p>
              Purchase Cut Off :{" "}
              {`${fundData.purchase_cutoff_time} ${moment(
                fundData.purchase_cutoff_time,
                "HH:mm"
              ).format("A")}`}
            </p>
            <p>
              Redemption Cut Off :{" "}
              {`${fundData.redemption_cutoff_time} ${moment(
                fundData.redemption_cutoff_time,
                "HH:mm"
              ).format("A")}`}
            </p>
            <p> Lock in Period : {fundData.lock_in_period} days</p>
          </div>
        </div>
      </div>

      <h1 className="text-validus-red text-center text-2xl font-bold my-8">
        Classes for {fundData?.code_Description}
      </h1>
      <Table
        className="w-[98%] mx-auto"
        rowKey={(record) => record.index}
        columns={columns}
        dataSource={tableData}
        bordered
        loading={loading}
      />
    </>
  );
};

export default Funds;
