import React, { useState, useRef } from "react";
import { Input, Table, Button, Modal } from "antd";
import DocumentUploadDrawer from "components/DocumentVault/DocumentUploadDrawer";
import { getCurrentTimeAndDate } from "components/utils/helperFunction";
import { getVaultDetails, uploadS3Links } from "services/ApiServices";
import { MdFileDownload } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";

const DocumentVault = () => {
  const [folioOrTransactionNumber, setFolioOrTransactionNumber] =
    useState(null);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [documentVaultDetails, setDocumentVaultDetails] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedDocumentFiles, setSelectedDocumentFiles] = useState([]);
  const [zipLoading, setZipLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const isBulkSelectingRef = useRef(false);
  const onSelectChange = (newSelectedRowKeys) => {
    if (!isBulkSelectingRef.current) {
      let documentList = documentVaultDetails?.reduce(
        (commonDocument, document) => {
          if (newSelectedRowKeys.includes(document.vault_id)) {
            commonDocument.push(document.s3_path);
          }

          return commonDocument;
        },
        []
      );
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedDocumentFiles(documentList);
    }
  };
  const onSelectAll = (selected, selectedRows, changeRows) => {
    isBulkSelectingRef.current = true;
    if (selectedRowKeys.length !== documentVaultDetails.length) {
      setSelectedRowKeys((prev) =>
        documentVaultDetails.map((doc) => doc.vault_id)
      );
      setSelectedDocumentFiles((prev) =>
        documentVaultDetails.map((doc) => doc.s3_path)
      );
    } else {
      setSelectedRowKeys([]);
      setSelectedDocumentFiles([]);
    }
    setTimeout(() => {
      isBulkSelectingRef.current = false;
    }, 0);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelectAll: onSelectAll,
  };

  const allColumns = [
    {
      title: "S. No",
      //dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => {
        return (pagination.current - 1) * pagination.pageSize + index + 1;
      },
      responsive: ["md"],
    },
    {
      title: "Investor Name",
      dataIndex: "investor_name",
    },
    {
      title: "Document Name",
      dataIndex: "document_name",
    },

    {
      title: "Account Number",
      dataIndex: "account_number",
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => {
        return (
          <div className="flex justify-center items-center">
            <div
              className={`flex ${
                record.s3_path ? "bg-validus-red" : "bg-validus-grey"
              }  text-white px-4 py-1 rounded-md cursor-pointer mx-2 justify-between items-center `}
              onClick={() => {
                const fileIdMatch = record?.s3_path.match(
                  /(?:\/d\/|id=)([\w-]+)/
                );
                if (fileIdMatch) {
                  const fileId = fileIdMatch[1];
                  const viewUrl = `https://drive.google.com/file/d/${fileId}/view`;

                  window.open(viewUrl, "_blank");
                } else {
                  record.s3_path
                    ? window.open(
                        record.s3_path.replace(
                          "&export=download",
                          "&export=view"
                        ),
                        "_blank"
                      )
                    : Modal.warning({
                        title: "File Unavailable",
                        content: "File does not exist",
                      });
                }
              }}
            >
              <AiOutlineEye style={{ fontSize: "15px", marginRight: "3px" }} />
              View
            </div>
            <div
              className={`flex ${
                record.s3_path ? "bg-validus-red" : "bg-validus-grey"
              }  text-white px-4 py-1 rounded-md cursor-pointer  justify-between items-center`}
              onClick={() => {
                if (record.s3_path) {
                  const fileUrl = record.s3_path;
                  const link = document.createElement("a");
                  link.href = fileUrl;
                  link.download = ""; // You can specify the file name here
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                } else
                  Modal.warning({
                    title: "File Unavailable",
                    content: "File does not exist",
                  });
              }}
            >
              <MdFileDownload
                style={{ fontSize: "15px", marginRight: "3px" }}
              />{" "}
              Download
            </div>
          </div>
        );
      },
    },
  ];

  //Open Drawer
  const openDocumentUpload = () => {
    setOpenDrawer(true);
  };

  //Fetch the document details
  const fetchVaultDetails = async () => {
    let res = await getVaultDetails(folioOrTransactionNumber);
    setDocumentVaultDetails(res.responseData);
  };

  const handleFolioAndTransactionNumber = (event) => {
    setFolioOrTransactionNumber(event.target.value);
  };

  //Dowload bulk files
  const handleDownloadFiles = async () => {
    try {
      const time_date_stamp = getCurrentTimeAndDate();

      let selectedRowswithLink = selectedDocumentFiles?.filter((row) => row);

      setZipLoading(true);
      const zipFile = await uploadS3Links(selectedRowswithLink);

      const binaryString = atob(zipFile.responseData); //Decoding base64 string
      const binaryData = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        binaryData[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([binaryData], { type: "application/zip" });

      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `KYC_${folioOrTransactionNumber}_${time_date_stamp}`; // Desired file name
      link.click();

      URL.revokeObjectURL(url);
      setZipLoading(false);
      setSelectedDocumentFiles([]);
      setSelectedRowKeys([]);
    } catch (err) {
      setZipLoading(false);

      console.log("Error in the bulk download api call", err);
    }
  };

  return (
    <>
      <h1 className="text-center text-validus-red text-2xl font-semibold">
        Document Vault
      </h1>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "space-between",
            marginLeft: "3%",
            marginTop: "3%",
            marginRight: "3%",
            gap: 10,
          }}
        >
          <Input
            name="FolioOrTransactionNumber"
            placeholder="Enter Folio Number"
            style={{
              fontSize: "14px",
              color: "#A3020C",
              width: "300px",
            }}
            allowClear
            onChange={handleFolioAndTransactionNumber}
          />

          <Button
            //disabled={disableGetButton}
            onClick={() => fetchVaultDetails()}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "140px",
              height: "33px",
              backgroundColor: "#A3020C",
              color: "white",
              borderRadius: 5,
            }}
          >
            Get
          </Button>
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              width: "140px",
              height: "33px",
              backgroundColor: "#A3020C",
              color: "white",
              borderRadius: 5,
            }}
            onClick={openDocumentUpload}
          >
            Document Upload
          </Button>
        </div>
        <div className="flex flex-col position-relative bg-[#f1f1f1] border border-gray-400 w-[99%] mx-auto mb-4 h-[70%] mt-4">
          <div>
            {selectedRowKeys && selectedRowKeys.length > 0 ? (
              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  minWidth: "200px",
                  height: "33px",
                  backgroundColor: "#A3020C",
                  color: "white",
                  borderRadius: 5,
                  float: "right",
                  margin: "10px",
                  padding: "5px",
                }}
                loading={zipLoading}
                onClick={handleDownloadFiles}
              >
                Download Bulk Document
              </Button>
            ) : null}
            <Table
              rowKey="vault_id"
              columns={allColumns}
              dataSource={documentVaultDetails}
              bordered
              rowSelection={rowSelection}
              pagination={{
                position: ["bottomRight"],
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "40",
                  "50",
                  "60",
                  "70",
                  "80",
                  "90",
                  "100",
                ],

                current: pagination.current,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => {
                  setPagination({ current: page, pageSize });
                },
              }}
            />
          </div>
        </div>
      </div>
      <DocumentUploadDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
    </>
  );
};

export default DocumentVault;
