import React, { useState } from "react";
import "antd/dist/antd.min.css";
import { Input, Form, notification } from "antd";
import { groupBy } from "lodash";
import { useStateContext } from "contexts/ContextProvider";
import QueryProfileData from "../QueryDetails/QueryProfileData";
import { Link } from "react-router-dom";
import { getAdvancedSearchData } from "services/QueryServices";
import { getFamilyFolioDetailsByFolio } from "services/ApiServices";
import FamilyFolioDataScreen from "../QueryDetails/FamilyFolioDataScreen";
import { useSelector } from "react-redux";

const SuggestedQueryList = (props) => {
  const { user_id } = useStateContext();
  const { userData } = useSelector((state) => state.userAuth);
  const data = props.searchFields;
  const groupedData = groupBy(data, "search_fieldid");
  const [inputs, setInputs] = useState([]);
  const [selectedQueryData, setSelectedQueryData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [familyFolioNumber, setFamilyFolioNumber] = useState("");
  const [familyFolioData, setFamilyFolioData] = useState(null);

  const scrollDownAfterSearch = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 10);
  };

  const searchAdvanceQuery = async () => {
    if (familyFolioNumber) {
      try {
        const res = await getFamilyFolioDetailsByFolio(familyFolioNumber);
        if (res?.responseData?.data) {
          setFamilyFolioData(res.responseData);
          scrollDownAfterSearch();
        } else {
          setFamilyFolioData(null);
          notification.info({
            message: "Info",
            description: `Family Folio Number Doesn't Exists!`,
            duration: 5,
          });
        }
      } catch (err) {
        console.log(err);
        setFamilyFolioData(null);
      }
    } else {
      const apiData = inputs.reduce((acc, input, subId) => {
        if (input) {
          acc.push({
            search_sub_fieldid: subId,
            value: input,
          });
        }
        return acc;
      }, []);

      const hasTransactionType = apiData.some(
        ({ search_sub_fieldid }) => search_sub_fieldid === 17
      );
      const uniqueApplicationNumbers = new Set();

      try {
        const res = await getAdvancedSearchData({
          user_id: user_id,
          type: hasTransactionType,
          payload: apiData,
          client_id: userData?.client_id,
        });
        if (res.responseData?.length) {
          const response = res.responseData;
          response.forEach(({ applicationnumber, ...temp }) => {
            if (uniqueApplicationNumbers.has(applicationnumber)) return;
            uniqueApplicationNumbers.add(applicationnumber);
            setSelectedQueryData((prevData) => [
              ...prevData,
              { applicationnumber, ...temp },
            ]);
          });
          scrollDownAfterSearch();
          setShowTable(true);
        } else {
          notification.info({
            title: "Info",
            message: "No data found. Please Enter Correct Details.",
          });
          setShowTable(false);
        }
      } catch (err) {
        console.log(err);
        setShowTable(false);
      }
    }
  };

  const handleInputChange = (index, value, subId) => {
    const newInputs = [...inputs];
    newInputs[subId] = value;
    setInputs(newInputs);
  };

  const renderInputFields = (items) => {
    const suggestedItems = items.filter(
      (item) => item.search_type === "Suggested"
    );
    if (suggestedItems.length === 0) {
      return null;
    }

    const fields = {};
    suggestedItems.forEach((item) => {
      const { search_sub_fieldid, search_sub_field_name } = item;
      if (!fields[search_sub_fieldid]) {
        fields[search_sub_fieldid] = {
          search_sub_fieldid,
          search_sub_field_name,
          inputs: [],
        };
      }
      fields[search_sub_fieldid].inputs.push(item);
    });

    const inputsArr = Object.values(fields)
      .map((field) => field.inputs)
      .flat();

    return (
      <div className="dark:text-gray-200 dark:bg-secondary-dark-bg rounded-md w-96">
        {inputsArr.map((input, index) => (
          <div className="flex flex-wrap">
            <div key={input.search_sub_fieldid} className="flex-1">
              <label className="font-medium">
                {input.search_sub_field_name}:
              </label>
              <div>
                <Form.Item name={input.search_sub_field_name} className="h-4">
                  <Input
                    placeholder={`Enter ${input.search_sub_field_name}`}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        e.target.value,
                        input.search_sub_fieldid
                      )
                    }
                    value={inputs[input.search_sub_fieldid]}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <h1 className="text-center text-2xl text-validus-red font-semibold">
        Investor Query
      </h1>

      <div className="flex flex-col items-center justify-center">
        <div className="bg-validus-blue text-white font-semibold text-base py-1 px-1 rounded mb-4">
          Suggested Search Fields
        </div>
        {/* <div className="dark:text-gray-200 dark:bg-secondary-dark-bg rounded-md w-96">
          <label className="font-medium">Family Folio Number:</label>

          <Form.Item name="Family Folio Number" className="h-4">
            <Input
              placeholder={`Enter Family Folio Numer`}
              onChange={({ target: { value } }) => {
                setFamilyFolioNumber(value);
                value.length === 0 && setFamilyFolioData(null);
              }}
              value={familyFolioNumber}
            />
          </Form.Item>
        </div> */}
        {Object.entries(groupedData).map(([fieldId, items]) =>
          renderInputFields(items)
        )}
      </div>

      <div className="flex mx-auto w-96 items-center justify-end">
        <h1 className="text-right text-validus-blue font-bold">
          <Link
            to={{ pathname: `/AdvancedQuerySelection` }}
            state={{ searchData: data }}
            className="text-validus-blue underline"
          >
            Advanced Query
          </Link>
        </h1>
      </div>

      <div className="flex mx-auto w-96 items-center justify-end mt-6">
        <div
          onClick={searchAdvanceQuery}
          className="flex bg-validus-red hover:bg-validus-blue text-white px-4 py-1 rounded-md cursor-pointer"
        >
          Search
        </div>
      </div>

      {familyFolioData && familyFolioNumber && (
        <FamilyFolioDataScreen familyData={familyFolioData} />
      )}

      {showTable && <QueryProfileData data={selectedQueryData} />}
    </>
  );
};
export default SuggestedQueryList;
