import React, { useEffect, useState } from "react";
import { getAddressData } from "services/profileServices";
import DownloadFamilyFolioSoa from "./DownloadFamilyFolioSoa";

const FamilyFolioDataScreen = ({ familyData }) => {
  const { data } = familyData;
  const [addressData, setAddressData] = useState({});
  const InfoItem = ({ label, value }) => (
    <div className="flex flex-row w-full mt-2">
      <div className="w-[33%] font-normal">{label} :</div>
      <div className="w-[67%] font-semibold">{value}</div>
    </div>
  );

  const fetchAddressDetails = async () => {
    try {
      const res = await getAddressData(data?.address);
      if (res.responseData) {
        setAddressData(res.responseData[0]);
      } else {
        setAddressData({});
      }
    } catch (err) {
      console.log(err);
      setAddressData({});
    }
  };

  useEffect(() => {
    fetchAddressDetails();
  }, []);

  return (
    <div>
      <div className="text-validus-red text-center text-2xl mt-3">
        Family Folio Details
      </div>
      <div className="w-[95%] bg-white rounded-lg mx-auto p-5 mt-3">
        <div className="flex justify-end">
          <DownloadFamilyFolioSoa
            family_account_no={data.family_account_no}
            client_id={data.client_id}
          />
        </div>
        <div className="flex flex-row justify-between w-full">
          <InfoItem label="Family Name" value={data?.family_name} />
          <InfoItem
            label="Family Head Name"
            value={
              data?.head_of_family_firstname
                ? `${data?.head_of_family_firstname} ${data?.head_of_family_lastname}`
                : ""
            }
          />
        </div>
        <div className="flex flex-row justify-between w-full">
          <InfoItem label="PAN Number" value={data?.head_of_family_pan} />
          <InfoItem
            label="Family Folio Number"
            value={data?.family_account_no}
          />
        </div>
        <div className="flex flex-row justify-between w-full">
          <InfoItem label="Email Address" value={data?.email_id} />
          <InfoItem label="Mobile Number" value={data?.mobile_number} />
        </div>
        <div className="flex flex-row justify-between w-full">
          <InfoItem
            label="Address Line 1"
            value={addressData?.address_line1 ?? "NA"}
          />
          <InfoItem
            label="Address Line 2"
            value={addressData?.address_line2 ?? "NA"}
          />
        </div>
        <div className="flex flex-row justify-between w-full">
          <InfoItem label="City" value={addressData?.city ?? "NA"} />
          <InfoItem
            label="District"
            value={addressData?.district_county ?? "NA"}
          />
        </div>
        <div className="flex flex-row justify-between w-full">
          <InfoItem
            label="State"
            value={addressData?.state_province_text ?? "NA"}
          />
          <InfoItem label="Pincode" value={addressData?.pincode ?? "NA"} />
        </div>
      </div>
    </div>
  );
};

export default FamilyFolioDataScreen;
