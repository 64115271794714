import { Divider, Modal, notification } from "antd";
import { useStateContext } from "contexts/ContextProvider";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  IoNewspaperOutline,
  IoShieldCheckmarkOutline,
  IoTimeOutline,
  IoTrashBinOutline,
  IoLockClosedOutline,
} from "react-icons/io5";
import { IoIosPower } from "react-icons/io";
import { deleteUserAPI } from "services/loginServices";

const Settings = () => {
  const { setOpenSettingsDrawer, last_login_date, handleLogOut, user_id } =
    useStateContext();
  const navigate = useNavigate();
  const deleteTheUser = async () => {
    try {
      const res = await deleteUserAPI(user_id);
      if (res.responseData === "User Deleted successfully") {
        handleLogOut();
        notification.success({
          message: "Success",
          description: res.responseData,
        });
      } else {
        notification.error({
          message: "Error",
          description: res.responseData,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <div className="flex flex-row items-center gap-x-4">
        <div className="bg-slate-200 p-2 rounded-full">
          <IoTimeOutline size={30} />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">Last Login</div>
          <div className="text-xs">
            {moment(last_login_date).format("DD-MM-YYYY HH:mm:SS A")}
          </div>
        </div>
      </div>
      <Divider />
      <div
        className="flex flex-row items-center gap-x-4 cursor-pointer"
        onClick={() => {
          navigate("/PrivacyPolicy");
          setOpenSettingsDrawer(false);
        }}
      >
        <div className="bg-slate-200 p-2 rounded-full">
          <IoShieldCheckmarkOutline size={30} />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">Privacy Policy</div>
          <div className="text-xs">View Privacy Policy</div>
        </div>
      </div>
      <Divider />
      <div
        className="flex flex-row items-center gap-x-4 cursor-pointer"
        onClick={() => {
          navigate("/TermsOfUse");
          setOpenSettingsDrawer(false);
        }}
      >
        <div className="bg-slate-200 p-2 rounded-full">
          <IoNewspaperOutline size={30} />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">Terms of Use</div>
          <div className="text-xs">View Terms of Use</div>
        </div>
      </div>
      <Divider />
      <div
        className="flex flex-row items-center gap-x-4 cursor-pointer"
        onClick={() => {
          Modal.confirm({
            title: "Delete Account",
            content: "Are you sure you want to delete your account?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: deleteTheUser,
            onCancel: () => {},
          });
        }}
      >
        <div className="bg-slate-200 p-2 rounded-full">
          <IoTrashBinOutline size={30} />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">Delete Account</div>
          <div className="text-xs">Press here to delete your account</div>
        </div>
      </div>
      <Divider />
      <div
        className="flex flex-row items-center gap-x-4 cursor-pointer"
        onClick={() => {
          navigate("/ChangePassword");
          setOpenSettingsDrawer(false);
        }}
      >
        <div className="bg-slate-200 p-2 rounded-full">
          <IoLockClosedOutline size={30} />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">Change Password</div>
          <div className="text-xs">Press here to change your password</div>
        </div>
      </div>
      <Divider />
      <div
        className="flex flex-row items-center gap-x-4 cursor-pointer"
        onClick={() => {
          Modal.confirm({
            title: "Logout",
            content: "Are you sure you want to logout?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: handleLogOut,
            onCancel: () => {},
          });
        }}
      >
        <div className="bg-slate-200 p-2 rounded-full">
          <IoIosPower size={30} />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">Logout</div>
          <div className="text-xs">
            Press here to logout from the application
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
