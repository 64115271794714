import React from "react";
import { Form, Input, Button, notification } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { changePasswordAPI } from "services/loginServices";
import { useStateContext } from "contexts/ContextProvider";
import { useSelector } from "react-redux";
import { STRINGS } from "resources";

const ChangePassword = () => {
  const { userData } = useSelector((state) => state.userAuth);
  const { handleLogOut } = useStateContext();
  const [form] = Form.useForm();

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("newPassword") === value) {
        return Promise.resolve();
      }

      return Promise.reject(new Error(STRINGS.LOGOUT_MESSAGE));
    },
  });

  const onFinish = async (values) => {
    if (values.newPassword === values.confirmPassword) {
      let apiPostData = {
        username: userData?.user_name,
        oldpassword: values.oldPassword,
        confirmpassword: values.confirmPassword,
        creation_by: userData?.user_id,
        updation_by: userData?.user_id,
        isactive: true,
        type: "C",
      };
      try {
        const res = await changePasswordAPI(apiPostData);
        if (
          res.message === "Success" &&
          res.responseData !== "New password already exist"
        ) {
          handleLogOut();
          notification.success({
            message: "Success",
            description: "Password changed successfully",
          });
        } else if (
          res.message === "Success" &&
          res.responseData === "New password already exist"
        ) {
          notification.warning({
            message: "Warning",
            description: "New password already exist",
          });
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: error.message,
        });
      }
    } else {
      notification.info({
        message: "Info",
        description: "New password and Confirm password did not match",
      });
    }
  };

  return (
    <>
      <h1 className="text-center text-2xl font-semibold text-validus-red">
        Change Password
      </h1>
      <div className="mt-4 w-[40%] mx-auto bg-white px-10 py-5 rounded-md flex items-center justify-center">
        <Form
          form={form}
          name="change-password"
          onFinish={onFinish}
          layout="vertical"
          className="w-full"
        >
          <Form.Item
            name="oldPassword"
            label="Old Password"
            rules={[
              {
                required: true,
                message: "Please enter Old Password",
              },
            ]}
          >
            <Input.Password
              size="medium"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Enter Old Password"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please enter New Password",
              },
              {
                pattern: /^(?=.*[a-z])/,
                message: "Password must contain at least one lowercase letter",
              },
              {
                pattern: /^(?=.*[A-Z])/,
                message: "Password must contain at least one uppercase letter",
              },
              {
                pattern: /^(?=.*\d)/,
                message: "Password must contain at least one number",
              },
              {
                pattern: /^(?=.*[!@#$%^&*])/,
                message:
                  "Password must contain at least one special character (!@#$%^&*)",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters long",
              },
            ]}
          >
            <Input.Password
              size="medium"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Enter New Password"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              validateConfirmPassword,
            ]}
          >
            <Input.Password
              size="medium"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Re-Enter New Password"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item className="flex justify-end">
            <Button type="primary" htmlType="submit">
              Change password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
