import React, { useState } from "react";
import "antd/dist/antd.min.css";
import { Input, Form, Modal } from "antd";
import { groupBy } from "lodash";
import { useStateContext } from "contexts/ContextProvider";
import QueryProfileData from "../QueryDetails/QueryProfileData";
import { useLocation } from "react-router-dom";
import { getAdvancedSearchData } from "services/QueryServices";
import { useSelector } from "react-redux";
const MAX_INPUTS_PER_FIELD = 3;

const AdvanceQuerySelection = () => {
  const { userData } = useSelector((state) => state.userAuth);
  const location = useLocation();
  const { searchData } = location.state || {};
  const { user_id } = useStateContext();
  const groupedData = groupBy(searchData, "search_fieldid");
  const [inputs, setInputs] = useState([]);
  const [selectedQueryData, setSelectedQueryData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const scrollDownAfterSearch = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 10);
  };

  const searchAdvanceQuery = async () => {
    let typeofTransaction;
    const apiData = inputs
      .map((input, subId) => ({ input, subId }))
      .filter(
        ({ input }) => input !== undefined && input !== null && input !== ""
      )
      .map(({ input, subId }) => ({
        search_sub_fieldid: subId,
        value: input,
      }));
    typeofTransaction = apiData.some(
      ({ search_sub_fieldid }) => search_sub_fieldid === 17
    )
      ? false
      : true;
    const uniqueApplicationNumbers = new Set();

    try {
      const res = await getAdvancedSearchData({
        user_id: user_id,
        type: typeofTransaction,
        payload: apiData,
        client_id: userData?.client_id,
      });
      if (res.responseData && res.responseData?.length > 0) {
        const response = res.responseData;
        response.forEach((item) => {
          const applicationNumber = item.applicationnumber;
          if (!uniqueApplicationNumbers.has(applicationNumber)) {
            uniqueApplicationNumbers.add(applicationNumber);
            setSelectedQueryData((prevData) => [...prevData, item]);
          }
        });
        scrollDownAfterSearch();
        setIsModalVisible(false);
        setShowTable(true);
      } else {
        setIsModalVisible(true);
        setShowTable(false);
      }
    } catch (err) {
      console.log(err);
      setShowTable(false);
      setIsModalVisible(true);
    }
  };

  const handleInputChange = (index, value, subId) => {
    const newInputs = [...inputs];
    newInputs[subId] = value;
    setInputs(newInputs);
  };

  const renderInputFields = (items, maxInputsPerField) => {
    const fields = {};
    items.forEach((item) => {
      const { search_sub_fieldid, search_sub_field_name, isactive } = item;
      if (!isactive) return; // Skip if isactive is false
      if (!fields[search_sub_fieldid]) {
        fields[search_sub_fieldid] = {
          search_sub_fieldid,
          search_sub_field_name,
          inputs: [],
        };
      }
      fields[search_sub_fieldid].inputs.push(item);
    });

    const inputsArr = Object.values(fields)
      .map((field) => field.inputs)
      .flat();
    const numCols = Math.ceil(inputsArr.length / maxInputsPerField);
    const columns = Array(numCols)
      .fill()
      .map((_, i) =>
        inputsArr.slice(i * maxInputsPerField, (i + 1) * maxInputsPerField)
      );

    return (
      <div
        key={`${items[0].search_fieldid}`}
        className="m-2 p-2 rounded-md shadow-lg bg-white dark:bg-gray-800"
      >
        <div className="bg-validus-blue text-white font-semibold text-md py-2 px-3 rounded mb-4">
          {items[0].search_field_name}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 -gap-y-6">
          {columns.flat().map((input, index) => (
            <div key={input.search_sub_fieldid}>
              <Form.Item name={input.search_sub_field_name}>
                <label className="block text-md font-medium text-gray-700 dark:text-gray-300">
                  {input.search_sub_field_name}
                </label>
                <Input
                  className="border border-gray-300 dark:border-gray-600 rounded px-2 py-1 w-full text-sm"
                  placeholder={`Enter ${input.search_sub_field_name}`}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      e.target.value,
                      input.search_sub_fieldid
                    )
                  }
                  value={inputs[input.search_sub_fieldid]}
                />
              </Form.Item>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <h1 className="text-center text-2xl text-validus-red font-semibold">
        Advanced Query Search
      </h1>

      <div className="grid grid-cols-2 gap-4 w-full">
        {Object.entries(groupedData).map(([fieldId, items]) =>
          renderInputFields(items, MAX_INPUTS_PER_FIELD)
        )}
      </div>

      <div className="flex mx-auto items-center justify-end mt-6 px-2">
        <div
          onClick={searchAdvanceQuery}
          className="flex bg-validus-red hover:bg-validus-blue text-white px-4 py-1 rounded-md cursor-pointer"
        >
          Search
        </div>
      </div>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>No data found. Please Enter Correct Details.</p>
      </Modal>
      {showTable && <QueryProfileData data={selectedQueryData} />}
    </>
  );
};

export default AdvanceQuerySelection;
