export const addIndianCommas = value => {
  if (value === undefined || value === null || value === '') return value;
  const cleanedText = value.toString().replace(/[^0-9]/g, '');
  return cleanedText.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
};

export const removeIndianCommas = value => {
  if (value === undefined || value === null || value === '') return value;
  if (typeof value === 'number') return value;
  return Number(value.replace(/[^0-9]/g, ''));
};

export const formatNumberWithIndianCommas = number => {
  let numString = parseFloat(number).toFixed(3).toString();
  let parts = numString.split('.');
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  const cleanedText = integerPart.toString().replace(/[^0-9]/g, '');
  let formattedIntegerPart = cleanedText.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
  let formattedNumber = formattedIntegerPart + decimalPart;
  return formattedNumber;
};