import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import clientDataReducer from './clientData.slice';
import userAuthReducer from './userAuth.slice';
const workflowPersistConfig = {
  key: 'clientData',
  storage: storageSession,
}

const userAuthPersistConfig = {
  key: 'userAuth',
  storage: storageSession,
}

const rootReducer = combineReducers({
  clientData: persistReducer(workflowPersistConfig, clientDataReducer),
  userAuth: persistReducer(userAuthPersistConfig, userAuthReducer),
  // add more reducers as needed
});

export default rootReducer;
