import { myApi } from "./apiInstance";
import endpoints from "./endpoints";

export const uploadDocstoGDrive = async (fileArray, documentType, path) => {
  try {
    const formData = new FormData();

    fileArray.forEach((file, i) => {
      // Create a new file with a modified name
      const modifiedFile = new File(
        [file],
        `${documentType.name.replace(/\s+/g, "")}_${i}.${file.type.split("/")[1]
        }`,
        { type: file.type }
      );

      // Append the modified file to formData
      formData.append("file", modifiedFile);
    });

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    // Upload the formData with multiple files
    const response = await myApi.post(
      `${endpoints.UPLOAD_DOC_TO_GDRIVE}?folders=${path}`,
      formData,
      { headers }
    );

    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveVaultDetails = async (data) => {
  try {
    return await myApi.post(endpoints.SAVE_VAULT_DETAILS, data).then((res) => {
      return res.data;
    });
  } catch (error) {
    return console.log(error);
  }
};

export const getVaultDetails = async (data) => {
  try {
    return await myApi
      .get(`${endpoints.GET_VAULT_DETAILS}?entityreferencenumber=${data}`)
      .then((res) => {
        return res.data;
      });
  } catch (err) {
    return console.log(err);
  }
};

export const saveVaultEntriesFromExcelApi = async (data) => {
  try {
    const response = await myApi.post(
      `${endpoints.ADD_VAULT_ENTRIES_FROM_EXCEL}`,
      data,
      {}
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getVaultDocumentDetails = async (userClientID) => {
  try {
    return await myApi
      .get(
        `${endpoints.GET_VAULT_DOCUMENT_DETAILS}/${userClientID}/1?numberOfHolders=6&docType=D`,
        {}
      )
      .then((res) => {
        return res.data.responseData;
      });
  } catch (err) {
    return console.log(err);
  }
};

export const getDownloadSOAPath = async data => {
  try {
    return await myApi
      .get(`${endpoints.GET_DOWNLOAD_SOA_PATH}/${data}`, {})
      .then(res => {
        return res.data;
      });
  } catch (err) {
    return console.log(err);
  }
};

export const getValidatedAccountAPI = async (
  accNo,
  userClientId
) => {
  try {
    return await myApi
      .get(`${endpoints.GET_VALIDATED_ACCOUNT}/${accNo}`, {})
      .then(res => {
        if (userClientId === res.data.responseData[0]?.client_id) {
          return res.data;
        } else {
          return {
            responseData: null,
            status: 'ERROR - MISMATCH CLIENT',
            message: 'Invalid Account Number',
          };
        }
      });
  } catch (err) {
    return console.log(err);
  }
};

export const uploadS3Links = async (files) => {
  try {

    const response = await myApi.post(
      `${endpoints.ADD_S3_LINK_BULK_DOWNLOAD}`,
      files,
      {}
    );
    return response.data;
  }

  catch (error) {
    console.log("Error in uploading s3 drive link in the bulk download", error)
  }
}


export const getFamilyFolioDetailsByFolio = async data => {
  try {
    return await myApi
      .post(`${endpoints.GET_FAMILY_FOLIO_DETAILS}?familyfoliono=${data}`)
      .then(res => {
        return res.data;
      });
  } catch (err) {
    return console.log(err);
  }
};
