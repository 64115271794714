import React, { useState, useEffect } from "react";
import { Button, Spin } from "antd";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import moment from "moment";
import { useSelector } from "react-redux";
import { getWorkflow, saveWorkflow } from "services/workflowServices";

const DtrExport = ({ data, fromDate, toDate, fundHouse }) => {
  const { userData } = useSelector((state) => state.userAuth);
  const [exportButton, setExportButton] = useState(true);
  const [workflowData, setWorkflowData] = useState([]);
  const [downloading, setDownloading] = useState(false);
  var workflowdate = moment().utcOffset("+05:30").format();
  const [pageLoadingTimeStamp, setPageLoadingTimeStamp] =
    useState(workflowdate);

  useEffect(() => {
    setExportButton(false);
    if (data.length === 0) {
      setExportButton(true);
    }
  }, [data]);

  useEffect(() => {
    getWorkflowAPI();
  }, []);

  const getWorkflowAPI = async () => {
    try {
      const res = await getWorkflow("URDTR");
      if (res.responseData) {
        setWorkflowData(res.responseData);
      } else {
        return console.log("Error in fetching workflow data");
      }
    } catch (err) {
      return console.log(err);
    }
  };

  const createDownLoadDtrData = () => {
    setDownloading(true);
    insertWorkflowAPI(data);
    if (downloading === false) {
      // Downloading the DTR Report
      handleDtrExport().then((url) => {
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute(
          "download",
          `DailyTransactionReport_from_${moment(fromDate).format(
            "DD-MM-YYYY"
          )}_to_${moment(toDate).format("DD-MM-YYYY")}.xls`
        );
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
        setDownloading(false);
      });
    } else {
      alert("please wait, while the data is being processed");
      setDownloading(false);
    }
  };

  const insertWorkflowAPI = async (data) => {
    var submitTimeStamp = moment().utcOffset("+05:30").format();
    let pageLoadingTimeStampInSeconds = moment(pageLoadingTimeStamp).seconds();
    let submitTimeStampInSeconds = moment(submitTimeStamp).seconds();
    let actualAHT_Seconds = Math.abs(
      submitTimeStampInSeconds - pageLoadingTimeStampInSeconds
    );

    for (let i = 0; i < data.length; i++) {
      if (
        data[i].transactiontypeid === 2 ||
        data[i].transactionstatusid === 3
      ) {
        let apiInsertWorkFlowData = {
          worfklow_item_create_ts: pageLoadingTimeStamp,
          workflow_item_reference_value: userData?.user_id,
          isactive: true,
          workflow_id: workflowData[0].workflow_id,
          workflow_step_id: workflowData[0].worflow_step_id,
          system_feature_operation_id: 1,
          assigned_by_user_id: userData?.user_id,
          assigned_to_user_id: userData?.user_id,
          completed_by_user_id: userData?.user_id,
          assigned_ts: pageLoadingTimeStamp,
          completed_ts: pageLoadingTimeStamp,
          updation_date: pageLoadingTimeStamp,
          creation_by: userData?.user_id,
          creation_date: pageLoadingTimeStamp,
          workflow_step_json_data: "DTR report has been generated",
          workflow_status: "completed",
          actual_aht_seconds: actualAHT_Seconds,
          worflow_step_sequence_number:
            workflowData[0].worflow_step_sequence_number,
          application_num: data[i].ih_number,
          client_id: fundHouse,
        };
        const res = await saveWorkflow(apiInsertWorkFlowData);
        if (res.responseData) {
          setDownloading(false);
        } else {
          setDownloading(false);
        }
      }
    }
  };

  const workbook2blob = (workbook) => {
    // Conversion of data to excel format
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    // Conversion of data to excel format and sorting of the data
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleDtrExport = () => {
    // Creating the DTR Report Excel Format
    const title = [
      {
        A: `Daily Transaction Report of  "${data[0].clientname}"  from ${moment(
          fromDate
        ).format("DD-MM-YYYY")}" to ${moment(toDate).format("DD-MM-YYYY")}" `,
      },
      {},
    ];

    let table = [
      {
        A: "Sno",
        B: "Client Name",
        C: "Scheme Name",
        D: "Investment Class",
        E: "Reporting Date",
        F: "Reporting Branch Name",
        G: "Application No",
        H: "Account No",
        I: "IH Number",
        J: "Transaction Type",
        K: "Investor Name",
        L: "Investor Category",
        M: "Moblie Number",
        N: "Email Id",
        O: "Pan Num",
        P: "Address 1",
        Q: "Address 2",
        R: "City",
        S: "Pincode",
        T: "State Name",
        U: "Country Name",
        V: "Address Nri 1",
        W: "Address Nri 2",
        X: "Zipcode For Nri",
        Y: "Country For Nri",
        Z: "State For Nri",
        AA: "City For Nri",
        AB: "Bank Name",
        AC: "Bank Account Number",
        AD: "Bank IFSC Code",
        AE: "Bank Account Type",
        AF: "Payment Bank Name",
        AG: "Payment Bank Account Number",
        AH: "Payment Bank IFSC Code",
        AI: "Payment Bank Account Type",
        AJ: "Commitment Amount",
        AK: "Transaction Amount",
        AL: "Setup Fee %",
        AM: "Cheque Number",
        AN: "Cheque Date",
        AO: "Cleared Date",
        AP: "Distributor Code",
        AQ: "Distributor Name",
        AR: "RM Code",
        AS: "RM Name",
        AT: "MOH",
        AU: "First Joint Holder Name",
        AV: "First Joint Holder PAN",
        AW: "First Joint Holder Relationship",
        AX: "Second Joint Holder Name",
        AY: "Second Joint Holder PAN",
        AZ: "Second Joint Holder Relationship",
        BA: "Alternate Email Id",
        BB: "First Nominee Name",
        BC: "Second Nominee Name",
        BD: "Third Nominee Name",
        BE: "Guardian Name",
        BF: "Guardian Pan",
        BG: "FATCA",
        BH: "UBO",
        BI: "POA Holder Name",
        BJ: "POA Holder Address",
        BK: "Application Status",
        BL: "QC Status",
        BM: "Image Status",
        BN: "Aadhaar Number",
        BO: "CKYC Number",
        BP: "Contribution Agreement Date",
        BQ: "MF Gain",
        BR: "Red MF Gain",
        BS: "TDS",
        BT: "Drawdown Number",
        BU: "Related Transaction IH Number",
        BV: "Leegality Document Id",
        BW: "Family Name",
        BX: "Family Folio No.",
      },
    ];

    let Total = [
      {
        A: "", // this will be for total and it will be empty
      },
    ];

    //Pushing data to tables
    for (let i = 0; i < data.length; i++) {
      table.push({
        A: data[i].sno,
        B: data[i].clientname,
        C: data[i].scheme_name,
        D: data[i].investment_classname,
        E: data[i].transaction_date,
        F: data[i].reporting_branch_name,
        G:
          data[i].application_number !== null || ""
            ? `${" " + data[i].application_number}`
            : "NA",
        H: `${" " + data[i].account_number}`,
        I: data[i].ih_number,
        J: data[i].transaction_type_name,
        K: data[i].investor_name,
        L: data[i].entity_status_name,
        M: data[i].mobile_num !== null || "" ? data[i].mobile_num : "NA",
        N: data[i].email_id !== null || "" ? data[i].email_id : "NA",
        O: data[i].pan_num !== null || "" ? data[i].pan_num : "NA",
        P: data[i].address_line1 !== null || "" ? data[i].address_line1 : "NA",
        Q: data[i].address_line2 !== null || "" ? data[i].address_line2 : "NA",
        R: data[i].city_name !== null || "" ? data[i].city_name : "NA",
        S: data[i].pincode !== null || "" ? data[i].pincode : "NA",
        T: data[i].state_name !== null || "" ? data[i].state_name : "NA",
        U: data[i].country_name !== null || "" ? data[i].country_name : "NA",
        V:
          data[i].addressline1_for_nri !== null || ""
            ? data[i].addressline1_for_nri
            : "NA",
        W:
          data[i].addressline2_for_nri !== null || ""
            ? data[i].addressline2_for_nri
            : "NA",
        X:
          data[i].zipcode_for_nri !== null || ""
            ? data[i].zipcode_for_nri
            : "NA",
        Y:
          data[i].country_for_nri !== null || ""
            ? data[i].country_for_nri
            : "NA",
        Z: data[i].state_for_nri !== null || "" ? data[i].state_for_nri : "NA",
        AA: data[i].city_for_nri !== null || "" ? data[i].city_for_nri : "NA",
        AB: data[i].bank_name !== null || "" ? data[i].bank_name : "NA",
        AC:
          data[i].bank_account_number !== null || ""
            ? `${" " + data[i].bank_account_number}`
            : "NA",
        AD:
          data[i].bank_ifsc_code !== null || "" ? data[i].bank_ifsc_code : "NA",
        AE:
          data[i].bank_account_type !== null || ""
            ? data[i].bank_account_type
            : "NA",
        AF: data[i].payment_bankname,
        AG: data[i].payment_bank_account_number,
        AH: data[i].payment_bank_ifsccode,
        AI: data[i].payment_bank_account_type,
        AJ: data[i].committment_amount,
        AK: data[i].transaction_amount,
        AL: data[i].setup_fee_percentage,
        AM: data[i].cheque_num,
        AN: data[i].cheque_date
          ? moment(data[i].cheque_date, "YYYY-MM-DDTHH:mm:ss").format(
              "DD/MM/YYYY"
            )
          : "Not Available",
        AO: data[i].cleared_date
          ? moment(data[i].cleared_date, "YYYY-MM-DDTHH:mm:ss").format(
              "DD/MM/YYYY"
            )
          : "Not Available",
        AP: data[i].distributor_arn_code,
        AQ: data[i].distributor_name,
        AR: data[i].rm_code !== null || "" ? data[i].rm_code : "NA",
        AS: data[i].rm_name !== null || "" ? data[i].rm_name : "NA",
        AT: data[i].moh,
        AU:
          data[i].first_holder_name !== null || ""
            ? data[i].first_holder_name
            : "NA",
        AV: data[i].first_holder_pan,
        AW: data[i].first_holder_relationship,
        AX: data[i].second_holder_name,
        AY: data[i].second_holder_pan,
        AZ: data[i].second_holder_relationship,
        BA: data[i].alternate_emailid,
        BB: data[i].first_nominee_name,
        BC: data[i].second_nominee_name,
        BD: data[i].third_nominee_name,
        BE: data[i].guardian_name,
        BF: data[i].guardian_pan,
        BG: data[i].fatca,
        BH: data[i].ubo,
        BI: data[i].poa_holder_name,
        BJ: data[i].poa_holder_address,
        BK: data[i].application_status,
        BL: data[i].qc_status,
        BM: data[i].image_status,
        BN: data[i].aadhar_number !== null || "" ? data[i].aadhar_number : "NA",
        BO: data[i].ckyc_number,
        BP: data[i].contribution_agreement_date,
        BQ: data[i].mf_gain,
        BR: data[i].red_mf_gain,
        BS: data[i].tds,
        BT: data[i].drawdown_number,
        BU: data[i].related_transaction_ih_number,
        BV: data[i].legality_document_id,
        BW: data[i].family_name,
        BX: data[i].family_account_no,
      });
    }

    Total.push({
      A: "Total Transaction",
      B: data.length,
    });

    table = [{ A: "" }]
      .concat([""])
      .concat([""])
      .concat(table)
      .concat([""])
      .concat([""])
      .concat(Total);

    const finalData = [...title, ...table];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "Daily Transaction Report");

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Sno" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A1",
      titleRange: "A1:D2",
      tbodyRange: `A2:CZ${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:BX${headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    // Styling the excel sheet
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        sheet.column("A").width(40);
        sheet.column("B").width(40);
        sheet.column("C").width(40);
        sheet.column("D").width(40);
        sheet.column("E").width(40);
        sheet.column("F").width(40);
        sheet.column("G").width(40);
        sheet.column("H").width(40);
        sheet.column("I").width(40);
        sheet.column("J").width(45);
        sheet.column("K").width(45);
        sheet.column("L").width(40);
        sheet.column("M").width(40);
        sheet.column("N").width(40);
        sheet.column("O").width(40);
        sheet.column("P").width(40);
        sheet.column("Q").width(40);
        sheet.column("R").width(40);
        sheet.column("S").width(40);
        sheet.column("T").width(40);
        sheet.column("U").width(40);
        sheet.column("V").width(40);
        sheet.column("W").width(40);
        sheet.column("X").width(40);
        sheet.column("Y").width(40);
        sheet.column("Z").width(40);
        sheet.column("AA").width(40);
        sheet.column("AB").width(40);
        sheet.column("AC").width(40);
        sheet.column("AD").width(40);
        sheet.column("AE").width(40);
        sheet.column("AF").width(40);
        sheet.column("AG").width(40);
        sheet.column("AH").width(40);
        sheet.column("AI").width(40);
        sheet.column("AJ").width(40);
        sheet.column("AK").width(40);
        sheet.column("AL").width(40);
        sheet.column("AM").width(40);
        sheet.column("AN").width(40);
        sheet.column("AO").width(40);
        sheet.column("AP").width(40);
        sheet.column("AQ").width(40);
        sheet.column("AR").width(40);
        sheet.column("AS").width(40);
        sheet.column("AT").width(40);
        sheet.column("AU").width(40);
        sheet.column("AV").width(40);
        sheet.column("AW").width(40);
        sheet.column("AX").width(40);
        sheet.column("AY").width(40);
        sheet.column("AZ").width(40);
        sheet.column("BA").width(40);
        sheet.column("BB").width(40);
        sheet.column("BC").width(40);
        sheet.column("BD").width(40);
        sheet.column("BE").width(40);
        sheet.column("BF").width(40);
        sheet.column("BG").width(40);
        sheet.column("BH").width(40);
        sheet.column("BI").width(40);
        sheet.column("BJ").width(40);
        sheet.column("BK").width(40);
        sheet.column("BL").width(40);
        sheet.column("BM").width(40);
        sheet.column("BN").width(40);
        sheet.column("BO").width(40);
        sheet.column("BP").width(40);
        sheet.column("BQ").width(40);
        sheet.column("BR").width(40);
        sheet.column("BS").width(40);
        sheet.column("BT").width(40);
        sheet.column("BU").width(40);
        sheet.column("BV").width(40);
        sheet.column("BW").width(40);
        sheet.column("BX").width(40);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
          border: true,
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "center",
          });
        }

        sheet.range(dataInfo.theadRange).style({
          bold: true,
          horizontalAlignment: "center",
          border: true,
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <>
      {downloading ? (
        <Spin size="medium" />
      ) : (
        <Button
          onClick={() => {
            createDownLoadDtrData();
          }}
          disabled={exportButton}
          loading={downloading}
          style={{
            width: "140px",
            height: "33px",
            backgroundColor: "#A3020C",
            color: "white",
            borderRadius: 5,
          }}
        >
          Export as Excel
        </Button>
      )}
    </>
  );
};

export default DtrExport;
