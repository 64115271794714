import { useSelector } from "react-redux";

const Classes = () => {
  const { classData } = useSelector((state) => state.clientData);
  const uniqueFees = classData?.fundfeelist.filter((item, index, array) => {
    const firstIndex = array.findIndex(
      (i) =>
        i.fee_calculation_type === item.fee_calculation_type &&
        i.fee_percentage === item.fee_percentage
    );

    return index === firstIndex;
  });
  return (
    <>
      <h1 className="text-center text-validus-red text-2xl ">
        {classData?.investment_class_description}
      </h1>
      <div
        className="flex flex-col bg-[#f5f5f5] rounded-md  p-6 mx-4 mb-6"
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col">
            <h2>Class Details</h2>
            <p>Class Name: {classData.investment_class_description}</p>
            <p>Class Code: {classData.investment_class_code}</p>
            <p>ISIN Number : {classData.isin}</p>
          </div>
          <div className="flex flex-col">
            <h2>Fund Details</h2>
            <p>Fund Name : {classData.fund_description}</p>
            <p>
              Setup Fee On Commitment :{" "}
              {classData.setfeeon_comt === "Y" ? "Yes" : "No"}
            </p>
          </div>
        </div>
        <h2>Fee Details</h2>
        <div className="grid grid-cols-3 w-full gap-4">
          {uniqueFees?.map((item) => {
            return (
              <div
                key={item.fund_fee_id}
                className="bg-white rounded-md px-4 py-2 shadow-lg"
              >
                <div>Fee Type : {item.fee_calculation_type}</div>
                <div className="mt-3">
                  Fee Percentage : {item.fee_percentage}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Classes;
