import axios from 'axios';

const baseConfig = {
    baseURL: `https://prodapi.myvalidus.com/Vapi`,
    timeout: 150000,
};

const apiInstance = axios.create({ ...baseConfig });
export { apiInstance as myApi };

export const httpPost = (url, payload) => {
    return apiInstance.post(url, payload);
};

export const httpGet = url => {
    return apiInstance.get(url);
};
