export const addIndianCommas = value => {
    if (value === undefined || value === null || value === '') return value;
    const cleanedText = value.toString().replace(/[^0-9]/g, '');
    return cleanedText.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
};

export const removeIndianCommas = value => {
    if (value === undefined || value === null || value === '') return value;
    if (typeof value === 'number') return value;
    return Number(value.replace(/[^0-9]/g, ''));
};

export const validateAccountNumber = (acc) => {
    const regex = /^\d{8,16}$/;
    return regex.test(acc);
};

export const dateManipulator = string => {
    string = string.slice(0, -9);
    let date = string.slice(-2);
    let year = string.substring(0, 4);
    let month = string.substring(5, 7);
    string = date + '/' + month + '/' + year;
    return string;
};