import { myApi } from "./apiInstance";
import endpoints from "./endpoints";

export const getMileStoneSummaryReportData = async (data) => {
    try {
        return await myApi
            .get(
                `${endpoints.GET_MILESTONE_SUMMARY_REPORT_DATA}?from_date=${data.from_date}&clientid=${data.clientid}&transactiontype=${data.transactiontype}`
            )
            .then((res) => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};

export const getMileStoneReportCountData = async (data) => {
    try {
        return await myApi
            .get(
                `${endpoints.GET_MILESTONE_REPORT_COUNT_DATA}?from_date=${data.from_date}&client_id=${data.clientid}&milestonecategory=${data.milestonecategory}&milestonestatus=${data.milestonestatus}&transactiontype=${data.transactiontype}`
            )
            .then((res) => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};

export const getTransactionsAPI = async (data) => {
    try {
        return await myApi
            .get(
                `${endpoints.GET_DTR_TRANSACTIONS}/${data.clientId}?branchcode=${data.branchcode}`
            )
            .then(res => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};