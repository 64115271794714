import { Avatar, Divider } from "antd";
import moment from "moment";
import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const UserProfile = () => {
  const { userData } = useSelector((state) => state.userAuth);
  return (
    <>
      <div className="flex flex-row items-center gap-x-4">
        <Avatar size="large" icon={<UserOutlined />} />
        <div className="text-lg font-semibold">
          {userData?.user_first_name} {userData?.user_last_name}
        </div>
      </div>
      <div className="mt-3">First Name : {userData?.user_first_name}</div>
      <Divider />
      <div>Last Name : {userData?.user_last_name}</div>
      <Divider />
      <div>
        Last Login :{" "}
        {moment(userData?.last_login_date).format("DD-MM-YYYY HH:mm:SS A")}
      </div>
      <Divider />
      <div>Email : {userData?.user_name}</div>
      <Divider />
      <div>Mobile : {userData?.primary_mobile_number}</div>
    </>
  );
};

export default UserProfile;
