import React, { useState, useEffect } from "react";
import { Button } from "antd";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import moment from "moment";
import { useSelector } from "react-redux";
import { getWorkflow, saveWorkflow } from "services/workflowServices";

const InvictaUcrReport = ({ data, fundHouse, accountNumber }) => {
  const { userData } = useSelector((state) => state.userAuth);
  const [exportButton, setExportButton] = useState(true);
  const [workflowData, setWorkflowData] = useState([]);

  var workflowdate = moment().utcOffset("+05:30").format();
  const [pageLoadingTimeStamp, setPageLoadingTimeStamp] =
    useState(workflowdate);

  useEffect(() => {
    // set the export button to false, if data is there it will change to true or else it will be false
    setExportButton(false);
    if (data?.length === 0) {
      setExportButton(true);
    } else {
      setExportButton(false);
    }
  }, [data]);

  useEffect(() => {
    getWorkflowAPI();
  }, []);

  const getWorkflowAPI = async () => {
    try {
      const res = await getWorkflow("URDTR");
      if (res.responseData) {
        setWorkflowData(res.responseData);
      } else {
        return console.log("Error in fetching workflow data");
      }
    } catch (err) {
      return console.log(err);
    }
  };

  const createDownloadFirstUcrData = () => {
    // Downloading the First UC Report
    handleFirstUcrExport().then((url) => {
      // Calling the handleFirstUcrExport function and converting the data to excel for downloading
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        `UnitCapitalReport_${accountNumber}.xls`
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const insertWorkflowAPI = async () => {
    let submitTimeStamp = moment().utcOffset("+05:30").format();
    let pageLoadingTimeStampInSeconds = moment(pageLoadingTimeStamp).seconds();
    let submitTimeStampInSeconds = moment(submitTimeStamp).seconds();
    let actualAHT_Seconds = Math.abs(
      submitTimeStampInSeconds - pageLoadingTimeStampInSeconds
    );

    let apiInsertWorkFlowData = {
      worfklow_item_create_ts: pageLoadingTimeStamp,
      workflow_item_reference_value: userData?.user_id,
      isactive: true,
      workflow_id: workflowData[0].workflow_id,
      workflow_step_id: workflowData[0].worflow_step_id,
      system_feature_operation_id: 1,
      assigned_by_user_id: userData?.user_id,
      assigned_to_user_id: userData?.user_id,
      completed_by_user_id: userData?.user_id,
      assigned_ts: pageLoadingTimeStamp,
      completed_ts: pageLoadingTimeStamp,
      updation_date: pageLoadingTimeStamp,
      creation_by: userData?.user_id,
      creation_date: pageLoadingTimeStamp,
      workflow_step_json_data: "UCR report has been generated",
      workflow_status: "completed",
      actual_aht_seconds: actualAHT_Seconds,
      worflow_step_sequence_number:
        workflowData[0].worflow_step_sequence_number,
      client_id: fundHouse,
    };

    await saveWorkflow(apiInsertWorkFlowData);
  };

  const workbook2blob = (workbook) => {
    // Converting the data to excel
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    // Conversion of data to excel format and sorting of the data

    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleFirstUcrExport = () => {
    // Creating the first UC Report Excel Format
    const title = [
      {
        A: `Unit Captial Report for ${accountNumber}`,
      },
      {},
    ];

    let firstTable = [
      {
        A: "Sno",
        B: "Class Name",
        C: "Transaction Date",
        D: "Transaction Type",
        E: "Units",
        F: "NAV Date",
        G: "Remarks",
      },
    ];

    let firstTotal = [
      {
        A: "", // this will be for total and it will be empty
      },
    ];

    for (let i = 0; i < data.length; i++) {
      firstTable.push({
        A: data[i].sno,
        B: data[i].class_name,
        C: data[i].transaction_date,
        D: data[i].transaction_type_desc,
        E: data[i].units,
        F: data[i].navdate,
        G: data[i].remarks,
      });
    }

    firstTotal.push({
      A: "Total Transactions",
      B: data.length,
    });

    firstTable = [{ A: "" }]
      .concat([""])
      .concat([""])
      .concat(firstTable)
      .concat([""])
      .concat([""])
      .concat(firstTotal);

    const finalData = [...title, ...firstTable];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "Unit Capital Report");

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Sno" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A1",
      titleRange: "A1:C2",
      tbodyRange: `A2:CZ${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:O${headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    // Adding style to the excel sheet
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        sheet.column("A").width(20);
        sheet.column("B").width(30);
        sheet.column("C").width(25);
        sheet.column("D").width(25);
        sheet.column("E").width(25);
        sheet.column("F").width(25);
        sheet.column("G").width(25);

        sheet.range(dataInfo.titleRange).merged(true).style({
          // fill: "#FFFFFF",
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
          border: true,
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "center",
          });
        }

        sheet.range(dataInfo.theadRange).style({
          bold: true,
          horizontalAlignment: "center",
          border: true,
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <Button
      onClick={() => {
        createDownloadFirstUcrData();
        insertWorkflowAPI();
      }}
      disabled={exportButton}
      style={{
        width: "140px",
        height: "33px",
        backgroundColor: "#A3020C",
        color: "white",
        borderRadius: 5,
      }}
    >
      Export as Excel
    </Button>
  );
};

export default InvictaUcrReport;
