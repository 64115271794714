import React, { useState, useEffect } from "react";
import {
  getMileStoneReportCountData,
  getMileStoneSummaryReportData,
} from "services/DashboardServices";
import OUTWARDICON from "../../assets/outbox.svg";
import INWARDICON from "../../assets/inbox.svg";
import TATICON from "../../assets/stopwatch.svg";
import PROCESSINGICON from "../../assets/folder.svg";
import moment from "moment";
import { useStateContext } from "contexts/ContextProvider";
import { DatePicker, Table, Button, Spin } from "antd";
import CountUp from "react-countup";
import { formatNumberWithIndianCommas } from "components/utils/helperFunctions";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { clientData } = useSelector((state) => state.clientData);
  const [mileStoneCountData, setMileStoneCountData] = useState([]);
  const [mileStoneCategoryType, setMileStoneCategoryType] = useState("");
  const [tableData, setTableData] = useState([]);
  const { client_id } = useStateContext();
  const [loader, setLoader] = useState(false);
  const [countLoader, setCountLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const fetchMileStoneCountData = async () => {
    setCountLoader(true);
    try {
      const res = await getMileStoneSummaryReportData({
        clientid: client_id,
        transactiontype: "NewPurchase",
        from_date: moment(new Date()).format("YYYY-MM-DD"),
      });
      if (res.responseData) {
        let amcData = res.responseData.filter(
          (item) => item.milestone === "Front Office"
        );
        setMileStoneCountData(amcData[0]);
        setCountLoader(false);
      } else {
        setMileStoneCountData([]);
        setCountLoader(false);
      }
    } catch (error) {
      setCountLoader(false);
      console.log(error);
    } finally {
      setCountLoader(false);
    }
  };

  useEffect(() => {
    fetchMileStoneCountData();
  }, []);

  useEffect(() => {
    if (!mileStoneCategoryType) return;
    getMileStoneTransactions(selectedDate);
  }, [mileStoneCategoryType]);

  const getMileStoneTransactions = async (date) => {
    if (
      mileStoneCategoryType === "Work in Progress" &&
      mileStoneCountData?.wip === 0
    ) {
      setTableData([]);
      setLoader(false);
      return;
    }

    let apiData = {
      from_date:
        mileStoneCategoryType === "TAT"
          ? moment(new Date()).format("YYYY-MM-DD")
          : moment(date).format("YYYY-MM-DD"),
      clientid: client_id,
      milestonecategory: "Front Office",
      milestonestatus: mileStoneCategoryType,
      transactiontype: "NewPurchase",
    };

    setLoader(true);
    try {
      const res = await getMileStoneReportCountData(apiData);
      if (res.message === "Success" && res.responseData) {
        setTableData(res.responseData);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error(error);
      setTableData([]); // Ensure table clears on error
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: "Fund",
      dataIndex: "fund_name",
      render: (text) => (text === "" ? "NA" : text ?? "NA"),
    },
    {
      title: "Investor Name",
      dataIndex: "investor_name",
      render: (text) => (text === "" ? "NA" : text ?? "NA"),
    },
    {
      title: "Application Number",
      dataIndex: "applicationnumber",
      render: (text) => (text === "" ? "NA" : text ?? "NA"),
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      render: (text) => (text === "" ? "NA" : text ?? "NA"),
    },
    {
      title: "Amount",
      dataIndex: "committment_amount",
      render: (text) =>
        text === "" ? "NA" : formatNumberWithIndianCommas(text) ?? "NA",
    },
    {
      title: "Staging",
      dataIndex: "staging",
      render: (text) => (text === "" ? "NA" : text ?? "NA"),
    },
    {
      title: "Creation date",
      dataIndex: "creationdate",
      render: (text) =>
        text === "" ? "NA" : text ? moment(text).format("DD/MM/YYYY") : "NA",
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  return (
    <div className="mx-10 mt-3">
      <p className="text-left text-xl font-semibold text-validus-red">
        {clientData?.client_name}
      </p>

      <div className="w-full flex flex-row gap-x-4">
        <div
          className="bg-gray-300 w-1/2 flex flex-row rounded-md shadow-lg cursor-pointer"
          onClick={() => {
            setMileStoneCategoryType("Received");
          }}
        >
          <div className="w-2/5 flex items-center justify-center bg-white rounded-l-md">
            <img src={INWARDICON} alt="Inward" className="w-1/2 h-1/2" />
          </div>
          <div className="w-3/5 p-4">
            <div className="text-md font-semibold">Inward</div>
            <div className="text-2xl font-bold">
              {countLoader ? (
                <Spin
                  spinning={countLoader}
                  size="medium"
                  indicator={antIcon}
                />
              ) : (
                <CountUp
                  start={0}
                  end={mileStoneCountData?.received}
                  duration={2}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className="bg-gray-300 w-1/2 flex flex-row rounded-md shadow-lg cursor-pointer"
          onClick={() => {
            setMileStoneCategoryType("Completed");
          }}
        >
          <div className="w-2/5 flex items-center justify-center bg-white rounded-l-md">
            <img src={OUTWARDICON} alt="Outward" className="w-1/2 h-1/2" />
          </div>
          <div className="w-3/5 p-4">
            <div className="text-md font-semibold">Outward</div>
            <div className="text-2xl font-bold">
              {countLoader ? (
                <Spin
                  spinning={countLoader}
                  size="medium"
                  indicator={antIcon}
                />
              ) : (
                <CountUp
                  start={0}
                  end={mileStoneCountData?.completed}
                  duration={2}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className="bg-gray-300 w-1/2 flex flex-row rounded-md shadow-lg cursor-pointer"
          onClick={() => {
            setMileStoneCategoryType("Work in Progress");
            setTableData([]); // Clear the table immediately
            if (mileStoneCountData?.wip > 0) {
              setLoader(true); // Show loader if data fetching is triggered
              getMileStoneTransactions(selectedDate);
            }
          }}
        >
          <div className="w-2/5 flex items-center justify-center bg-white rounded-l-md">
            <img
              src={PROCESSINGICON}
              alt="Processing"
              className="w-1/2 h-1/2"
            />
          </div>
          <div className="w-3/5 p-4">
            <div className="text-md font-semibold">Processing</div>
            <div className="text-2xl font-bold">
              {countLoader ? (
                <Spin
                  spinning={countLoader}
                  size="medium"
                  indicator={antIcon}
                />
              ) : (
                <CountUp start={0} end={mileStoneCountData?.wip} duration={2} />
              )}
            </div>
          </div>
        </div>

        <div
          className="bg-gray-300 w-1/2 flex flex-row rounded-md shadow-lg cursor-pointer"
          onClick={() => {
            setMileStoneCategoryType("TAT");
          }}
        >
          <div className="w-2/5 flex items-center justify-center bg-white rounded-l-md">
            <img src={TATICON} alt="TAT" className="w-1/2 h-1/2" />
          </div>
          <div className="w-3/5 p-4">
            <div className="text-md font-semibold">TAT</div>
            <div className="text-2xl font-bold">
              {countLoader ? (
                <Spin
                  spinning={countLoader}
                  size="medium"
                  indicator={antIcon}
                />
              ) : (
                <CountUp
                  start={0}
                  end={mileStoneCountData?.tat_breach}
                  duration={2}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        {mileStoneCategoryType ? (
          <>
            <div className="flex flex-row justify-between mb-4">
              <div className="text-center text-xl font-semibold text-validus-red">
                {mileStoneCategoryType} Transactions
              </div>
              <div className="flex justify-end gap-x-6">
                <DatePicker
                  placeholder="Select Date"
                  format={"DD/MM/YYYY"}
                  onChange={(date, dateString) => {
                    mileStoneCategoryType === "TAT"
                      ? setSelectedDate(new Date())
                      : setSelectedDate(date);
                  }}
                  disabled={mileStoneCategoryType === "TAT"}
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                  value={
                    mileStoneCategoryType === "TAT"
                      ? moment(new Date())
                      : selectedDate
                  }
                />
                <Button
                  style={{
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                    border: "none",
                  }}
                  onClick={() => {
                    if (
                      mileStoneCategoryType === "Work in Progress" &&
                      mileStoneCountData?.wip === 0
                    ) {
                      setTableData([]);
                      return;
                    }
                    getMileStoneTransactions(selectedDate);
                  }}
                >
                  Show Transactions
                </Button>
              </div>
            </div>

            <Table
              dataSource={tableData}
              columns={columns}
              bordered
              loading={loader}
            />
          </>
        ) : (
          <div
            className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
            role="alert"
          >
            Please Select Category
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
