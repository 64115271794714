import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    clientData: {},
    fundData: {},
    classData: {}
}

const clientDataSlice = createSlice({
    name: 'clientData',
    initialState,
    reducers: {
        setClientData(state, action) {
            state.clientData = action.payload;
        },
        setFundData(state, action) {
            state.fundData = action.payload;
        },
        setClassData(state, action) {
            state.classData = action.payload;
        }
    },
});

export const {
    setClientData,
    setFundData,
    setClassData
} = clientDataSlice.actions;

export default clientDataSlice.reducer;