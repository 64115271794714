import { myApi } from "./apiInstance";
import endpoints from "./endpoints";

export const getAddressData = async (data) => {
    try {
        return await myApi
            .get(
                `${endpoints.GET_ADDRESS_DATA}?address_id=${data}`)
            .then((res) => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};

export const getFunds = async (data) => {
    try {
        return await myApi
            .get(
                `${endpoints.GET_FUNDS}/${data}`)
            .then((res) => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};

export const getClasses = async (data) => {
    try {
        return await myApi
            .get(
                `${endpoints.GET_CLASS_DATA}?fundid=${data}`)
            .then((res) => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};
