import React, { useState, useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { Table, Button, Modal } from "antd";
import moment from "moment";
import {
  getVaultDetails,
  saveVaultDetails,
  uploadDocstoGDrive,
} from "../services/ApiServices";

const DocumentUpload = () => {
  const { client_code, user_id, setActiveMenu } = useStateContext();
  const [toggleState, setToggleState] = useState(1);
  const [dematdocumentsTableData, setDematDocumentsTableData] = useState([]);
  const [corporateDocumentsTableData, setCorporateDocumentsTableData] =
    useState([]);
  const [otherDocumentsTableData, setOtherDocumentsTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [docType, setDocType] = useState("");

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    getVaults();
  };

  const getVaults = async () => {
    try {
      const res = await getVaultDetails(client_code);
      if (res.responseData) {
        setDematDocumentsTableData(
          res.responseData.filter((item) => item.document_type === "Demat")
        );
        setCorporateDocumentsTableData(
          res.responseData.filter((ele) => ele.document_type === "Corporate")
        );
        setOtherDocumentsTableData(
          res.responseData.filter((ele) => ele.document_type === "Others")
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const allColumns = [
    {
      title: "Document Type",
      dataIndex: "document_type",
    },
    {
      title: "Document Name",
      dataIndex: "document_name",
    },
    {
      title: "Document Date",
      dataIndex: "creation_date",
      render: (_, record) => {
        let date = moment(record.creation_date).format("DD/MM/YYYY");
        return <div>{date}</div>;
      },
    },
    {
      title: "Document Status",
      dataIndex: "storage_category",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => {
        return (
          <div className="flex justify-center items-center">
            <div
              className="flex bg-validus-red text-white px-4 py-1 rounded-md cursor-pointer"
              onClick={
                !record.s3_path
                  ? () =>
                      window.open(
                        record.s3_path.replace(
                          "&export=download",
                          "&export=view"
                        )
                      )
                  : () => {
                      setIsModalOpen(true);
                      setActiveMenu(false);
                      toggleState === 2 && setDocType("Corporate");
                      toggleState === 3 && setDocType("Others");
                      toggleState === 1 && setDocType("Demat");
                    }
              }
            >
              {record.s3_path ? "View" : "Upload"}
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getVaults();
  }, [client_code]);

  const handleFileInput = (e) => {
    const files = Array.from(e.target.files);
    handleUploadAndSaveDocuments(files);
  };

  const handleUploadAndSaveDocuments = async (files) => {
    try {
      const fileUrl = "client-bucket_" + client_code;

      const documentType = {
        name: "Client Document",
      };
      const res = await uploadDocstoGDrive(files, documentType, fileUrl);
      if (res) {
        uploadToVaultDetails(res, files);
      } else {
        Modal.error({
          title: "Error",
          content: "Something went wrong. Please try again",
        });
      }
    } catch (err) {
      Modal.error({
        title: "Error",
        content: "Something went wrong. Please try again",
      });
    }
  };

  const uploadToVaultDetails = async (webUrls, files) => {
    try {
      let payloadArray = webUrls.WebContentLink.map((url, i) => {
        const file = files[i]; // Assuming files is an array and matches the URL count
        const metaData = {
          file_original_name: file.name,
          docType: docType,
          uploadedBy: user_id,
          uploadedOn: new Date(),
          modified: false,
          fileSize: file.size,
          module: "Inward",
        };

        // Construct the payload for each file and corresponding URL
        return {
          vault_id: 0,
          entity_id: 1,
          document_type: docType,
          entity_reference_number: client_code,
          storage_category: "Inward",
          s3_path: url,
          created_by: user_id,
          creation_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          isactive: true,
          updated_by: user_id,
          updation_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          document_name: file.name,
          metadata: JSON.stringify(metaData),
          transaction_reference_number: client_code,
          document_type_id: 120,
          file_type: file.type,
        };
      });

      const resp = await saveVaultDetails(payloadArray);
      if (resp.responseData) {
        setDocType("");
        setIsModalOpen(false);
      } else {
        Modal.error({
          title: "Error",
          content: "Something went wrong. Please try again",
        });
      }
    } catch (err) {
      Modal.error({
        title: "Error",
        content: "Something went wrong. Please try again",
      });
    }
  };

  return (
    <>
      <h1 className="text-center text-validus-red text-2xl font-semibold">
        Client Documents
      </h1>

      <div className="flex flex-col position-relative bg-[#f1f1f1] border border-gray-400 w-[99%] mx-auto mb-4 h-[70%]">
        <div className="flex">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            Demat Documents
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            Corporate Documents
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(3)}
          >
            Other Documents
          </button>
        </div>
        <div className="content-tabs">
          <div
            className={
              toggleState === 1
                ? "content  active-content"
                : toggleState === 2
                ? "content  active-content"
                : toggleState === 3
                ? "content  active-content"
                : "content"
            }
          >
            <Table
              columns={allColumns}
              dataSource={
                toggleState === 1
                  ? dematdocumentsTableData
                  : toggleState === 2
                  ? corporateDocumentsTableData
                  : otherDocumentsTableData
              }
              bordered
              pagination={{
                position: ["bottomRight"],
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "40",
                  "50",
                  "60",
                  "70",
                  "80",
                  "90",
                  "100",
                ],
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        title="Document Upload"
        open={isModalOpen}
        onOk={handleClose}
        onCancel={handleClose}
        footer={[
          <Button key="submit" type="primary" onClick={handleClose}>
            Submit
          </Button>,
        ]}
        width={900}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <input
            type="file"
            style={{ width: "200px", height: "35px" }}
            onChange={(e) => {
              handleFileInput(e);
            }}
            multiple={false}
          />
          <p>View</p>
        </div>
      </Modal>
    </>
  );
};

export default DocumentUpload;
