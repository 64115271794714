import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { Table, Button, Select, DatePicker, Input, Space } from "antd";
import moment from "moment";
import DtrReports from "./DailyTransactionReports";
import UcrReports from "./UnitCapitalReports";
import UhrReports from "./UnitHoldingReports";
import { useStateContext } from "../../contexts/ContextProvider";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import TransactionRegisterReports from "./TransactionRegisterReports";
import CreditMarkingReports from "./CreditMarkingReports";
import NavReports from "./NavReports";
import NctReports from "./NctReports";
import { myApi } from "services/apiInstance";
import { dateManipulator } from "utils/helperFunctions";
import {
  columnsUhr,
  columnsUcr,
  columnsDtr,
  columnsNAVReport,
  columnsCreditMarking,
  columnsTransactionRegister,
  columnsDDR,
  columnsInvictaUcr,
} from "./reportTypesColumns";
import DrawdownReport from "./DrawdownReport";
import { getFunds } from "services/profileServices";
import InvictaUcrReport from "./InvictaUnitCapitalReport";
import { useSelector } from "react-redux";

const selectionReportData = [
  {
    id: 1,
    title: "Daily Transaction Report",
  },
  {
    id: 2,
    title: "Unit Capital Report",
  },
  {
    id: 3,
    title: "Unit Holding Report",
  },
  {
    id: 4,
    title: "Transaction Register Report",
  },
  {
    id: 5,
    title: "Service Request Report",
  },
  {
    id: 6,
    title: "Credit Marking Report",
  },
  {
    id: 7,
    title: "NAV Report",
  },
  {
    id: 8,
    title: "Drawdown Report",
  },
];
const ReportGeneration = () => {
  const { apiBaseUrl } = useStateContext();
  const { userData } = useSelector((state) => state.userAuth);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [schemeData, setSchemeData] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState("");
  const [disableDate, setDisableDate] = useState(true);
  const [disableGetButton, setDisableGetButton] = useState(true);
  const [transactionData, setTransactionData] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [dtrTableData, setDtrTableData] = useState([]);
  const [ucrTableData, setUcrTableData] = useState([]);
  const [uhrTableData, setUhrTableData] = useState([]);
  const [nctTableData, setNctTableData] = useState([]);
  const [transactionRegisterData, setTransactionRegisterData] = useState([]);
  const [creditMarkingData, setCreditMarkingData] = useState([]);
  const [navReportData, setNavReportData] = useState([]);
  const [drawdownReportData, setDrawdownReportData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");

  const [reportType, setReportType] = useState("");
  const [showSelection, setShowSelection] = useState(false);

  useEffect(() => {
    try {
      transactionData.forEach((ele, index) => {
        ele.sno = index + 1;
        ele.creation_date
          ? (ele.creation_date = dateManipulator(ele.creation_date))
          : (ele.creation_date = "No Date Present");
        ele.fund_nav_date
          ? (ele.fund_nav_date = dateManipulator(ele.fund_nav_date))
          : (ele.fund_nav_date = "No Date Present");
        ele.nav_date && ele.nav_date !== "NA"
          ? (ele.nav_date = moment(ele.nav_date).format("DD/MM/YYYY"))
          : (ele.nav_date = "No Date Present");
        ele.navdate && ele.navdate !== "NA"
          ? (ele.navdate = moment(ele.navdate).format("DD/MM/YYYY"))
          : (ele.navdate = "No Date Present");
        ele.transaction_date
          ? (ele.transaction_date = dateManipulator(ele.transaction_date))
          : (ele.transaction_date = "No Date Present");
        ele.paymentdate
          ? (ele.paymentdate = dateManipulator(ele.paymentdate))
          : (ele.paymentdate = "No Date Present");
        ele.endoreseddate
          ? (ele.endoreseddate = dateManipulator(ele.endoreseddate))
          : (ele.endoreseddate = "No Date Present");
        ele.chequedate
          ? (ele.chequedate = dateManipulator(ele.chequedate))
          : (ele.chequedate = "No Date Present");
        ele.processed_date
          ? (ele.processed_date = dateManipulator(ele.processed_date))
          : (ele.processed_date = "No Date Present");
        ele.primary_holder_dob
          ? (ele.primary_holder_dob = moment(
              ele.primary_holder_dob,
              "DD/MM/YYYY hh:mm:ss a"
            )
              .format("DD/MM/YYYY")
              .toString())
          : (ele.primary_holder_dob = "No Date Present");
        ele.endorsement_flag =
          ele.endorsement_flag === "Y"
            ? "Success"
            : ele.endorsement_flag === "P"
            ? "Pending"
            : "Null";
        ele.remark = ele.remark ? ele.remark : "No Remarks Present";
        ele.status =
          ele.status === "Open"
            ? "Pending"
            : ele.status === "Closed"
            ? "Success"
            : ele.status === "Resolved"
            ? "Rejected"
            : "Null";
        ele.institution_incorporation_date =
          ele.institution_incorporation_date !== "NA"
            ? moment(ele.institution_incorporation_date).format("DD/MM/YYYY")
            : "NA";
      });
      setDtrTableData(transactionData);
      setUcrTableData(transactionData);
      setUhrTableData(transactionData);
      setNctTableData(transactionData);
      setTransactionRegisterData(transactionData);
      setCreditMarkingData(transactionData);
      setNavReportData(transactionData);
      setDrawdownReportData(transactionData);
    } catch (err) {
      alert("There was an Error Loading The Data");
      setTransactionData([]);
      setShowTable(false);
      setLoading(false);
    }
  }, [transactionData]);

  const fetchFunds = async () => {
    try {
      const res = await getFunds(userData?.client_id);
      if (res.responseData) {
        setSchemeData(res.responseData);
      } else {
        setSchemeData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchFunds();
  }, []);

  const handleSelectReportType = (e) => {
    setShowSelection(true);
    setReportType(e);
    setShowTable(false);
  };

  const fetchData = (url) => {
    setLoading(true);
    setShowTable(true);
    myApi
      .get(url)
      .then((res) => {
        setTransactionData(res.data.responseData);
        setLoading(false);
      })
      .catch((error) => alert(`Something went wrong`));
  };

  const fetchDtrData = () => {
    const url = `${apiBaseUrl}/VReports/GetDailyReport2/${selectedFromDate}/${selectedToDate}/${userData?.client_id}/${selectedScheme}`;
    fetchData(url);
  };

  const fetchUcrData = () => {
    let url;
    if (userData?.client_id === 248) {
      url = `${apiBaseUrl}/VReports/GetInvictaUcr/${userData?.client_id}/${accountNumber}`;
    } else {
      url = `${apiBaseUrl}/VReports/GetUcr_data/${userData?.client_id}/${selectedScheme}/${selectedToDate}`;
    }
    fetchData(url);
  };

  const fetchUhrData = () => {
    const url = `${apiBaseUrl}/VReports/GetUhr/${userData?.client_id}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchNctData = () => {
    const url = `${apiBaseUrl}/VReports/GetNctReport/${userData?.client_id}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchTransactionRegisterData = () => {
    const url = `${apiBaseUrl}/VReports/GetTransactionRegisterReport/${userData?.client_id}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchCreditMarkingData = () => {
    const url = `${apiBaseUrl}/VReports/GetCreditmarkingReport/${userData?.client_id}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchNAVReportData = () => {
    const url = `${apiBaseUrl}/VReports/GetNavReport/${userData?.client_id}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchDDRData = () => {
    const url = `${apiBaseUrl}/VReports/GetDrawdownReport?clientid=${userData?.client_id}&fundid=${selectedScheme}&todate=${selectedToDate}`;
    fetchData(url);
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search Here`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              setSearchText("");
              setSearchedColumn("");
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90 }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <span className="ant-table-filter-icon-container">
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      </span>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const filterData = (nctTableData) =>
    nctTableData.map((item) => ({
      key: item,
      value: item,
      text: item,
    }));

  // Nct column data
  const columnsNct = [
    {
      title: "Service Request No.",
      dataIndex: "erp_reference_ticket_num",
      responsive: ["md"],
      ...getColumnSearchProps("erp_reference_ticket_num"),
    },
    {
      title: "Request Registration Date",
      dataIndex: "transaction_date",
      responsive: ["md"],
      defaultSortOrder: "descend",
      sorter: (a, b) => a.transaction_date - b.transaction_date,
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      responsive: ["md"],
      ...getColumnSearchProps("account_number"),
    },
    {
      title: "Investor Name",
      dataIndex: "investor_name",
      responsive: ["md"],
      ...getColumnSearchProps("investor_name"),
    },
    {
      title: "Service Request Category",
      dataIndex: "category_name",
      responsive: ["md"],
      filters: filterData(
        nctTableData
          .map((item) => item.category_name)

          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) => record.category_name.indexOf(value) === 0,
    },
    {
      title: "Service Request",
      dataIndex: "subject_name",
      responsive: ["md"],
      ...getColumnSearchProps("subject_name"),
    },
    {
      title: "Source Type",
      dataIndex: "source_type_name",
      responsive: ["md"],
      filters: filterData(
        nctTableData
          .map((item) => item.source_type_name)

          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) => record.source_type_name.indexOf(value) === 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["md"],
      filters: filterData(
        nctTableData
          .map((item) => item.status)

          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
  ];

  const handleChange = (_, filter, sorter) => {
    const { order, field } = sorter;
    setSortedInfo({ columnKey: field, order });
  };

  return (
    <>
      <h1 className="text-center text-2xl text-validus-red font-semibold">
        Report Generation
      </h1>
      <div className="px-4 w-full">
        <Select
          className="min-w-[300px]"
          placeholder="Report Types"
          onChange={(e) => handleSelectReportType(e)}
        >
          {selectionReportData.map((ele, index) => (
            <Select.Option key={index} value={ele.id}>
              {ele.title}
            </Select.Option>
          ))}
        </Select>
      </div>
      {showSelection ? (
        <>
          <div className="flex flex-row justify-between gap-x-4 px-4 mt-4">
            {userData?.client_id === 248 ? (
              <Input
                className="min-w-[300px] max-w-[300px]"
                value={accountNumber}
                onChange={(e) => {
                  setAccountNumber(e.target.value);
                  if (e.target.value?.length >= 11) {
                    setDisableGetButton(false);
                  }
                }}
                placeholder="Enter Account Number"
              />
            ) : (
              <>
                <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={(e) => {
                    setSelectedScheme(e);
                    setDisableDate(false);
                  }}
                  className="min-w-[300px]"
                >
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                {reportType === 1 ? (
                  <>
                    <DatePicker
                      disabled={disableDate}
                      className="w-small"
                      placeholder="Select From Date"
                      format="DD/MM/YYYY"
                      selected={selectedFromDate}
                      disabledDate={(current) => {
                        return current >= moment().endOf("day");
                      }}
                      onChange={(date) => {
                        setSelectedFromDate(moment(date).format("YYYY-MM-DD"));
                        setDisableGetButton(false);
                      }}
                    />

                    <DatePicker
                      disabled={disableDate}
                      className="w-small"
                      placeholder="Select To Date"
                      format="DD/MM/YYYY"
                      selected={selectedToDate}
                      disabledDate={(current) => {
                        return current >= moment().endOf("day");
                      }}
                      onChange={(date) => {
                        setSelectedToDate(moment(date).format("YYYY-MM-DD"));
                        setDisableGetButton(false);
                      }}
                    />
                  </>
                ) : (
                  <DatePicker
                    disabled={disableDate}
                    className="w-small"
                    placeholder="Select Date"
                    format="DD/MM/YYYY"
                    selected={selectedToDate}
                    disabledDate={(current) => {
                      return current >= moment().endOf("day");
                    }}
                    onChange={(date) => {
                      setSelectedToDate(moment(date).format("YYYY-MM-DD"));
                      setDisableGetButton(false);
                    }}
                  />
                )}
              </>
            )}

            <Button
              disabled={disableGetButton}
              style={{
                display: "flex",
                justifyContent: "center",

                backgroundColor: "#A3020C",
                color: "white",
                borderRadius: 5,
              }}
              onClick={() =>
                reportType === 1
                  ? fetchDtrData()
                  : reportType === 2
                  ? fetchUcrData()
                  : reportType === 3
                  ? fetchUhrData()
                  : reportType === 4
                  ? fetchTransactionRegisterData()
                  : reportType === 5
                  ? fetchNctData()
                  : reportType === 6
                  ? fetchCreditMarkingData()
                  : reportType === 7
                  ? fetchNAVReportData()
                  : reportType === 8
                  ? fetchDDRData()
                  : null
              }
            >
              Get
            </Button>

            <Button
              style={{
                display: "flex",
                justifyContent: "center",

                backgroundColor: "#A3020C",
                color: "white",
                borderRadius: 5,
              }}
              onClick={() => window.location.reload(false)}
            >
              Reset
            </Button>
          </div>

          {showTable ? (
            <div className="mt-4 px-4">
              <Table
                columns={
                  reportType === 1
                    ? columnsDtr
                    : reportType === 2
                    ? userData?.client_id !== 248
                      ? columnsUcr
                      : columnsInvictaUcr
                    : reportType === 3
                    ? columnsUhr
                    : reportType === 4
                    ? columnsTransactionRegister
                    : reportType === 5
                    ? columnsNct
                    : reportType === 6
                    ? columnsCreditMarking
                    : reportType === 7
                    ? columnsNAVReport
                    : reportType === 8
                    ? columnsDDR
                    : null
                }
                dataSource={
                  reportType === 1
                    ? dtrTableData
                    : reportType === 2
                    ? ucrTableData
                    : reportType === 3
                    ? uhrTableData
                    : reportType === 4
                    ? transactionRegisterData
                    : reportType === 5
                    ? nctTableData
                    : reportType === 6
                    ? creditMarkingData
                    : reportType === 7
                    ? navReportData
                    : reportType === 8
                    ? drawdownReportData
                    : null
                }
                bordered
                loading={loading}
                onChange={handleChange}
                rowKey={(record) => record.id}
              />
              {reportType === 1 ? (
                <div className="flex justify-center content-center mt-10 mb-10">
                  <DtrReports
                    data={dtrTableData}
                    fromDate={selectedFromDate}
                    toDate={selectedToDate}
                    fundHouse={userData?.client_id}
                  />
                </div>
              ) : reportType === 2 ? (
                <div className="flex justify-center content-center mt-10 mb-10">
                  {userData?.client_id === 248 ? (
                    <InvictaUcrReport
                      data={ucrTableData}
                      fundHouse={userData?.client_id}
                      accountNumber={accountNumber}
                    />
                  ) : (
                    <UcrReports
                      data={ucrTableData}
                      toDate={selectedToDate}
                      fundHouse={userData?.client_id}
                    />
                  )}
                </div>
              ) : reportType === 3 ? (
                <div className="flex justify-center content-center mt-10 mb-10">
                  <UhrReports
                    data={uhrTableData}
                    toDate={selectedToDate}
                    fundHouse={userData?.client_id}
                  />
                </div>
              ) : reportType === 4 ? (
                <div className="flex justify-center content-center mt-10 mb-10">
                  <TransactionRegisterReports
                    data={transactionRegisterData}
                    toDate={selectedToDate}
                    fundHouse={userData?.client_id}
                  />
                </div>
              ) : reportType === 5 ? (
                <div className="flex justify-center content-center mt-10 mb-10">
                  <NctReports
                    data={nctTableData}
                    toDate={selectedToDate}
                    fundHouse={userData?.client_id}
                  />
                </div>
              ) : reportType === 6 ? (
                <div className="flex justify-center content-center mt-10 mb-10">
                  <CreditMarkingReports
                    data={creditMarkingData}
                    toDate={selectedToDate}
                    fundHouse={userData?.client_id}
                  />
                </div>
              ) : reportType === 7 ? (
                <div className="flex justify-center content-center mt-10 mb-10">
                  <NavReports
                    data={navReportData}
                    toDate={selectedToDate}
                    fundHouse={userData?.client_id}
                  />
                </div>
              ) : reportType === 8 ? (
                <div className="flex justify-center content-center mt-10 mb-10">
                  <DrawdownReport
                    data={drawdownReportData}
                    toDate={selectedToDate}
                    fundHouse={userData?.client_id}
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <div
              className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
              role="alert"
            >
              Please Select Client, Fund & Date
            </div>
          )}
        </>
      ) : (
        <div
          className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
          role="alert"
        >
          Please Select A Report Type
        </div>
      )}
    </>
  );
};

export default ReportGeneration;
