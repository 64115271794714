import React, { useEffect, useState } from "react";
import { Input, Select, Modal, Radio, Table, Button } from "antd";
import { useStateContext } from "../../contexts/ContextProvider";
import axios from "axios";
import Footer from "../../components/Footer";
import "./ServiceRequest.css";
import moment from "moment";
import KnowledgeBase from "../../components/KnowledgeBase";
import {
  saveVaultDetails,
  uploadDocstoGDrive,
} from "../../services/ApiServices";

const ServiceRequests = () => {
  const { apiBaseUrl, user_id, client_id } = useStateContext();
  const [selectedFundHouse, setSelectedFundHouse] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [schemeData, setSchemeData] = useState([]);
  const [fundData, setFundData] = useState([]);
  const [nctTableData, setNctTableData] = useState([]);
  const [politicalData, setPoliticalData] = useState([]); // for storing subject data
  const [occupationData, setOccupationData] = useState([]); // for storing subject data
  const [disableSubjectField, setDisableSubjectField] = useState(true);
  const [disableServiceRequest, setDisableServiceRequest] = useState(false);
  const [randomKey, setRandomKey] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [subjectData, setSubjectData] = useState([]);
  const [disableSubject, setDisableSubject] = useState(false);
  const [columnName, setColumnName] = useState("");
  const [subject, setSubject] = useState("");
  const [genderData, setGenderData] = useState([]); // for storing subject data
  const [files, setFiles] = useState();
  const [nctData, setNctData] = useState([]);
  const [relationshipData, setRelationshipData] = useState([]); // for storing subject data
  const fileInput = React.useRef();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [addressOldData, setAddressOldData] = useState([]);
  const [perAddressOldData, setPerAddressOldData] = useState([]);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [bankOldData, setBankOldData] = useState([]);
  const [bankName, setBankName] = useState("");
  const [accountNum, setAccountNum] = useState("");
  const [accountType, setAccountType] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [micr, setMicr] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryOldName, setCountryOldName] = useState("");
  const fetchFundData = async () => {
    fetch(`${apiBaseUrl}/VFund/GetFundbyCilentid/${client_id}`)
      .then((response) => response.json())
      .then((json) => {
        setFundData(json.responseData);
      })
      .catch((error) => console.log(`Error Loading Data : ${error.message}`));
  };

  useEffect(() => {
    fetchFundData();
    getDocumentTypeData();
  }, []);
  const [documentTypeData, setDocumentTypeData] = useState([]);
  const [documentTypeID, setDocumentTypeID] = useState({ id: "", name: "" });
  const getDocumentTypeData = async () => {
    await axios
      .get(`${apiBaseUrl}/Common/Digitaldocinfodetails/${client_id}`)
      .then((response) => {
        setDocumentTypeData(response.data.responseData);
      });
  };
  const [subjectName, setSubjectName] = useState("");
  const [oldValueData, setOldValueData] = useState("");
  const [oldValueField, setOldValueField] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [disableGetButton, setDisableGetButton] = useState(true);
  const [selectedAccountNumber, setSelectedAccountNumber] = useState("");
  const success = (ticketNumber) => {
    Modal.success({
      onOk: () => {
        window.location.reload(false);
      },
      content: `Service Request submitted with ticket number                 ${ticketNumber} `,
    });
  };
  const fetchNctTransactionData = () => {
    setShowTable(true);
  };

  useEffect(() => {
    nctData?.length > 0 &&
      nctData?.forEach((ele, index) => {
        ele.sno = index + 1;
        ele.nct_transaction_date = moment(ele.nct_transaction_date)
          .format("DD/MM/YYYY")
          .toString();
        ele.nct_status =
          ele.nct_status === "Open"
            ? "Pending"
            : ele.nct_status === "Closed"
            ? "Success"
            : ele.nct_status === "Resolved"
            ? "Resolved"
            : "Null";

        ele.nct_old_value =
          ele.nct_subject_id === 154 || ele.nct_subject_id === 155 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === 1
                ? "Yes"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 2
                ? "No"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ""
                ? "Not Available"
                : "Not Applicable"}
            </>
          ) : ele.nct_subject_id === 28 ||
            ele.nct_subject_id === 48 ||
            ele.nct_subject_id === 64 ||
            ele.nct_subject_id === 65 ||
            ele.nct_subject_id === 49 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === 1
                ? "Male"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 2
                ? "Female"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : ele.nct_subject_id === 115 || ele.nct_subject_id === 116 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === 1
                ? "Son"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 2
                ? "Father"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 3
                ? "Mother"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 4
                ? "Wife"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 5
                ? "Husband"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 6
                ? "Court Appointed"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 7
                ? "Daughter"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : ele.nct_subject_id === 152 || ele.nct_subject_id === 153 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === 1
                ? "Farmer"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 2
                ? "Daily Wager"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 3
                ? "Engineer"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : Object.values(JSON.parse(ele.nct_old_value))[0] === "" ? (
            <>Not Available</>
          ) : (
            <>{Object.values(JSON.parse(ele.nct_old_value))[0]}</>
          );

        ele.nct_new_value =
          ele.nct_subject_id === 154 || ele.nct_subject_id === 155 ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === 1
                ? "Yes"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === 2
                ? "No"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ""
                ? "Not Available"
                : "Not Applicable"}
            </>
          ) : ele.nct_subject_id === 28 ||
            ele.nct_subject_id === 48 ||
            ele.nct_subject_id === 64 ||
            ele.nct_subject_id === 65 ||
            ele.nct_subject_id === 49 ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === 1
                ? "Male"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === 2
                ? "Female"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : ele.nct_subject_id === 115 || ele.nct_subject_id === 116 ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === 1
                ? "Son"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === 2
                ? "Father"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === 3
                ? "Mother"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === 4
                ? "Wife"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === 5
                ? "Husband"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === 6
                ? "Court Appointed"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === 7
                ? "Daughter"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : ele.nct_subject_id === 152 || ele.nct_subject_id === 153 ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === 1
                ? "Farmer"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === 2
                ? "Daily Wager"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === 3
                ? "Engineer"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : Object.values(JSON.parse(ele.nct_new_value))[0] === "" ? (
            <>Not Available</>
          ) : (
            <>{Object.values(JSON.parse(ele.nct_new_value))[0]}</>
          );
      });
    setNctTableData(nctData);
  }, [nctData]);

  const [arnCode, setARNCode] = useState("");
  const [rmData, setRMData] = useState([]);
  const [rmName, setRMName] = useState("");
  const [distributorData, setDistributorData] = useState([]);
  const [distributorEmail, setDistributorEmail] = useState("");
  const [distributorName, setDistributorName] = useState("");
  const [distributorMobile, setDistributorMobile] = useState("");
  const [distributorid, setDistributorid] = useState("");
  const [distributorEuin, setDistributorEuin] = useState("");
  const [distributorOldData, setDistributorOldData] = useState([]);

  const onCountrySelect = (e) => {
    setCountry(e);
  };

  const nctColumns = [
    {
      title: "S. No",
      dataIndex: "sno",
    },
    {
      title: "Transaction Date",
      dataIndex: "nct_transaction_date",
    },
    {
      title: "Ticket Type",
      dataIndex: "nct_category_description",
    },
    {
      title: "Service Request",
      dataIndex: "nct_subject_description",
    },
    {
      title: "Value",
      dataIndex: "nct_new_value",
      render: (text, record) => {
        if (record.nct_old_value) {
          return (
            <>
              <span>
                Old Value:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {record.nct_old_value}
                </span>
              </span>
              <br />
              <span>
                New Value: <span style={{ fontWeight: "bold" }}>{text}</span>
              </span>
            </>
          );
        } else {
          return <span>{text}</span>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "nct_status",
    },
  ];

  const [previousActionDone, setPreviousActionDone] = useState(false);
  const [value, setValue] = useState();
  const [disableScheme, setDisableScheme] = useState(true);

  const handleSubject = (e) => {
    const newSubject = e.target.value;
    setSubject(newSubject); //input value of subject
    setDisableSubmitButton(false);
  };
  const handleSelectAccountNumber = (e) => {
    const accountNumberEntry = e.target.value;
    setSelectedAccountNumber(accountNumberEntry); //input value of subject

    fetch(
      `${apiBaseUrl}/VAccount/Getvadiledaccount/${accountNumberEntry}?clientid=${client_id}`
    ) //For selecting scheme by client
      .then((response) => response.json())
      .then((json) => {
        if (json.responseData !== null) {
          setSchemeData(json.responseData);
          setShowErrorMessage(false);
          setDisableScheme(false);
          console.log(json.responseData, "data"); //storing the data
        } else {
          setShowErrorMessage(true);
        }
      })
      .catch((error) => console.log(`Error Loading Data : ${error.message}`));
  };

  const handleSelectFundHouse = (e) => {
    setSelectedFundHouse(e);
    setPreviousActionDone(true);
  };
  const handleSelectScheme = (e) => {
    fetch(`${apiBaseUrl}/VNct/GetNctTransactions/${e}/${client_id}`)
      .then((response) => response.json())
      .then((json) => {
        setNctData(json.responseData);
      })
      .catch((error) => alert("something went error"));
    setDisableGetButton(false);
  };
  const onTypeChange = (e) => {
    // for setting export format value in value state
    setValue(e.target.value);
    setDisableSubject(true);
    fetch(
      `${apiBaseUrl}/VNct/GetSubject/${selectedFundHouse}/${e.target.value}`
    ) //For selecting subject by scheme and category
      .then((response) => response.json())
      .then((json) => {
        setSubjectData(json.responseData); //storing the data in Subject data
      })
      .catch((error) => console.log(`Error Loading Data : ${error.message}`));
  };
  const handleSelectRMName = (e) => {
    setRMName(e);
  };
  const handleARNCode = (newARNCode) => {
    const formattedARNCode = newARNCode.toUpperCase();
    setARNCode(formattedARNCode);

    if (formattedARNCode === "") {
      setDistributorData([]); // or setDistributorData(null);
      return;
    }

    fetch(`${apiBaseUrl}/VDistributor/Getrmbydistributor/${formattedARNCode}`)
      .then((response) => response.json())
      .then((json) => {
        if (json.responseData == []) {
          Modal.error({
            content: `Invalid ARN Code`,
          });
        } else {
          setRMData(json.responseData);
        }
      })
      .catch((error) => console.log(`Error Loading Data: ${error.message}`));

    fetch(
      `${apiBaseUrl}/Vlookup/GetDistributorbyarncode?arncode=${formattedARNCode}`
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.responseData == []) {
          Modal.error({
            content: `Invalid ARN Code`,
          });
        } else {
          setDistributorData(json.responseData);
          setDistributorEmail(json.responseData[0].distributor_email_address);
          setDistributorName(json.responseData[0].distributor_legal_name);
          setDistributorMobile(json.responseData[0].distributor_mobile_number);
          setDistributorid(json.responseData[0].distributor_id);
          setDistributorEuin(json.responseData[0].euin);
        }
      })
      .catch((error) => console.log(`Error Loading Data: ${error.message}`));
  };

  const handleSelectSubject = (e) => {
    setDisableSubjectField(false);
    setSelectedSubject(e);
    setOldValueField(true);
    setDisableServiceRequest(true);
    setColumnName(
      subjectData.filter((item) => item.nct_subject_id === e)[0].nct_column_name
    ); //For getting column name by subject id
    setSubjectName(
      subjectData.filter((item) => item.nct_subject_id === e)[0]
        .nct_subject_code
    ); //For getting subject name by subject id

    fetch(`${apiBaseUrl}/VNct/GetOldValue/${selectedAccountNumber}/${e}`) //For selecting subject by scheme and category
      .then((response) => response.json())
      .then((json) => {
        console.log("Old Value data.........", json);

        e === 154 || e === 155
          ? json.responseData === 1
            ? setOldValueData("Yes")
            : json.responseData === 2
            ? setOldValueData("No")
            : json.responseData === ""
            ? setOldValueData("Not Available")
            : setOldValueData("Not Applicable")
          : e === 28 || e === 48 || e === 49 || e === 64 || e === 65
          ? json.responseData === 1
            ? setOldValueData("Male")
            : json.responseData === 2
            ? setOldValueData("Female")
            : json.responseData === ""
            ? setOldValueData("Not Available")
            : setOldValueData("Others")
          : e === 115 || e === 116
          ? json.responseData === 1
            ? setOldValueData("Son")
            : json.responseData === 2
            ? setOldValueData("Father")
            : json.responseData === 3
            ? setOldValueData("Mother")
            : json.responseData === 4
            ? setOldValueData("Wife")
            : json.responseData === 5
            ? setOldValueData("Husband")
            : json.responseData === 6
            ? setOldValueData("Court Appointed")
            : json.responseData === 7
            ? setOldValueData("Daughter")
            : json.responseData === ""
            ? setOldValueData("Not Available")
            : setOldValueData("Others")
          : e === 152 || e === 153
          ? json.responseData === 1
            ? setOldValueData("Private Sector Service")
            : json.responseData === 2
            ? setOldValueData("Public Sector")
            : json.responseData === 3
            ? setOldValueData("Government Service")
            : json.responseData === 4
            ? setOldValueData("Housewife")
            : json.responseData === ""
            ? setOldValueData("N/A")
            : setOldValueData("Others")
          : setOldValueData(json.responseData);

        e === 1 || e === 2
          ? setDistributorOldData(JSON.parse(json.responseData))
          : setDistributorOldData("Not Available");

        e === 77 || e === 78
          ? setPerAddressOldData(JSON.parse(json.responseData)) &&
            setCountryId(JSON.parse(json.responseData).country_id)
          : setPerAddressOldData("Not Available");

        e === 75 || e === 76
          ? setAddressOldData(JSON.parse(json.responseData)) &&
            setCountryId(JSON.parse(json.responseData).country_id)
          : setAddressOldData("Not Available");

        e === 127 || e === 128
          ? setBankOldData(JSON.parse(json.responseData))
          : setBankOldData("Not Available");
      })
      .catch((error) => console.log(`Error Loading Data : ${error.message}`));

    fetch(`${apiBaseUrl}/Vlookup/GetGender`) // for fetching Query data
      .then((response) => response.json())
      .then((json) => {
        setGenderData(json.responseData);
      });

    fetch(`${apiBaseUrl}/Vlookup/GetRelationType?type=1`) // for fetching Query data
      .then((response) => response.json())
      .then((json) => {
        setRelationshipData(json.responseData);
      });

    fetch(`${apiBaseUrl}/Vlookup/GetPoliticalExporusreTypes`) // for fetching Query data
      .then((response) => response.json())
      .then((json) => {
        setPoliticalData(json.responseData);
      });

    console.log(`${apiBaseUrl}/Vlookup/GetOccupation`);
    fetch(`${apiBaseUrl}/Vlookup/GetOccupation`) // for fetching Query data
      .then((response) => response.json())
      .then((json) => {
        setOccupationData(json.responseData);
      });

    fetch(`${apiBaseUrl}/Common/GetCountry`) // for fetching Query data
      .then((response) => response.json())
      .then((json) => {
        setCountryData(json.responseData);
      });

    fetch(`${apiBaseUrl}/Common/GetCountry?countryid=${countryId}`) // for fetching Query data
      .then((response) => response.json())
      .then((json) => {
        setCountryOldName(countryId);
      });
  };

  const onGenderSelect = (e) => {
    setSubject(e);
    setDisableSubmitButton(false);
  };

  const onPoliticalTypeSelect = (e) => {
    setSubject(e);
    setDisableSubmitButton(false);
  };

  const onRelationSelect = (e) => {
    setSubject(e);
    setDisableSubmitButton(false);
  };

  const onOccupationSelect = (e) => {
    setSubject(e);
    setDisableSubmitButton(false);
  };

  const handleUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 4) {
      Modal.warning({
        title: "Files Upload",
        content: "You can only select a maximum of 4 files",
      });
      setFiles(null);
      let randomString = Math.random().toString(36);
      setRandomKey(randomString);
      return;
    }
    setFiles(files);
  };

  const raiseNCT = async () => {
    if (!selectedFundHouse) {
      Modal.error({
        title: "Error",
        content: "Select Fund",
      });
    } else if (!value) {
      Modal.error({
        title: "Error",
        content: "Select Service Request Category",
      });
    } else if (selectedSubject === "1" || selectedSubject === "2") {
      if (!arnCode) {
        Modal.error({
          title: "Error",
          content: "ARN Code is empty",
        });
      } else if (!rmName) {
        Modal.error({
          title: "Error",
          content: "Select RM Name",
        });
      } else {
        try {
          await raiseServiceReq();
        } catch (error) {
          Modal.error({
            title: "Error",
            content: "API call failed",
          });
        }
      }
    } else if (
      selectedSubject === "75" ||
      selectedSubject === "76" ||
      selectedSubject === "77" ||
      selectedSubject === "78"
    ) {
      if (!addressLine1) {
        Modal.error({
          title: "Error",
          content: "Address line 1 is empty, Enter Address line 1",
        });
      } else if (!addressLine2) {
        Modal.error({
          title: "Error",
          content: "Address line 2 is empty, Enter Address line 2",
        });
      } else if (!city) {
        Modal.error({
          title: "Error",
          content: "City is empty, Enter City",
        });
      } else if (!state) {
        Modal.error({
          title: "Error",
          content: "State is empty, Enter State",
        });
      } else if (!pincode) {
        Modal.error({
          title: "Error",
          content: "Pincode is empty, Enter Pincode",
        });
      } else if (!country) {
        Modal.error({
          title: "Error",
          content: "Select Country",
        });
      } else {
        try {
          await raiseServiceReq();
        } catch (error) {
          Modal.error({
            title: "Error",
            content: "API call failed",
          });
        }
      }
    } else if (selectedSubject === "127" || selectedSubject === "128") {
      if (!bankName) {
        Modal.error({
          title: "Error",
          content: "Bank Name is empty, Enter Bank Name",
        });
      } else if (!accountNum) {
        Modal.error({
          title: "Error",
          content: "Bank Account Number is empty, Enter Bank Account Number",
        });
      } else if (!ifsc) {
        Modal.error({
          title: "Error",
          content: "IFSC Code is empty, Enter IFSC Code",
        });
      } else if (!micr) {
        Modal.error({
          title: "Error",
          content: "MICR Code is empty, Enter MICR Code",
        });
      } else if (!accountType) {
        Modal.error({
          title: "Error",
          content: "Account Type is empty, Enter Account Type",
        });
      } else {
        try {
          await raiseServiceReq();
        } catch (error) {
          Modal.error({
            title: "Error",
            content: "API call failed",
          });
        }
      }
    } else if (
      (selectedSubject !== "1" ||
        selectedSubject !== "2" ||
        selectedSubject !== "75" ||
        selectedSubject !== "76" ||
        selectedSubject !== "77" ||
        selectedSubject !== "78" ||
        selectedSubject !== "127" ||
        selectedSubject !== "128") &&
      !subject
    ) {
      Modal.error({
        title: "Error",
        content: "Please enter subjects",
      });
    } else {
      try {
        await raiseServiceReq();
      } catch (error) {
        Modal.error({
          title: "Error",
          content: "API call failed",
        });
      }
    }
  };
  //For raising NCT
  const raiseServiceReq = () => {
    const newValue = {
      "RM Name": rmName,
      distributor_id: distributorid,
      distributor_legal_name: distributorName,
      arn: arnCode,
      euin: distributorEuin,
      primary_email_id: distributorEmail,
      primary_phone_number: distributorMobile,
    };
    const newAddressValue = {
      address_line1: addressLine1,
      address_line2: addressLine2,
      city: city,
      state_province_text: state,
      pincode: pincode,
      country_id: country,
    };

    const newPerAddressValue = {
      address_line1: addressLine1,
      address_line2: addressLine2,
      city: city,
      state_province_text: state,
      pincode: pincode,
      country_id: country,
    };

    const newBankValue = {
      bank_name: bankName,
      bank_account_number: accountNum,
      bank_micr: micr,
      bank_ifsc: ifsc,
      account_type: accountType,
    };

    let nctNewValue;
    if (selectedSubject === "1" || selectedSubject === "2") {
      nctNewValue = JSON.stringify(newValue);
      console.log("nctNewValue", nctNewValue);
    } else if (selectedSubject === "75" || selectedSubject === "76") {
      nctNewValue = JSON.stringify(newPerAddressValue);
      console.log("nctNewValue", nctNewValue);
    } else if (selectedSubject === "77" || selectedSubject === "78") {
      nctNewValue = JSON.stringify(newAddressValue);
      console.log("nctNewValue", nctNewValue);
    } else if (selectedSubject === "127" || selectedSubject === "128") {
      nctNewValue = JSON.stringify(newBankValue);
      console.log("nctNewValue", nctNewValue);
    } else {
      nctNewValue = JSON.stringify({ [columnName]: subject });
    }
    const data = {
      account_no: selectedAccountNumber,
      nct_category_id: value,
      subject_id: selectedSubject,
      nct_transaction_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      nct_new_value: nctNewValue,
      status: "Pending",
      creation_by: user_id,
      creation_date: moment(new Date()).format("YYYY-MM-DD"),
      nct_type: "Creation",
      nct_transaction_id: 0,
      nct_transaction_sourcetype_id: 4,
    };
    axios.post(`${apiBaseUrl}/VNct/PostNct`, data).then((response) => {
      if (
        typeof response.data.responseData === "string" &&
        response.data.responseData?.startsWith("No")
      ) {
        Modal.error({
          title: "Failure",
          content: response.data.responseData,
        });
      } else {
        UploadAllFilestoGDrive(response.data.responseData.nct_ih_number);
      }
    });
  };

  const UploadAllFilestoGDrive = (folderName) => {
    handleUploadAndSaveDocuments(files, folderName);
  };

  const handleUploadAndSaveDocuments = async (files, ticketNumber) => {
    try {
      const fileUrl =
        "client-bucket_InvestorDocs_" +
        client_id +
        "_" +
        selectedFundHouse +
        "_" +
        selectedAccountNumber +
        "nct" +
        ticketNumber;

      const res = await uploadDocstoGDrive(files, documentTypeID, fileUrl);
      if (res) {
        uploadToVaultDetails(res, files, ticketNumber);
      } else {
        Modal.error({
          title: "Error",
          content: "Something went wrong. Please try again",
        });
      }
    } catch (err) {
      Modal.error({
        title: "Error",
        content: "Something went wrong. Please try again",
      });
    }
  };

  const uploadToVaultDetails = async (webUrls, files, ticketNumber) => {
    try {
      let entityReferenceNumber = selectedAccountNumber;
      let payloadArray = webUrls.WebContentLink.map((url, i) => {
        const file = files[i]; // Assuming files is an array and matches the URL count

        const metaData = {
          file_original_name: `nct_${entityReferenceNumber}_${ticketNumber}_${documentTypeID.name.replace(
            /\s+/g,
            ""
          )}_${i}`,
          docType: "NCT",
          uploadedBy: 0,
          uploadedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          modified: false,
          fileSize: file.size,
          module: "Inward",
        };

        // Construct the payload for each file and corresponding URL
        return {
          vault_id: 0,
          entity_id: 1,
          document_type: "NCT",
          entity_reference_number: selectedAccountNumber,
          storage_category: "Inward",
          s3_path: url,
          created_by: user_id,
          creation_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          isactive: true,
          updated_by: user_id,
          updation_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          document_name: `${documentTypeID.name.replace(
            /\s+/g,
            ""
          )}_${ticketNumber}`,
          metadata: JSON.stringify(metaData),
          transaction_reference_number: ticketNumber,
          document_type_id: documentTypeID.id,
          file_type: file.type,
        };
      });

      const resp = await saveVaultDetails(payloadArray);
      if (resp.responseData) {
        success(ticketNumber);
      } else {
        Modal.error({
          title: "Error",
          content: "Something went wrong. Please try again",
        });
      }
    } catch (err) {
      Modal.error({
        title: "Error",
        content: "Something went wrong. Please try again",
      });
    }
  };

  const handleRemoveUpload = () => {
    let randomString = Math.random().toString(36);
    setRandomKey(randomString);
    setFiles(null);
  };

  const [selectedOption, setSelectedOption] = useState("serviceRequest");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleKnowledgeBaseClick = () => {
    setSelectedOption("knowledgeBase"); // Set the activeButton state to 3 to activate the KnowledgeBase button
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "98%",
            borderRadius: 10,
            padding: "2%",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "2%",
            }}
          >
            Register Service Request
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "5%",
              width: "80%",
              margin: "0 auto",
            }}
          >
            <div
              className={`text-center hoverable ${
                selectedOption === "serviceRequest" ? "selected" : ""
              }`}
              style={{
                cursor: "pointer",
                backgroundColor:
                  selectedOption === "serviceRequest" ? "#A3030C" : "#001F60",
                padding: "1.5%",
                borderRadius: 10,
                color: "white",
                width: "20%",
              }}
              onClick={() => handleOptionClick("serviceRequest")}
            >
              Register
              <br />
              Service Request
            </div>
            <div
              className={`text-center hoverable ${
                selectedOption === "interactionHistory" ? "selected" : ""
              }`}
              style={{
                cursor: "pointer",
                backgroundColor:
                  selectedOption === "interactionHistory"
                    ? "#A3030C"
                    : "#001F60",
                padding: "1.5%",
                borderRadius: 10,
                color: "white",
                width: "20%",
              }}
              onClick={() => handleOptionClick("interactionHistory")}
            >
              Interaction
              <br />
              History
            </div>
            <div
              className={`text-center hoverable ${
                selectedOption === "knowledgeBase" ? "selected" : ""
              }`}
              style={{
                cursor: "pointer",
                backgroundColor:
                  selectedOption === "knowledgeBase" ? "#A3030C" : "#001F60",
                padding: "1.5%",
                borderRadius: 10,
                color: "white",
                width: "20%",
              }}
              onClick={() => handleOptionClick("knowledgeBase")}
            >
              Knowledgebase
              <br />
              Articles
            </div>
          </div>
          {selectedOption === "serviceRequest" && (
            <>
              <div style={{ marginTop: "2%", textAlign: "center" }}>
                Did you read our FAQs? It is very likely that the solution you
                are looking for may be available in our{" "}
                <span
                  onClick={handleKnowledgeBaseClick}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  KnowledgeBase{" "}
                </span>
                .
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingLeft: "20%",
                  paddingRight: "20%",
                  marginTop: "3%",
                }}
              >
                <div style={{ width: "40%" }}>Account Number</div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Input
                    placeholder="Enter Account Number"
                    style={{
                      fontSize: "14px",
                      color: "#A3020C",
                      width: "300px",
                    }}
                    allowClear
                    onChange={handleSelectAccountNumber}
                  />
                  <div>
                    {showErrorMessage ? (
                      <p style={{ color: "red" }}>Invalid Account Number</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingLeft: "20%",
                  paddingRight: "20%",
                  marginTop: "3%",
                }}
              >
                <div style={{ width: "40%" }}>In which Fund?</div>
                <div>
                  <Select
                    disabled={disableScheme}
                    showSearch
                    placeholder="Select Fund"
                    optionFilterProp="children"
                    onChange={handleSelectFundHouse}
                    style={{
                      fontSize: "14px",
                      color: "#A3020C",
                      width: "300px",
                    }}
                  >
                    {schemeData?.map((ele, index) => (
                      <Select.Option key={index} value={ele.fund_id}>
                        {ele.fund_name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingLeft: "20%",
                  paddingRight: "20%",
                  marginTop: "3%",
                }}
              >
                <div style={{ width: "40%" }}>What is the Ticket Type?</div>
                <div style={{ alignSelf: "center" }}>
                  <div id="radio" className=" flex flex-col gap-2">
                    <Radio.Group
                      onChange={onTypeChange}
                      value={value}
                      disabled={!previousActionDone}
                    >
                      <Radio value={1}>Query</Radio>
                      <Radio value={2}>Request</Radio>
                      <Radio value={3}>Complaint</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingLeft: "20%",
                  paddingRight: "20%",
                  marginTop: "3%",
                }}
              >
                <div style={{ width: "40%" }}>What is the Service Request?</div>
                <div style={{ alignSelf: "center" }}>
                  <Select
                    disabled={!disableSubject}
                    showSearch
                    placeholder="Select Service Request"
                    optionFilterProp="children"
                    onChange={(e) => handleSelectSubject(e)}
                    style={{
                      fontSize: "14px",
                      color: "#A3020C",
                      width: "300px",
                    }}
                  >
                    {subjectData.map((ele, index) => (
                      <Select.Option key={index} value={ele.nct_subject_id}>
                        {ele.nct_subject_code}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              {selectedSubject === 1 ||
              selectedSubject === 2 ||
              selectedSubject === 127 ||
              selectedSubject === 128 ||
              selectedSubject === 75 ||
              selectedSubject === 76 ||
              selectedSubject === 77 ||
              selectedSubject === 78 ? null : (
                <>
                  {oldValueField && oldValueData !== "" ? (
                    <div
                      style={{
                        marginTop: "1%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "2%",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "30%" }}>
                        <h1 style={{ textAlign: "left", marginRight: "8%" }}>
                          Old Value :
                        </h1>
                      </div>
                      <div style={{ marginRight: "6%" }}>{oldValueData}</div>
                    </div>
                  ) : oldValueField &&
                    oldValueData === "" &&
                    oldValueData === null ? (
                    <div
                      style={{
                        marginTop: "1%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "2%",
                        width: "100%",
                      }}
                    >
                      No Record Available
                    </div>
                  ) : null}
                </>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingLeft: "20%",
                  paddingRight: "20%",
                  marginTop: "3%",
                }}
              >
                {disableServiceRequest ? (
                  <>
                    {selectedSubject === 1 ||
                    selectedSubject === 2 ||
                    selectedSubject === 127 ||
                    selectedSubject === 128 ||
                    selectedSubject === 75 ||
                    selectedSubject === 76 ||
                    selectedSubject === 77 ||
                    selectedSubject === 78 ? null : (
                      <div style={{ width: "40%" }}>What is the issue?</div>
                    )}
                    <div style={{ alignSelf: "center" }}>
                      {selectedSubject === 1 || selectedSubject === 2 ? (
                        <>
                          <div
                            style={{
                              marginTop: "1%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={arnCode}
                                disabled={disableSubjectField}
                                placeholder="Enter the ARN Code"
                                allowClear
                                onChange={(e) => handleARNCode(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "300px",
                                }}
                              />
                              <div>
                                Old ARN Code:{" "}
                                {distributorOldData.arn
                                  ? distributorOldData.arn
                                  : "Not Available"}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: "5%",
                              marginTop: "1%",
                            }}
                          >
                            <div>
                              <Select
                                disabled={disableSubjectField}
                                showSearch
                                placeholder="Select RM Name"
                                optionFilterProp="children"
                                onChange={handleSelectRMName}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "300px",
                                  height: "35px",
                                }}
                              >
                                {rmData?.map((ele, index) => (
                                  <Select.Option
                                    key={index}
                                    value={ele.first_name + ele.last_name}
                                  >
                                    {ele.first_name} {ele.last_name}
                                  </Select.Option>
                                ))}
                              </Select>
                              <div>
                                RM:{" "}
                                {distributorOldData["RM Name"]
                                  ? distributorOldData["RM Name"]
                                  : "Not Available"}
                              </div>
                              <Input
                                disabled={true}
                                value={
                                  distributorData[0]?.distributor_email_address
                                }
                                placeholder="Enter the Email Address"
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "300px",
                                  height: "35px",
                                  marginTop: "3%",
                                }}
                              />
                              <div>
                                Distributor Email:{" "}
                                {distributorOldData.primary_email_id
                                  ? distributorOldData.primary_email_id
                                  : "Not Available"}
                              </div>
                            </div>
                            <div>
                              <Input
                                disabled={true}
                                value={
                                  distributorData[0]?.distributor_legal_name
                                }
                                placeholder="Enter the Distributor Name"
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "300px",
                                  height: "35px",
                                }}
                              />
                              <div>
                                Distributor Name:
                                {distributorOldData.distributor_legal_name
                                  ? distributorOldData.distributor_legal_name
                                  : "Not Available"}
                              </div>
                              <Input
                                disabled={true}
                                value={
                                  distributorData[0]?.distributor_mobile_number
                                }
                                placeholder="Enter the Mobile Number"
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "300px",
                                  height: "35px",
                                  marginTop: "2%",
                                }}
                              />
                              <div>
                                Distributor Phone Number:
                                {distributorOldData.primary_phone_number
                                  ? distributorOldData.primary_phone_number
                                  : "Not Available"}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : selectedSubject === 127 || selectedSubject === 128 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "4%",
                            marginTop: "1%",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={bankName}
                                disabled={disableSubjectField}
                                placeholder="Enter the Bank Name"
                                allowClear
                                onChange={(e) => setBankName(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                Bank :{" "}
                                {bankOldData.bank_name
                                  ? bankOldData.bank_name
                                  : "Not Available"}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={ifsc}
                                disabled={disableSubjectField}
                                placeholder="Enter the IFSC Code"
                                allowClear
                                onChange={(e) => setIfsc(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                IFSC :{" "}
                                {bankOldData.bank_ifsc
                                  ? bankOldData.bank_ifsc
                                  : "Not Available"}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={accountType}
                                disabled={disableSubjectField}
                                placeholder="Enter the Account Type"
                                allowClear
                                onChange={(e) => setAccountType(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                Account Type :{" "}
                                {bankOldData.account_type
                                  ? bankOldData.account_type
                                  : "Not Available"}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={accountNum}
                                disabled={disableSubjectField}
                                placeholder="Enter the State"
                                allowClear
                                onChange={(e) => setAccountNum(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                Bank Account Number:{" "}
                                {bankOldData.bank_account_number
                                  ? bankOldData.bank_account_number
                                  : "Not Available"}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={micr}
                                disabled={disableSubjectField}
                                placeholder="Enter the Bank MICR Code"
                                allowClear
                                onChange={(e) => setMicr(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                MICR :{" "}
                                {bankOldData.bank_micr
                                  ? bankOldData.bank_micr
                                  : "Not Available"}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedSubject === 77 || selectedSubject === 78 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "4%",
                            marginTop: "1%",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={addressLine1}
                                disabled={disableSubjectField}
                                placeholder="Enter the Address line 1"
                                allowClear
                                onChange={(e) =>
                                  setAddressLine1(e.target.value)
                                }
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                Address line 1:
                                {addressOldData.address_line1
                                  ? addressOldData.address_line1
                                  : "Not Available"}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={city}
                                disabled={disableSubjectField}
                                placeholder="Enter the City"
                                allowClear
                                onChange={(e) => setCity(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                City:
                                {addressOldData.city
                                  ? addressOldData.city
                                  : "Not Available"}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={pincode}
                                type="number"
                                maxLength={6}
                                disabled={disableSubjectField}
                                placeholder="Enter the pincode"
                                allowClear
                                onChange={(e) => setPincode(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                Pincode:{" "}
                                {addressOldData.pincode
                                  ? addressOldData.pincode
                                  : "Not Available"}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={addressLine2}
                                disabled={disableSubjectField}
                                placeholder="Enter the Address line 2"
                                allowClear
                                onChange={(e) =>
                                  setAddressLine2(e.target.value)
                                }
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                Address line 2:{" "}
                                {addressOldData.address_line2
                                  ? addressOldData.address_line2
                                  : "Not Available"}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={state}
                                disabled={disableSubjectField}
                                placeholder="Enter the State"
                                allowClear
                                onChange={(e) => setState(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                State:{" "}
                                {addressOldData.state_province_text
                                  ? addressOldData.state_province_text
                                  : "Not Available"}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Select
                                showSearch
                                disabled={!disableSubject}
                                placeholder="Select Country"
                                optionFilterProp="children"
                                onChange={(e) => onCountrySelect(e)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              >
                                {countryData.map((ele, index) => (
                                  <Select.Option
                                    key={index}
                                    value={ele.country_id}
                                  >
                                    {ele.code_description}
                                  </Select.Option>
                                ))}
                              </Select>
                              <div>
                                Country:{" "}
                                {countryOldName
                                  ? countryOldName
                                  : "Not Available"}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedSubject === 75 || selectedSubject === 76 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "4%",
                            marginTop: "1%",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={addressLine1}
                                disabled={disableSubjectField}
                                placeholder="Enter the Address line 1"
                                allowClear
                                onChange={(e) =>
                                  setAddressLine1(e.target.value)
                                }
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                Address line 1:
                                {perAddressOldData.address_line1
                                  ? perAddressOldData.address_line1
                                  : "Not Available"}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={city}
                                disabled={disableSubjectField}
                                placeholder="Enter the City"
                                allowClear
                                onChange={(e) => setCity(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                City:
                                {perAddressOldData.city
                                  ? perAddressOldData.city
                                  : "Not Available"}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={pincode}
                                type="number"
                                maxLength={6}
                                disabled={disableSubjectField}
                                placeholder="Enter the pincode"
                                allowClear
                                onChange={(e) => setPincode(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                Pincode:{" "}
                                {perAddressOldData.pincode
                                  ? perAddressOldData.pincode
                                  : "Not Available"}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={addressLine2}
                                disabled={disableSubjectField}
                                placeholder="Enter the Address line 2"
                                allowClear
                                onChange={(e) =>
                                  setAddressLine2(e.target.value)
                                }
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                Address line 2:{" "}
                                {perAddressOldData.address_line2
                                  ? perAddressOldData.address_line2
                                  : "Not Available"}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Input
                                value={state}
                                disabled={disableSubjectField}
                                placeholder="Enter the State"
                                allowClear
                                onChange={(e) => setState(e.target.value)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              />
                              <div>
                                State:{" "}
                                {perAddressOldData.state_province_text
                                  ? perAddressOldData.state_province_text
                                  : "Not Available"}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Select
                                showSearch
                                disabled={!disableSubject}
                                placeholder="Select Country"
                                optionFilterProp="children"
                                onChange={(e) => onCountrySelect(e)}
                                style={{
                                  fontSize: "14px",
                                  color: "#A3020C",
                                  width: "350px",
                                }}
                              >
                                {countryData.map((ele, index) => (
                                  <Select.Option
                                    key={index}
                                    value={ele.country_id}
                                  >
                                    {ele.code_description}
                                  </Select.Option>
                                ))}
                              </Select>
                              <div>
                                Country:{" "}
                                {countryOldName
                                  ? countryOldName
                                  : "Not Available"}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedSubject === 28 ||
                        selectedSubject === 48 ||
                        selectedSubject === 49 ||
                        selectedSubject === 64 ||
                        selectedSubject === 65 ? (
                        <div>
                          <Select
                            showSearch
                            placeholder="Select Gender"
                            optionFilterProp="children"
                            onChange={(e) => onGenderSelect(e)}
                            style={{
                              fontSize: "14px",
                              color: "#A3020C",
                              width: "300px",
                            }}
                          >
                            {genderData?.map((ele, index) => (
                              <Select.Option
                                key={ele.index}
                                value={ele.gender_id}
                              >
                                {ele.code_description}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      ) : selectedSubject === 115 || selectedSubject === 116 ? (
                        <div>
                          <Select
                            showSearch
                            placeholder="Select Relationship"
                            optionFilterProp="children"
                            onChange={(e) => onRelationSelect(e)}
                            style={{
                              fontSize: "14px",
                              color: "#A3020C",
                              width: "300px",
                            }}
                          >
                            {relationshipData?.map((ele, index) => (
                              <Select.Option
                                key={ele.index}
                                value={ele.relation_typeid}
                              >
                                {ele.code_description}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      ) : selectedSubject === 154 || selectedSubject === 155 ? (
                        <div>
                          <Select
                            showSearch
                            placeholder="Select Political Type"
                            optionFilterProp="children"
                            onChange={(e) => onPoliticalTypeSelect(e)}
                            style={{
                              fontSize: "14px",
                              color: "#A3020C",
                              width: "300px",
                            }}
                          >
                            {politicalData?.map((ele, index) => (
                              <Select.Option
                                key={ele.index}
                                value={ele.political_exporusre_type_id}
                              >
                                {ele.code_description}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      ) : selectedSubject === 153 || selectedSubject === 152 ? (
                        <div>
                          <Select
                            showSearch
                            placeholder="Select Occupation"
                            optionFilterProp="children"
                            onChange={(e) => onOccupationSelect(e)}
                            style={{
                              fontSize: "14px",
                              color: "#A3020C",
                              width: "300px",
                            }}
                          >
                            {occupationData?.map((ele, index) => (
                              <Select.Option
                                key={ele.index}
                                value={ele.occupation_id}
                              >
                                {ele.code_description}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      ) : (
                        <Input
                          disabled={disableSubjectField}
                          placeholder={`Enter the ${subjectName}`}
                          allowClear
                          onChange={handleSubject}
                          style={{
                            fontSize: "14px",
                            color: "#A3020C",
                            width: "300px",
                            height: "40px",
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : null}
              </div>
              {value !== 1 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingLeft: "20%",
                    paddingRight: "20%",
                    marginTop: "3%",
                  }}
                >
                  <div style={{ width: "40%" }}>Select Document Type</div>
                  <div style={{ alignSelf: "center" }}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      disabled={!disableSubject}
                      style={{
                        fontSize: "14px",
                        color: "#A3020C",
                        width: "300px",
                      }}
                      placeholder="Document Type"
                      onChange={(value, option) => {
                        setDocumentTypeID({ id: value, name: option.label });
                      }}
                    >
                      {documentTypeData.map((item, index) => {
                        return (
                          <Select.Option
                            key={item.kyc_document_id}
                            value={item.kyc_document_id}
                            label={item.kyc_document}
                          >
                            {item.kyc_document}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              )}

              {disableServiceRequest ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 auto",
                    alignItems: "center",
                    marginTop: "2%",
                    marginBottom: "2%",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div style={{ marginTop: "2%" }}>
                    <input
                      type="file"
                      key={randomKey || ""}
                      accept="image/*,application/pdf"
                      multiple
                      ref={fileInput}
                      onChange={(e) => handleUpload(e)}
                      style={{ marginTop: 5 }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2%",
                    }}
                  >
                    {files?.map((file) => (
                      <div key={file.name}>
                        {file.type.startsWith("image/") ? (
                          // Display the image preview if the file is an image
                          <a
                            href={URL.createObjectURL(file)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={URL.createObjectURL(file)}
                              style={{ width: 100 }}
                              alt={file.name}
                            />
                          </a>
                        ) : (
                          // Display the file name if the file is a PDF
                          <p>{file.name}</p>
                        )}
                        <p
                          className="cursor-pointer text-blue-800 underline"
                          onClick={handleRemoveUpload}
                        >
                          Remove
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "1%",
                  gap: "2%",
                  width: "100%",
                }}
              >
                <div
                  className="text-center"
                  style={{
                    fontSize: 18,
                    backgroundColor: "#001F60",
                    padding: "1%",
                    borderRadius: 10,
                    color: "white",
                    width: "20%",
                    cursor: "pointer",
                  }}
                  onClick={raiseNCT}
                >
                  Register
                </div>
              </div>
            </>
          )}
          {selectedOption === "interactionHistory" && (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  margin: "0 auto",
                  width: "70%",
                  alignSelf: "center",
                  marginTop: "3%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 40,
                  }}
                >
                  <Select
                    showSearch
                    placeholder="Select Fund"
                    optionFilterProp="children"
                    onChange={(e) => handleSelectScheme(e)}
                    style={{
                      fontSize: "14px",
                      color: "#A3020C",
                      width: "150px",
                    }}
                  >
                    {fundData.map((ele, index) => (
                      <Select.Option key={index} value={ele.fund_ID}>
                        {ele.fund_Description}
                      </Select.Option>
                    ))}
                  </Select>
                  <Button
                    disabled={disableGetButton}
                    onClick={fetchNctTransactionData}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "140px",
                      height: "33px",
                      backgroundColor: "#A3020C",
                      color: "white",
                      borderRadius: 5,
                    }}
                  >
                    Get
                  </Button>
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "140px",
                      height: "33px",
                      backgroundColor: "#A3020C",
                      color: "white",
                      borderRadius: 5,
                    }}
                    onClick={() => window.location.reload(false)}
                  >
                    Reset
                  </Button>
                </div>
              </div>
              {showTable ? (
                <Table
                  style={{
                    display: "block",
                    justifyContent: "center",
                    marginTop: "3%",
                  }}
                  columns={nctColumns}
                  dataSource={nctTableData}
                  pagination={{
                    pageSize: 10,
                  }}
                  bordered
                />
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  Please Select Fund
                </div>
              )}
            </div>
          )}
          {selectedOption === "knowledgeBase" && (
            <div>
              <KnowledgeBase />
            </div>
          )}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default ServiceRequests;
