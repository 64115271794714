import React, { useEffect, useState } from "react";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import moment from "moment";
import { Button, Spin, notification } from "antd";

const DrawdownReport = ({ data, toDate }) => {
  const [exportButton, setExportButton] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const exportToExcel = async () => {
    if (data && toDate) {
      try {
        setDownloading(true);
        // Create a new workbook and worksheet
        const workbook = await XlsxPopulate.fromBlankAsync();
        const worksheet = workbook.sheet(0);

        // Set column widths
        worksheet.column("A").width(12);
        worksheet.column("B").width(45);
        worksheet.column("C").width(45);
        worksheet.column("D").width(20);
        worksheet.column("E").width(30);
        worksheet.column("F").width(45);
        worksheet.column("G").width(25);
        worksheet.column("H").width(35);
        worksheet.column("I").width(45);
        worksheet.column("J").width(28);
        worksheet.column("K").width(30);
        worksheet.column("L").width(30);
        worksheet.column("M").width(35);
        worksheet.column("N").width(35);
        worksheet.column("O").width(35);
        worksheet.column("P").width(35);
        worksheet.column("Q").width(35);
        worksheet.column("R").width(35);
        worksheet.column("S").width(35);
        worksheet.column("T").width(35);
        worksheet.column("U").width(35);
        worksheet.column("V").width(35);
        worksheet.column("W").width(35);
        worksheet.column("X").width(35);

        // Merge cells for report heading
        const headingRange = worksheet.range("A1:G1");
        headingRange.merged(true);
        headingRange.value(
          `Drawdown Report of "${data[0].clientname}" as on ${moment(
            toDate
          ).format("DD-MM-YYYY")}`
        );
        headingRange.style({
          fontSize: 20,
          bold: true,
          horizontalAlignment: "left",
          fill: "F9F9FC",
        });
        worksheet.range("A2:X2").merged(true);
        worksheet.range("H1:X1").merged(true);

        // Table Heading information
        worksheet.cell("A2").value("");
        worksheet.cell("A3").value("S.No.");
        worksheet.cell("B3").value("Client Name");
        worksheet.cell("C3").value("Scheme Name");
        worksheet.cell("D3").value("Class Code");
        worksheet.cell("E3").value("Class Category ");
        worksheet.cell("F3").value("Class Name");
        worksheet.cell("G3").value("Folio No");
        worksheet.cell("H3").value("Branch Name");
        worksheet.cell("I3").value("Investor Name");
        worksheet.cell("J3").value("Category");
        worksheet.cell("K3").value("Residential Status");
        worksheet.cell("L3").value("Transaction Type");
        worksheet.cell("M3").value("Transaction Date");
        worksheet.cell("N3").value("Transaction Units");
        worksheet.cell("O3").value("Drawdown Amount");
        worksheet.cell("P3").value("IH Number");
        worksheet.cell("Q3").value("Cheque Number");
        worksheet.cell("R3").value("Cheque Date");
        worksheet.cell("S3").value("Drawdown Id");
        worksheet.cell("T3").value("Drawdown Percentage");
        worksheet.cell("U3").value("Drawdown Value");
        worksheet.cell("V3").value("Drawdown Start Date");
        worksheet.cell("W3").value("Drawdown End Date");
        worksheet.cell("X3").value("Endoresed Date");

        // Apply Styles to cells A3 to G#
        worksheet.range("A3:X3").style({
          fill: "E1F0DA",
          fontSize: 12,
          bold: true,
          horizontalAlignment: "center",
        });

        // Fill in fund model information
        let currentRow = 4;
        for (const DdrData of data) {
          worksheet.cell(`A${currentRow}`).value(DdrData.sno);
          worksheet.cell(`B${currentRow}`).value(DdrData.clientname);
          worksheet.cell(`C${currentRow}`).value(DdrData.schemename);
          worksheet.cell(`D${currentRow}`).value(DdrData.class_code);
          worksheet.cell(`E${currentRow}`).value(DdrData.class_cat);
          worksheet.cell(`F${currentRow}`).value(DdrData.class_name);
          worksheet.cell(`G${currentRow}`).value(DdrData.folio_no);
          worksheet.cell(`H${currentRow}`).value(DdrData.branch_name);
          worksheet.cell(`I${currentRow}`).value(DdrData.investor_name);
          worksheet.cell(`J${currentRow}`).value(DdrData.inv_category);
          worksheet.cell(`K${currentRow}`).value(DdrData.residential_status);
          worksheet.cell(`L${currentRow}`).value(DdrData.transaction_type);
          worksheet
            .cell(`M${currentRow}`)
            .value(moment(DdrData.transaction_date).format("DD-MM-YYYY"));
          worksheet.cell(`N${currentRow}`).value(DdrData.transaction_units);
          worksheet.cell(`O${currentRow}`).value(DdrData.drawdown_amount);
          worksheet.cell(`P${currentRow}`).value(DdrData.ih_number);
          worksheet.cell(`Q${currentRow}`).value(DdrData.cheque_number);
          worksheet
            .cell(`R${currentRow}`)
            .value(
              DdrData.cheque_date
                ? moment(DdrData.cheque_date).format("DD-MM-YYYY")
                : "-"
            );
          worksheet.cell(`S${currentRow}`).value(DdrData.drawdown_id);
          worksheet.cell(`T${currentRow}`).value(DdrData.drawdown_percentage);
          worksheet.cell(`U${currentRow}`).value(DdrData.drawdown_value);
          worksheet
            .cell(`V${currentRow}`)
            .value(moment(DdrData.drawdown_start_date).format("DD-MM-YYYY"));
          worksheet
            .cell(`W${currentRow}`)
            .value(moment(DdrData.drawdown_end_date).format("DD-MM-YYYY"));
          worksheet
            .cell(`X${currentRow}`)
            .value(moment(DdrData.endoresed_date).format("DD-MM-YYYY"));
          currentRow++;
          worksheet
            .range(`A4:X${currentRow}`)
            .style({ fontSize: 12, horizontalAlignment: "center" });
          worksheet
            .range(`B4:D${currentRow}`)
            .style({ fontSize: 12, horizontalAlignment: "left" });
          worksheet
            .range(`D4:E${currentRow}`)
            .style({ fontSize: 12, horizontalAlignment: "center" });
          worksheet
            .range(`F4:F${currentRow}`)
            .style({ fontSize: 12, horizontalAlignment: "left" });
          worksheet
            .range(`H4:I${currentRow}`)
            .style({ fontSize: 12, horizontalAlignment: "left" });
          worksheet
            .range(`N4:O${currentRow}`)
            .style({ fontSize: 12, horizontalAlignment: "right" });
          worksheet
            .range(`U4:U${currentRow}`)
            .style({ fontSize: 12, horizontalAlignment: "right" });

          worksheet.range(`A1:X${currentRow}`).style({
            border: true,
            bottomBorder: "thin",
            rightBorder: "thin",
            leftBorder: "thin",
            topBorder: "thin",
          });
        }

        // Write the workbook to a file or stream
        const blob = await workbook.outputAsync();
        const urlObject = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = urlObject;
        link.download = `Drawdowns Report ${moment(toDate).format(
          "DD-MM-YYYY"
        )}.xlsx`;
        link.click();
        setDownloading(false);
        notification.success({ message: "Report Downloaded Successfully" });
        console.log(`Report generated successfully.`);
      } catch (error) {
        console.log(`Error generating report: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    setExportButton(!data || data.length === 0);
  }, [data]);

  return (
    <>
      {downloading ? (
        <Spin size="medium" />
      ) : (
        <Button
          onClick={() => {
            exportToExcel();
          }}
          disabled={exportButton}
          loading={downloading}
          style={{
            width: "140px",
            height: "33px",
            backgroundColor: "#A3020C",
            color: "white",
            borderRadius: 5,
          }}
        >
          Export as Excel
        </Button>
      )}
    </>
  );
};

export default DrawdownReport;
