import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import rootReducer from './rootReducers';
import storage from 'redux-persist/lib/storage';

import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from 'redux-persist';

const localpersistConfig = {
    key: 'root',
    storage,
    //   stateReconciler: autoMergeLevel2,  //more reading required ( this key is supposedly able to bypass the above mentioned problem but currently not working)
    blacklist: [
        'clientData',
        'userAuth',
    ],
    whitelist: [
        'clientData',
        'userAuth',
    ],
};

const persistedReducer = persistReducer(localpersistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], //these actions are not serializable
            },
        }).concat(logger),
});

export const persistor = persistStore(store);
