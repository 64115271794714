import React from "react";

const PrivacyPolicy = () => {
  const styles = {
    container: {
      flex: 1,
      padding: 20,
      textAlign: "justify",
    },
    heading: {
      textAlign: "center",
      fontSize: 20,
      marginTop: 10,
      color: "#113293",
      fontWeight: "bold",
    },
    subHeading: {
      fontSize: 16,
      marginTop: 20,
      color: "#0a0a0a",
      fontWeight: "bold",
    },
    regularText: {
      fontSize: 14,
      marginTop: 20,
      color: "#0a0a0a",
    },
    link: {
      color: "#113293",
    },
    lastSpacer: {
      height: 100,
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.heading}>
        Privacy Policy - Validus Fintech Services Pvt Ltd (VFSPL)
      </div>
      <div style={styles.regularText}>
        Validus Fintech Services Pvt Ltd (VFSPL) is committed to protecting any
        personal information that we may receive while you access our website
        and mobile application. We believe it is important for you to know how
        we treat information about you that we may receive when you engage with
        us. This Privacy Policy is devised to help you feel more confident about
        the privacy and security of your personal details. 'You' shall mean You,
        the User of the Website/ Application and 'Yourself' interpreted
        accordingly. 'We' / 'Us' means Validus Fintech Services Pvt Ltd (VFSPL)
        and 'Our' interpreted accordingly. 'Users' means the Users of the
        Website/ application collectively and/or individually as the context
        allows.
      </div>

      <div style={styles.subHeading}>Who we are</div>
      <div style={styles.regularText}>
        Validus is in the business of providing Digital, IT and IT enabled
        services to various companies in the BFSI hereinafter referred as "The
        company / Validus".
      </div>

      <div style={{ marginTop: 20, fontSize: 14 }}>
        Our website address is :
        <span
          style={{ color: "blue", marginLeft: "5px", cursor: "pointer" }}
          onClick={() =>
            window.open("https://www.validusfintech.com", "_blank")
          }
        >
          https://www.validusfintech.com
        </span>
      </div>

      <div style={styles.subHeading}>
        What personal data we collect and why we collect it
      </div>
      <div style={styles.regularText}>
        We receive both information that is directly provided to Us, such as
        personal information You provide when You visit the Website/ mobile
        application, and information that is passively or automatically
        collected from You, such as information collected from the browser or
        device You used to access Our Website or Services. In this Privacy
        Policy, we refer to all this as the 'User Information'. To explain
        further,
      </div>
      <div style={styles.regularText}>
        Information You Provide To Us. There are portions of this Website /
        Application where We may need to collect personal information from You
        for a specific purpose. For e.g. You can register, apply for partner
        services, seek out enabler connects. In the course of these various
        offerings, We often seek to collect from You various forms of
        information, such as: name, address, e-mail address, telephone number,
        fax number and business details. At some instances, You may also be able
        to submit Information about your business plan. For example, You might
        submit answers specific to your business / idea for an innovation
        challenge or hunt posted on the portal.
      </div>
      <div style={styles.regularText}>
        Information That is Automatically Collected. In general, You can visit
        this Website without telling Us who You are or revealing any information
        about Yourself. Our web servers collect the domain names, not the e-mail
        addresses, of visitors. Our web servers also seek (as many websites do)
        to place a 'cookie' (a small data file) on Your computer's hard drive
        which allows the server to determine the computer when it visits again,
        in order to track statistical information about navigation to and
        throughout certain areas of our Website, and to Validus promotional
        material that may interest the user based on prior visits to the site.
        This cookie is not used to obtain Your name or any personal data, and
        the information that is tracked is used only for internal purposes, such
        as to improve site navigation and to measure the effectiveness of our
        promotional placements, and is not shared with anyone other than Validus
        contractors who assist Validus in these efforts and are bound to
        confidentiality. If You prefer not to accept cookies, You can set Your
        browser to reject them or to alert You before one is placed. If You
        choose not to receive cookies, You may still use most of the features of
        our Website. We do not use technology that responds to 'do-not track'
        signals from Your web browser.
      </div>

      <div style={styles.subHeading}>Third-Party Advertising</div>
      <div style={styles.regularText}>
        We may use third-party advertising companies to serve ads on Our behalf.
        These companies may employ cookies and action tags (also known as single
        pixel gifs or web beacons) to measure advertising effectiveness. Any
        information that these third parties collect via cookies and action tags
        is completely anonymous.
      </div>

      <div style={styles.subHeading}>Links</div>
      <div style={styles.regularText}>
        We may link to other websites, including those of our subsidiaries and
        third-party content providers, that have different privacy policies and
        practices from those disclosed here. We do not endorse any of these
        websites or the products or services associated with such websites. We
        have no control over and are not responsible for the privacy policies or
        practices of any third-party website. We encourage you to review the
        privacy policies of those websites before providing them with any of
        your personal information.
      </div>

      <div style={styles.subHeading}>Changes to This Privacy Policy</div>
      <div style={styles.regularText}>
        We reserve the right to change or update this Privacy Policy from time
        to time. Any changes to the Privacy Policy will be posted on this page
        with the updated Privacy Policy. We encourage you to review this Privacy
        Policy regularly for any updates. Your continued use of the Website or
        Services after any changes or revisions to this Privacy Policy shall
        indicate your agreement with the terms of such revised Privacy Policy.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
