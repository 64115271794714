import {
  Button,
  DatePicker,
  Form,
  Input,
  notification,
  Radio,
  Spin,
} from "antd";
import { validateAccountNumber } from "components/utils/helperFunction";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  getDownloadSOAPath,
  getValidatedAccountAPI,
} from "services/ApiServices";

const AccountStatement = () => {
  const { userData } = useSelector((state) => state.userAuth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState("custom");
  const [pdfLink, setPDFLink] = useState("");
  const onSubmit = async (values) => {
    if (!validateAccountNumber(values.accountNumber)) {
      notification.error({
        message: "Error",
        description: "Account Number should be 8 to 12 digits long",
      });
      return;
    }
    if (!values.duration) {
      notification.error({
        message: "Error",
        description: "Please select duration",
      });
      return;
    }
    if (duration === "custom" && (!values.periodfrom || !values.periodto)) {
      notification.error({
        message: "Error",
        description: "Please select Start Date & End Date",
      });
      return;
    }

    const currentYear = new Date().getFullYear();
    try {
      const { responseData } = await getValidatedAccountAPI(
        values.accountNumber,
        userData?.client_id
      );
      if (!responseData) {
        notification.error({
          message: "Error",
          description: "Client & Fund not found",
        });
        return;
      } else {
        let queryParams = `${responseData[0].client_id}/${responseData[0].fund_id}/${values.accountNumber}`;
        queryParams += `?from_date=${getFromDate(
          currentYear
        )}&to_date=${getToDate(currentYear)}`;
        downloadStatement(queryParams);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onDownload = async (values) => {
    const { responseData } = await getValidatedAccountAPI(
      values.accountNumber,
      userData?.client_id
    );
    if (!responseData) {
      notification.error({
        message: "Error",
        description: "Client & Fund not found",
      });
      return;
    } else {
      let fundid = responseData[0].fund_id;
      const currentYear = new Date().getFullYear();
      const client_id = userData?.client_id;
      const fund_id = fundid;
      const accountNo = values.accountNumber;
      const fromDate = getFromDate(currentYear, values);
      const toDate = getToDate(currentYear, values);

      let displaySOAapi = `${client_id}/${fund_id}/${accountNo}`;

      if (
        values.periodfrom !== "Period From" &&
        values.periodto !== "Period To"
      ) {
        displaySOAapi += `?from_date=${fromDate}&to_date=${toDate}`;
      }
      downloadStatement(displaySOAapi);
    }
  };

  const downloadStatement = async (queryParams) => {
    try {
      setLoading(true);
      const response = await getDownloadSOAPath(queryParams);
      const { message, responseData } = response;
      if (
        message === "Success" &&
        responseData !== "Data is not avialble with selected criteria"
      ) {
        setLoading(false);
        setPDFLink(responseData[1]);
      } else {
        setLoading(false);
        notification.error({
          message: "Error",
          description: responseData,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getFromDate = (currentYear, values) => {
    let fromDate;
    if (values.duration === "last_fy") {
      fromDate = new Date(currentYear - 1, 3, 1);
    } else if (values.duration === "current_fy") {
      fromDate = new Date(currentYear, 3, 1);
    } else if (values.duration === "inception") {
      fromDate = new Date(2022, 0, 1);
    } else if (duration === "custom") {
      fromDate = dayjs(values.periodfrom).format("DD-MM-YYYY");
    }
    return dayjs(fromDate).format("YYYY-MM-DD");
  };

  const getToDate = (currentYear, values) => {
    let toDate;
    if (values.duration === "last_fy") {
      toDate = new Date(currentYear, 2, 31);
    } else if (values.duration === "current_fy") {
      toDate = new Date(currentYear + 1, 2, 31);
    } else if (values.duration === "inception") {
      toDate = new Date();
    } else if (duration === "custom") {
      toDate = dayjs(values.periodto).format("DD-MM-YYYY");
    }
    return dayjs(toDate).format("YYYY-MM-DD");
  };

  const onFinish = (values) => {
    if (values?.accountNumber) {
      onDownload(values);
    } else {
      onSubmit(values);
    }
  };
  return (
    <>
      <h1 className="text-center text-2xl font-bold text-validus-red">
        Account Statement
      </h1>
      <div className="flex flex-row gap-x-5 px-5 mt-4 h-[80vh]">
        {pdfLink && (
          <div className="w-1/2  mx-auto bg-white p-1 rounded-md">
            <iframe
              src={pdfLink}
              title="Download"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        )}
        <div className="w-1/2 mx-auto bg-white px-10 py-5 rounded-md flex items-center justify-center">
          <Spin spinning={loading}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              className="w-full"
            >
              <Form.Item
                name="accountNumber"
                label="Account Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter account number!",
                  },
                ]}
              >
                <Input placeholder="Enter Account Number" />
              </Form.Item>
              <Form.Item
                name="duration"
                label="Duration"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select duration!",
                //   },
                // ]}
              >
                <Radio.Group>
                  <Radio value="last_fy">For Last FY</Radio>
                  <Radio value="current_fy">For Current FY</Radio>
                  <Radio value="inception">Since Inception</Radio>
                </Radio.Group>
              </Form.Item>
              <p className="font-semibold text-center">(OR)</p>
              <div className="flex flex-row justify-between gap-x-6">
                <Form.Item
                  name="periodfrom"
                  label="Period From"
                  className="w-full"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select period from!",
                  //   },
                  // ]}
                >
                  <DatePicker
                    placeholder="Select Period From"
                    className="w-[100%]"
                    onChange={() => {
                      setDuration("cutom");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="periodto"
                  label="Period To"
                  className="w-full"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select period to!",
                  //   },
                  // ]}
                >
                  <DatePicker
                    placeholder="Select Period To"
                    className="w-[100%]"
                    onChange={() => {
                      setDuration("cutom");
                    }}
                  />
                </Form.Item>
              </div>
              <Form.Item className="flex justify-end">
                <Button type="primary" htmlType="submit">
                  Generate Statement
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default AccountStatement;
