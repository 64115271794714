import React, { useState, useEffect } from "react";
import LoadingScreen from "react-loading-screen";
import SuggestedQueryList from "./SuggestedQuery/SuggestedQueryList";
import { getSearchFields } from "services/QueryServices";
import { Spin } from "antd";

const QuerySelection = () => {
  const [searchFields, setSearchFields] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFields = async () => {
    setLoading(true);
    try {
      const res = await getSearchFields();
      if (res.responseData) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setSearchFields(res.responseData);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchFields();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin spinning={loading} tip="Loading..." />
        </div>
      ) : (
        <SuggestedQueryList searchFields={searchFields} />
      )}
    </div>
  );
};

export default QuerySelection;
