const endpoints = {
  CHANGE_PASSWORD: 'VUser/changePassword',
  DELETE_USER: '/VUser/DeleteTheUser',
  GET_CLIENT_DATA: 'VClient/GetClientbyClientid',
  GET_ADDRESS_DATA: 'Vlookup/GetAddressData',
  GET_FUNDS: 'VFund/GetFundbyCilentid',
  GET_CLASS_DATA: 'VClass/GetClassbyFundid',
  UPLOAD_DOC_TO_GDRIVE: "AwsS3/UploadDocToGdrive",
  SAVE_VAULT_DETAILS: "/VTransaction/SaveVaultDetails",
  GET_VAULT_DETAILS: "/VTransaction/GetVaultDetails",
  ADD_VAULT_ENTRIES_FROM_EXCEL: "/VTransaction/addVaultEntriesFromExcel",
  GET_VAULT_DOCUMENT_DETAILS: "/Common/Digitaldocdetails/P",
  GET_DTR_TRANSACTIONS: '/VTransaction/GetDTRTransactionsCommon',
  GET_MILESTONE_SUMMARY_REPORT_DATA: 'VReports/GetMilestoneSummaryReportData_v2',
  GET_MILESTONE_REPORT_COUNT_DATA: 'VReports/GetMilestoneReportCountdata_v2',

  GET_DOWNLOAD_SOA_PATH: '/VReports/DownloadSOAPDFPath',
  GET_VALIDATED_ACCOUNT: '/VAccount/Getvadiledaccount',
  ADD_S3_LINK_BULK_DOWNLOAD: '/VTransaction/ZipFilesToBase64',
  GET_SEARCH_FIELDS: 'VAccount/GetSearchFields',
  GET_ADVANCED_SEARCH_DATA: 'VAccount/getAdvancedSearchData',
  GET_FAMILY_FOLIO_DETAILS: 'VAccount/getFamilyAccountDetails',
  GENERATE_FAMILY_FOLIO_SOA: 'VTransaction/DownloadFamilySOAPDFPath',

  SAVE_WORKFLOW: 'VWorkFlow/insertWorkflow',
  GET_WORKFLOW: 'VWorkflow/getworkflowdata',
  GET_COUNTRY: 'Common/GetCountry',

};
export default endpoints;
