import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Drawer, Tooltip } from "antd";
import { useStateContext } from "../contexts/ContextProvider";
import { GoGear } from "react-icons/go";
import Settings from "./Settings";
import UserProfile from "./UserProfile";
import { useSelector } from "react-redux";

const Navbar = () => {
  const {
    setActiveMenu,
    screenSize,
    setScreenSize,
    setOpenSettingsDrawer,
    setOpenProfileDrawer,
    openSettingsDrawer,
    openProfileDrawer,
    handleLogOut,
  } = useStateContext();
  const { clientData } = useSelector((state) => state.clientData);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const Footer = () => (
    <div onClick={handleLogOut} className="flex justify-end">
      <div className="cursor-pointer bg-validus-blue text-white px-3 py-2 rounded-lg w-1/4 text-center">
        Logout
      </div>
    </div>
  );

  const NavButton = ({ title, customfunc, icon, color, dotColor }) => (
    <Tooltip title={title} placement="bottom">
      <button
        type="button"
        onClick={customfunc}
        style={{ color }}
        className="relative text-xl rounded-full p-3 hover:bg-light-gray"
      >
        <span
          style={{ background: dotColor }}
          className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
        />
        {icon}
      </button>
    </Tooltip>
  );
  return (
    <div className="flex justify-between p-2 md:mx-6 relative items-center">
      <NavButton
        title="Menu"
        customfunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
        color="black"
        icon={<AiOutlineMenu />}
      />
      <div className="flex flex-row gap-x-8 items-center">
        <Tooltip title="Settings" placement="bottomRight">
          <div
            className="bg-white rounded-3xl p-2 cursor-pointer"
            onClick={() => {
              setOpenSettingsDrawer(true);
            }}
          >
            <GoGear size={30} />
          </div>
        </Tooltip>
        <Drawer
          title="Settings"
          onClose={() => {
            setOpenSettingsDrawer(false);
          }}
          open={openSettingsDrawer}
        >
          <Settings />
        </Drawer>
        <Tooltip title="User Profile" placement="bottomRight">
          <div
            className=" cursor-pointer "
            onClick={() => {
              setOpenProfileDrawer(true);
            }}
          >
            <img
              src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
              alt="logo"
              className="w-10 h-10 rounded-full"
            />
          </div>
        </Tooltip>
        <Drawer
          title="Profile"
          onClose={() => {
            setOpenProfileDrawer(false);
          }}
          open={openProfileDrawer}
          footer={<Footer />}
        >
          <UserProfile />
        </Drawer>
      </div>
    </div>
  );
};

export default Navbar;
