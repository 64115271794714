import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const StateContext = createContext();
//  This is state is to maintain the different options of the webapp
const initialState = {
  chat: false,
  userProfile: false,
  notification: false,
};

const empty = {};


export const ContextProvider = ({ children }) => {
  const [apiBaseUrl, setApiBaseUrl] = useState(`https://prodapi.myvalidus.com/Vapi`);
  const [investorData, setInvestorData] = useState([]);
  const [user_branch_code, setUser_branch_code] = useState(sessionStorage.getItem("branch_code") || ""
  );
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);

  const [formData, setFormData] = useState({});
  const userID = "id-1223456789";
  const [accountNumber, setAccountNumber] = useState("148180000364");
  const [clientId, setClientId] = useState("1");
  const [loggedIn, setLoggedIn] = useState(
    sessionStorage.getItem("loggedIn") || false
  );

  const [client_id, setClient_id] = useState(
    sessionStorage.getItem("client_id") || ""
  );

  const [client_code, setClient_code] = useState(
    sessionStorage.getItem("client_code") || ""
  );

  const [user_name, setUser_name] = useState(
    sessionStorage.getItem("user_name") || ""
  );
  const [user_first_name, setUser_first_name] = useState(
    sessionStorage.getItem("user_first_name") || ""
  );
  const [user_last_name, setUser_last_name] = useState(
    sessionStorage.getItem("user_last_name") || ""
  );
  const [user_branch_id, setUser_branch_id] = useState(
    sessionStorage.getItem("user_branch_id") || ""
  );
  const [first_time_user, setFirst_time_user] = useState(
    sessionStorage.getItem("first_time_user") || ""
  );
  const [pwd_expiry, setPwd_expiry] = useState(
    sessionStorage.getItem("pwd_expiry") || ""
  );
  const [last_login_date, setLast_login_date] = useState(
    sessionStorage.getItem("last_login_date") || ""
  );
  const [user_id, setUser_id] = useState(
    sessionStorage.getItem("user_id") || ""
  );
  const [user_role_id, setUser_role_id] = useState(
    sessionStorage.getItem("user_role_id") || ""
  );
  const [value, setValue] = useState("");
  const [branchData, setBranchData] = useState({});
  const [userBranch, setUserBranch] = useState({});
  const handleClick = (clicked) => {
    setIsClicked({
      ...initialState,
      [clicked]: !isClicked[clicked],
    });
  };
  const [querydata, setQuerydata] = useState([]);
  const [openSettingsDrawer,
    setOpenSettingsDrawer] = useState(false);
  const [openProfileDrawer,
    setOpenProfileDrawer] = useState(false);
  function amountInWords(price) {
    var sglDigit = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt
          ? ""
          : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (
          (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
    if (((price += ""), isNaN(parseInt(price)))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
        switch (
        ((digit = price[digitIdx] - 0),
          (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
          price.length - digitIdx - 1)
        ) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 != digit
                ? " " +
                sglDigit[digit] +
                " Hundred" +
                (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                  ? " and"
                  : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 != digit
                ? " " +
                sglDigit[digit] +
                " Hundred" +
                (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                  ? " and"
                  : " Crore")
                : ""
            );
        }
      str = words.reverse().join("");
    } else str = "";
    return str;
  }

  const formatNumberWithCommas = (num) => {
    // Check if the number is a valid number or convert it to a string
    num = typeof num === "number" ? num.toString() : num || "";

    // Split the number into integer and decimal parts
    const parts = num.split(".");
    let integerPart = parts[0];
    const decimalPart = parts[1] ? `.${parts[1]}` : "";

    // Add commas to the integer part
    if (integerPart.length > 3) {
      const integerLength = integerPart.length;
      const numCommas = Math.floor((integerLength - 1) / 3);
      for (let i = 0; i < numCommas; i++) {
        const commaIndex = integerLength - 3 * (i + 1) + i;
        integerPart =
          integerPart.slice(0, commaIndex) +
          "," +
          integerPart.slice(commaIndex);
      }
    }

    // Combine the integer and decimal parts
    return integerPart + decimalPart;
  };
  const [loading, setLoading] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };


  let navigate = useNavigate();
  const handleLogOut = () => {
    axios
      .get(`${apiBaseUrl}/VUser/logout/${sessionStorage.getItem("user_name")}`)
      .then((res) => {
        setLoggedIn(false);
        sessionStorage.removeItem("loggedIn");
        sessionStorage.removeItem("user_name");
        sessionStorage.removeItem("user_first_name");
        sessionStorage.removeItem("user_last_name");
        sessionStorage.removeItem("user_branch_id");
        sessionStorage.removeItem("first_time_user");
        sessionStorage.removeItem("pwd_expiry");
        sessionStorage.removeItem("last_login_date");
        sessionStorage.removeItem("user_id");
        sessionStorage.removeItem("user_role_id");
        sessionStorage.removeItem("client_id");
        setUser_name("");
        setUser_first_name("");
        setUser_last_name("");
        setUser_branch_id("");
        setFirst_time_user("");
        setPwd_expiry("");
        setLast_login_date("");
        setUser_id("");
        setUser_role_id("");
        setBranchData({});
        //clear localStorage
        localStorage.clear();
        //clear sessionStorage
        sessionStorage.clear();
      })
      .then(() => {
        navigate("/Login");
        setOpenProfileDrawer(false);
        setOpenSettingsDrawer(false);
      });
  };

  return (
    <StateContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        value,
        setValue,
        loading,
        setLoading,
        querydata,
        setQuerydata,
        user_name,
        user_first_name,
        user_last_name,
        user_branch_id,
        first_time_user,
        pwd_expiry,
        last_login_date,
        user_id,
        user_role_id,

        client_id,
        setClient_id,

        client_code,
        setClient_code,

        setUser_name,
        setUser_first_name,
        setUser_last_name,
        setUser_branch_id,
        setFirst_time_user,
        setPwd_expiry,
        setLast_login_date,
        setUser_id,
        setUser_role_id,
        amountInWords,
        branchData,
        setBranchData,
        investorData,
        setInvestorData,
        userBranch,
        setUserBranch,

        activeMenu,
        setActiveMenu,
        isClicked,
        handleClick,
        screenSize,
        setScreenSize,
        empty,
        apiBaseUrl,
        setApiBaseUrl,
        formData,
        setFormData,

        accountNumber,
        setAccountNumber,
        user_branch_code,
        setUser_branch_code,
        clientId,
        setClientId,
        userID,
        scrollToTop,
        formatNumberWithCommas,
        handleLogOut,
        openSettingsDrawer,
        setOpenSettingsDrawer,
        openProfileDrawer,
        setOpenProfileDrawer
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
