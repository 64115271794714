import React from "react";
import { Form, Input, Button, notification } from "antd";
import "antd/dist/antd.css";
import "./Login.css";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import LeftNav from "components/LeftNav";
import { changePasswordAPI } from "services/loginServices";

const ChangePassword = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("newPassword") === value) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error("The Passwords that you entered do not match!")
      );
    },
  });

  const onFinish = async (values) => {
    if (values.newPassword === values.confirmPassword) {
      let changePasswordPayload = {
        username: localStorage.getItem("userName"),
        oldpassword: values.oldPassword,
        confirmpassword: values.confirmPassword,
        creation_by: 1,
        updation_by: 1,
        isactive: true,
        type: "C",
      };
      const res = await changePasswordAPI(changePasswordPayload);
      if (
        res.message === "Success" &&
        res.responseData !== "New password already exist"
      ) {
        notification.success({
          message: "Success",
          description: "Password changed successfully",
        });
        localStorage.setItem("password", values.confirmPassword);
        navigate("/Login");
      } else if (
        res.message === "Success" &&
        res.responseData === "New password already exist"
      ) {
        notification.warning({
          message: "Error",
          description: "New password already exist",
        });
      }
    } else {
      notification.warning({
        message: "Error",
        description: "New password and confirm password do not match",
      });
    }
  };

  return (
    <div className="flex flex-row">
      <LeftNav />
      <div className="w-[50%] bg-[#F1F6F9]">
        <div className="heading">AIF Investor Services</div>
        <div className="text-xl font-semibold text-validus-red text-center">
          Change Password
        </div>
        <div className="flex flex-col mt-12 mx-auto items-center">
          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
              username: localStorage.getItem("userName"),
              password: localStorage.getItem("password"),
            }}
            onFinish={onFinish}
            style={{
              width: "80%",
            }}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                disabled={true}
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                autoComplete="off"
              />
            </Form.Item>
            <div>Old Password</div>
            <Form.Item
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your current Password!",
                },
              ]}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Current Password"
                autoComplete="off"
              />
            </Form.Item>
            <div>New Password</div>
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
                {
                  pattern: /^(?=.*[a-z])/,
                  message:
                    "Password must contain at least one lowercase letter",
                },
                {
                  pattern: /^(?=.*[A-Z])/,
                  message:
                    "Password must contain at least one uppercase letter",
                },
                {
                  pattern: /^(?=.*\d)/,
                  message: "Password must contain at least one number",
                },
                {
                  pattern: /^(?=.*[!@#$%^&*])/,
                  message:
                    "Password must contain at least one special character (!@#$%^&*)",
                },
                {
                  min: 8,
                  message: "Password must be at least 8 characters long",
                },
              ]}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Enter New Password"
                autoComplete="off"
              />
            </Form.Item>
            <div>Confirm Password</div>
            <Form.Item
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                { required: true, message: "Please confirm your password!" },
                validateConfirmPassword,
              ]}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Re-Enter New Password"
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Change password
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
