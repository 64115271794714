import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { getAddressData, getFunds } from "services/profileServices";
import { useDispatch, useSelector } from "react-redux";
import { setFundData } from "store/clientData.slice";

const Profile = () => {
  const { clientData } = useSelector((state) => state.clientData);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "S. No",
      dataIndex: "sno",
      align: "center",
    },
    {
      title: "Fund Name",
      dataIndex: "fund_Description",
      align: "center",
    },
    {
      title: "Fund Code",
      dataIndex: "code",
      align: "center",
    },

    {
      title: "Status",
      dataIndex: "fund_Status",
      align: "center",
    },
    {
      title: "View Details",
      dataIndex: "action",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              dispatch(setFundData(record));
            }}
            style={{
              backgroundColor: "#A3020C",
              color: "white",
              borderRadius: 5,
            }}
          >
            <Link to={{ pathname: `/Funds` }}>View</Link>
          </Button>
        );
      },
    },
  ];

  const fetchAddressData = async () => {
    try {
      const res = await getAddressData(clientData?.address_id);
      if (res.responseData) {
        setAddressData(res.responseData[0]);
      } else {
        setAddressData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFundData = async () => {
    try {
      const res = await getFunds(clientData?.client_id);
      if (res?.responseData) {
        res.responseData.forEach((e, i) => {
          e.sno = i + 1;
          e.maturity_Date = moment(e.maturity_Date)
            .format("DD/MM/YYYY")
            .toString();
        });
        setTableData(res.responseData);
        setLoading(false);
      } else {
        setLoading(false);
        setTableData([]);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const showFunds = () => {
    setLoading(true);
    fetchAddressData();
    fetchFundData();
  };

  useEffect(() => {
    showFunds();
  }, []);

  return (
    <>
      <h1 className="text-validus-red text-center text-2xl font-bold">
        {clientData?.client_name}
      </h1>
      <div
        className="flex flex-col bg-[#f5f5f5] rounded-md p-6 mx-4"
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="flex flex-row justify-between">
          <div className="w-[50%] flex flex-col">
            <h2 style={{ fontSize: "16px" }}>Client Details</h2>
            <p>Client Name : {clientData.client_name}</p>
            <p>Client Code : {clientData.client_code}</p>
            <h3 style={{ marginTop: "2%", fontSize: "16px" }}>
              Contact Details
            </h3>
            <p>Primary Telephone : {clientData.primary_telephone_number}</p>
            <p>
              Secondary Telephone :{" "}
              {clientData.secondary_telephone_number === null ||
              clientData.secondary_telephone_number === "" ||
              clientData.secondary_telephone_number === "+"
                ? "Not Available"
                : clientData.secondary_telephone_number}
            </p>
            <p>Fax Number : {clientData.fax_number}</p>
            <p>Primary Email : {clientData.primary_email_id}</p>
            <p>
              Secondary Email :{" "}
              {clientData?.secondary_email_id || "Not Available"}
            </p>
          </div>
          <div className="w-[50%] flex flex-col">
            <div className="flex flex-col">
              <h2 style={{ fontSize: "16px" }}>Registration Details</h2>
              <p>
                Government Registration ID :{" "}
                {clientData?.government_registration_id || "Not Available"}
              </p>
              <p>
                Regulator Registration ID :{" "}
                {clientData?.regulator_registration_id || "Not Available"}
              </p>
              <p>
                Direct Tax ID : {clientData?.direct_tax_id || "Not Available"}
              </p>
              <p>
                Indirect Tax ID :{" "}
                {clientData?.indirect_tax_id || "Not Available"}
              </p>
              <p>
                Company Website URL :{" "}
                {clientData.company_website_url || "Not Available"}
              </p>
            </div>
            <div className="flex flex-col">
              <h3 style={{ fontSize: "16px" }} className="mt-4">
                Contact Person Details
              </h3>
              <p>Name : {clientData.contact_person_name || "Not Available"}</p>
              <p>
                Designation :{" "}
                {clientData.designation_contact_person || "Not Availble"}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="w-[50%] flex flex-col">
            <h3 style={{ fontSize: "16px" }}>Address Details</h3>
            <p>Address : {addressData?.address_line1} </p>
            <p>
              {addressData?.address_line2 || "Not Available"},{" "}
              {addressData?.address_line3 || "Not Available"}
            </p>
            <p>City : {addressData?.city || "Not Available"}</p>
          </div>
          <div className="w-[50%] flex flex-col mt-4">
            <p>District : {addressData?.district_county || "Not Available"}</p>
            <p>State : {addressData?.state_province_text || "Not Available"}</p>
            <p>Pincode : {addressData?.pincode || "Not Available"}</p>
          </div>
        </div>
      </div>

      <h1 className="text-validus-red text-center text-2xl font-bold my-8">
        Funds for {clientData.client_name}
      </h1>
      <Table
        className="w-[98%] mx-auto"
        rowKey={(record) => record.index}
        columns={columns}
        dataSource={tableData}
        bordered
        loading={loading}
      />
    </>
  );
};

export default Profile;
