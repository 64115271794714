import React, { useState, useRef } from "react";
import { Drawer, Button, Alert, Divider, Modal } from "antd";
import { IoMdDownload } from "react-icons/io";
import { FaRegFileExcel } from "react-icons/fa";
import {
  saveVaultEntriesFromExcelApi,
  getVaultDocumentDetails,
} from "services/ApiServices";
import { document_upload_link } from "../../constants/Constant";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";

const DocumentUploadDrawer = ({ openDrawer, setOpenDrawer }) => {
  const { userData } = useSelector((state) => state.userAuth);
  const [files, setFiles] = useState(null);
  const [excelUploaded, setExcelUploaded] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const selectedFundHouse = useRef(userData.client_id);
  const fileInputRef = useRef(null);

  const onClose = () => {
    setOpenDrawer(false);
  };
  const handleFileChange = (e) => {
    // Handle file change logic here
    const file = e.target.files[0];
    setFiles(file);
  };
  const handleButtonClick = () => {
    // Trigger click on the file input when the button is clicked
    fileInputRef.current.click();
  };

  const downloadTemplate = () => {
    document.getElementById("downloadLink").click();
  };

  const handleUpload = async () => {
    if (files && selectedFundHouse.current) {
      setIsUploadLoading(true);
      try {
        const arrayBuffer = await readFileAsync(files);
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          range: 1,
        });

        const payloadData = jsonData.reduce((acc, row) => {
          if (row.every((cell) => cell)) {
            const payload = {
              vault_id: 0,
              entity_id: 1,
              document_type: row[1],
              entity_reference_number: String(row[0]),
              storage_category: "INWARD",
              s3_path: "",
              created_by: userData.user_id, // Ensure user_id is defined
              creation_date: new Date().toISOString(),
              isactive: true,
              updated_by: 0,
              updation_date: new Date().toISOString(),
              document_name: row[2],
              transaction_reference_number: String(row[0]),
              metadata: "",
              document_type_id: 0,
            };

            acc.push(payload);
          }
          return acc;
        }, []);

        const validatedPayloadData = await validatePayloadData(payloadData);
        const response = await getVaultDocumentDetails(
          selectedFundHouse.current
        );

        const updatedValidationList = validatedPayloadData.map((item) => {
          const matchingResponseItem = response.find(
            (responseItem) =>
              responseItem.kyc_documnet_desc === item.document_type
          );
          if (matchingResponseItem) {
            return {
              ...item,
              document_type_id: matchingResponseItem.kyc_document_id,
            };
          }
          return item;
        });
        if (!updatedValidationList.length) {
          setIsUploadLoading(false);
          setExcelUploaded(false);
          Modal.error({
            title: "Error",
            content: "No data found in the excel file.",
          });
          return;
        } else {
          const response = await saveVaultEntriesFromExcelApi(
            updatedValidationList
          );
          const { message } = response;
          if (message === "Success") {
            setTimeout(() => {
              setExcelUploaded(true);
              setIsUploadLoading(false);
              Modal.success({
                title: "Success",
                content: "File uploaded successfully",
              });
            }, 2000); // 2 seconds timeout
          } else {
            setIsUploadLoading(false);
            setExcelUploaded(false);
            console.log("Error uploading file", message);
            return;
          }
        }
      } catch (error) {
        console.error("Error reading file:", error);
      }
    } else {
      setIsUploadLoading(false);
      setExcelUploaded(false);
    }
  };

  const validatePayloadData = (data) => {
    const payloadData = data.filter((obj) => obj.document_type);
    const validatedData = payloadData.filter((item) => {
      const {
        entity_reference_number,
        transaction_reference_number,
        document_type,
        document_name,
      } = item;
      const isValid =
        entity_reference_number &&
        transaction_reference_number &&
        document_type &&
        document_name;
      return isValid;
    });

    const removedItemsCount = payloadData.length - validatedData.length;
    if (removedItemsCount === payloadData.length) {
      <Alert
        message="No Data"
        description="No data / Invalid data found in the excel file."
        type="warning"
        showIcon
        closable
      />;
    } else if (removedItemsCount > 0) {
      <Alert
        message="Warning"
        description="Some items were removed due to incorrect data supplied."
        type="warning"
        showIcon
        closable
      />;
    }
    return validatedData;
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <>
      <Drawer
        title="Document Upload"
        placement="right"
        onClose={onClose}
        open={openDrawer}
      >
        <p className="text-left">Click here to Download the Template</p>
        <a
          id="downloadLink"
          href={document_upload_link}
          style={{ display: "none" }}
        >
          Download Template
        </a>
        <Button
          type="primary"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "#4e76e1",
            color: "#fff",
            padding: "17px 10px",
            borderRadius: "8px",
          }}
          onClick={() => {
            downloadTemplate();
          }}
        >
          <span>Download Template</span>
          <span className="ml-2">
            <IoMdDownload size={18} />
          </span>
        </Button>
        <Divider
          style={{
            color: "#002060",
          }}
        />
        <div className="mt-10 mb-3 font-bold">Upload Excel Sheet</div>
        <input
          type="file"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ref={fileInputRef}
          style={{ display: "none" }} // Hide the file input
          onChange={handleFileChange}
        />
        <Button
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "rgb(58,112,73)",
            color: "#fff",
            borderRadius: "8px",
            padding: "17px 13px",
          }}
          onClick={handleButtonClick}
        >
          <span> Import Excel File</span>
          <span className="ml-2">
            <FaRegFileExcel size={18} />
          </span>
        </Button>

        {files ? (
          <div style={{ marginTop: 20 }}>
            <div
              key={files.name}
              style={{
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: 20,
              }}
            >
              <FaRegFileExcel size={50} />

              <p
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "250px",
                  margin: 0,
                  fontSize: "smaller",
                }}
              >
                {files.name}
              </p>
            </div>
          </div>
        ) : null}
        <div
          style={{
            //keep this to the bottom of the drawer
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            padding: "10px 16px",
            textAlign: "right",
            left: 0,
            right: 0,
            zIndex: 100,
          }}
        >
          <Button
            disabled={excelUploaded}
            loading={isUploadLoading}
            type="primary"
            onClick={() => {
              handleUpload();
            }}
          >
            Submit
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default DocumentUploadDrawer;
