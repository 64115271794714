import { myApi } from "./apiInstance";
import endpoints from "./endpoints";

export const getWorkflow = async (data) => {
    try {
        return await myApi
            .get(
                `${endpoints.GET_WORKFLOW}?wfseqcode=${data}`)
            .then((res) => {
                return res.data;
            });
    } catch (err) {
        return console.log(err);
    }
};

export const saveWorkflow = async (data) => {
    try {
        return await myApi
            .post(
                `${endpoints.SAVE_WORKFLOW}`, data)
            .then((res) => {
                return res.data;
            });
    }
    catch (err) {
        return console.log(err);
    }
};